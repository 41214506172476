import React from 'react';
import { currencyFormat } from '../../../utils/filters';

const CotizacionComparativa = ({cuenta}) => {
    return (
        <div className="table-responsive">
            <table className="table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Financiera</th>
                        <th>Yuhu</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Número de pagos</td>
                        <td>{cuenta.cotizacion_actual.numero_pagos}</td>
                        <td>{cuenta.cotizacion_yuhu.numero_pagos}</td>
                    </tr>
                    <tr>
                        <td>Pago mensual</td>
                        <td>{currencyFormat(cuenta.cotizacion_actual.pago_mensual)}</td>
                        <td>{currencyFormat(cuenta.cotizacion_yuhu.pago_mensual)}</td>
                    </tr>
                    <tr>
                        <td>Tasa de interés</td>
                        <td>N/A</td>
                        <td>{cuenta.cotizacion_yuhu.tasa_interes}%</td>
                    </tr>
                    <tr>
                        <td>Total a pagar</td>
                        <td>{currencyFormat(cuenta.cotizacion_actual.total_pagar)}</td>
                        <td>{currencyFormat(cuenta.cotizacion_yuhu.total_pagar)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}
 
export default CotizacionComparativa;