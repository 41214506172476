import React, { useContext, useEffect } from 'react';
import { currencyFormat } from '../../../utils/filters';
import { CDDContext } from '../../../context/solicitudes/CDDContext';

const CDDMonto = () => {
    const { solicitud } = useContext(CDDContext);

    useEffect(() => {
        
        return () => {
            
        }
    }, [solicitud])
    
    return (
        <div className="text-right">
            <style jsx="true">{`
                .label {
                    margin-right: 10px;
                    display: block;
                }
                .monto {
                    font-size: 24px;
                    display: block;
                }
            `}</style>
            <span> Monto CDD </span>
            <span className="font-bold monto">{solicitud.monto_cdd === '--' ? '$0.00' : currencyFormat(solicitud.monto_cdd) }</span>
        </div>
    );
}
 
export default CDDMonto;