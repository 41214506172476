import React from 'react';
import Modal from 'react-awesome-modal';

const ModalCuentasAptas = ({mostrar, setMostrar}) => {
    return (
        <Modal visible={mostrar} width="50%" height="600px" effect="fadeInUp">
            <div className="modal-header">
                <h4 className="modal-title">Reglas para cuentas aptas para compra de deuda</h4>
            </div>
            <div className="modal-body">
                <ul>
                    <li>Cuenta abierta</li>
                    <li>Otorgantes excluidos:
                        <ul>
                            <li>COMUNICACIONES</li>
                            <li>SERVICIOS</li>
                            <li>GUBERNAMENTAL</li>
                            <li>AUTOMOTRIZ</li>
                            <li>HIPOTECARIA</li>
                            <li>HIPOTECAGOBIERNO</li>
                        </ul>
                    </li>
                    <li>Tipos de cuentas aptas: 
                        <ul>
                            <li>R (Revolvente)</li>
                            <li>I (Pagos fijos)</li>
                        </ul>
                    </li>
                    <li>Tipo de responsabilidad apta:
                        <ul>
                            <li>I (Individual)</li>
                        </ul>
                    </li>
                    <li>Forma de pago actual:
                        <ul>
                            <li>UR</li>
                            <li>00</li>
                            <li>01</li>
                            <li>02</li>
                        </ul>
                    </li>
                    <li>Antigüedad mínima de 6 meses</li>
                    <li>Saldo actual mínimo de $2500.00</li>
                </ul>
            </div>
            <div className="modal-footer">
                <button type="button" className="btn btn-primary" onClick={() => setMostrar(false)} >Cerrar</button>
            </div>
        </Modal>
    );
}
 
export default ModalCuentasAptas;