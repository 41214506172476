import React, { useContext, useState } from 'react';
import { CDDContext } from '../../../context/solicitudes/CDDContext';
import CDDCuenta from './CDDCuenta';
import ModalCuentasAptas from './ModalCuentasAptas';


const CDDLista = () => {
    const { cuentas } = useContext(CDDContext);

    const [mostrarModalInfo, setMostrarModalInfo] = useState(false);

    return (
        <div className="table-responsive">
            <table className="table table-striped">
                <thead>
                    <tr>
                        <th>Otorgante</th>                          
                        <th>Tipo cuenta</th>
                        <th>Contrato</th>
                        <th>No. pagos</th>
                        <th>Frecuencia pago</th>
                        {/* <th>Monto</th> */}
                        <th>Fecha apertura</th>
                        <th>Fecha ultimo pago</th>
                        <th>Crédito máximo</th>
                        <th>Saldo Actual</th>
                        <th>Monto de pago</th>
                        <th>Saldo vencido</th>
                        <th>MOP Actual</th>
                        <th>Historico</th>
                        {/*<th>*/}
                        {/*    Apta CDD*/}
                        {/*    <button*/}
                        {/*      className="btn btn-info btn-circle"*/}
                        {/*      onClick={() => setMostrarModalInfo(true)}*/}
                        {/*    >?</button>*/}
                        {/*</th>*/}
                        <th>

                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        cuentas.map( (cuenta) => (
                            <CDDCuenta 
                                key={cuenta.id}
                                cuenta={cuenta}
                            />
                        ) )
                    }
                </tbody>
            </table>

            <ModalCuentasAptas mostrar={mostrarModalInfo} setMostrar={setMostrarModalInfo} />
        </div>        
    );
}
 
export default CDDLista;

