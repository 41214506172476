import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import Autenticado from '../../components/Autenticado';
import Formulario from '../../components/form/Form';
import { Spanish } from 'flatpickr/dist/l10n/es';
import Radio from '../../components/form/fields/Radio';
import {
    actualizarComprobanteSolicitud,
    actualizarEstatusSolicitud,
    actualizarOrdenDispersionSolicitud,
    actualizarReferenciaSolicitud,
    agregarComprobanteSolicitud,
    agregarDocumentosFirmadosSolicitud,
    agregarDocumentosSolicitud,
    agregarMensajeSolicitud,
    agregarOrdenDispersionSolicitud,
    agregarReferenciaSolicitud, agregarValidacionRescateSolicitud, analizarSolicitudIA,
    asignarAnalista,
    cancelarSolicitud,
    consultarBuroCredito,
    crearVinculoRenovacion,
    eliminarComprobantesSolicitud,
    eliminarDocumentosFirmadosSolicitud,
    eliminarDocumentosSolicitud,
    eliminarMensajeSolicitud,
    eliminarOrdenDispersionSolicitud,
    eliminarVinculoRenovacion,
    enviarOrdenDispersionSolicitud,
    exportarSolicitud,
    generarPrestamo,
    getAnalistas,
    getJsonBuroCredito,
    getNotasCredito, getValidacionesRescateSolicitud,
    obtenerComprobantesSolicitud,
    obtenerDocumentosSolicitud,
    obtenerMensajeSolicitud,
    obtenerOrdenesDispersionSolicitud,
    obtenerReferenciasSolicitud,
    obtenerSeguimiento,
    obtenerSolicitud, obtenerSolicitudAnalizarIA,
    reactivarSolicitudCredito,
    rechazarSolicitud,
    saveBuroCredito,
    solicitudGenerarOrdenVenta,
    solicitudObtenerOrdenVenta,
    solicitudPartialUpdate,
    solicitudvalidarInformacionBancaria,
    updateNotasCredito,
    getDatosUnificacion,
    UnificarEmpleado
} from '../../api/Solicitudes';
import { obtenerRenovacion } from '../../api/Prestamos';
import { obtenerCuentasBancarias } from '../../api/CuentasBancarias';
import { obtenerBancosList } from '../../api/Domiciliacion';
import { obtenerDocumentos } from '../../api/Documentos';
import { obtenerPrestamoActivo, obtenerPrestamoActivoRenovacion } from '../../api/Empleados';
import Tabla from '../../components/Tabla';
import Modal from 'react-awesome-modal';
import { currencyFormat, dateFormat, dateTimeFormat, timeFormat } from '../../utils/filters';
import { quitarNulos } from '../../utils/functions';
import { verificarPermiso } from '../../utils/auth';
import moment from 'moment';
import TablaCotizacion from '../../components/TablaCotizacion';
import DatePicker from '../../components/form/fields/DatePicker';
import { obtenerEstados, ObtenerMotivosRechazo, obtenerMunicipios } from '../../api/Sistema';
import { obtenerEstadoMunicipiosSepomex, obtenerEstadosSepomex, obtenerMunicipiosSepomex } from '../../api/Sepomex';
// import ColoniasSepomexSelect from '../../components/ColoniasSepomexSelect';
import CartaBuro from './CartaBuro';
import { mostrarToast } from '../../utils/alerts';
import SemaforoColor from '../../components/ColorSemaforo';
import CuestionarioCompliance from '../../components/CuestionarioCompliance';
import EnviarDocumento from '../../components/solicitudes/EnviarDocumento';
// CDD
import CDD from '../../components/solicitudes/cdd/CDD';
import { CDDProvider } from '../../context/solicitudes/CDDContext';
import ExcepcionRiesgo from '../../components/solicitudes/ExcepcionRiesgo';
import SelectAnalista from '../../components/solicitudes/SelectAnalista';
import OcrData from '../../components/solicitudes/ocr/OcrData';
import GenerarContrato from '../../components/solicitudes/contrato/GenerarContrato';
// import OcrData from '../../components/solicitudes/OcrData';


class Form extends Component {
    state = {
        id: null,
        titulo: null,
        pestanaSeleccionada: 1,
        dataComprobante: {},
        ordenesDispersion: [],
        ordenDispersion: {},
        errorsComprobante: [],
        errorsOrdenDisp: [],
        totalDispersion: 0,
        dispersionCompleto: false,
        dataDocumento: {},
        errorsDocumento: [],
        dataDocFirmado: {},
        errorsDocFirmado: [],
        dataMensaje: {},
        errorsMensaje: [],
        dataRechazo: {},
        dataCancelacion: {},
        errorsRechazo: [],
        errorsCancelacion: [],
        dataEstatus: {},
        errorsEstatus: [],
        ocultarTabs: true,
        mostrarFrmOrdenDispEdit: false,
        mostrarFrmComp: false,
        mostrarConfComp: false,
        mostrarConfCompDoc: false,
        comprobanteGuardarDisabled: false,
        ordenDispGuardarDisabled: false,
        documentoGuardarDisabled: false,
        documentoFirmadoGuardarDisabled: false,
        mostrarFrmDoc: false,
        mostrarFrmDocFirm: false,
        mostrarConfElimContrato: false,
        mostrarFrmRechazo: false,
        mostrarFrmCancelacion: false,
        referencias: [],
        referenciaId: null,
        objeto: {},
        empleado: {},
        errorsEmpleado: [],
        domicilio: {},
        errorsDomicilio: [],
        estados: [],
        municipios: [],
        valid_rfc: {},
        valid_curp: {},
        producto: {},
        infoBancaria: {},
        cotizacion: [],
        percepciones: {},
        cotCreColumnas: [
            {
                llave: 'num_pago',
                etiqueta: 'Plazo'
            },{
                llave: 'fecha',
                etiqueta: 'Fecha',
                tipo: 'fecha'
            },{
                llave: 'capital',
                etiqueta: 'Capital',
                tipo: 'dinero'
            },{
                llave: 'interes',
                etiqueta: 'Interes',
                tipo: 'dinero'
            },{
                llave: 'iva',
                etiqueta: 'IVA',
                tipo: 'dinero'
            },{
                llave: 'total_pagar',
                etiqueta: 'Total',
                tipo: 'dinero'
            },{
                llave: 'saldo_final',
                etiqueta: 'Saldo final',
                tipo: 'dinero'
            }
        ],
        comprobantes: [],
        comprobantesCols: [
            {
                llave: 'tipo_documento_nombre',
                etiqueta: 'Comprobante'
            },{
                llave: 'documento',
                etiqueta: 'Documento'
            }
        ],
        comprobantesBtns: [
            {
                etiqueta: 'Ver',
                url: 'https://www.lanetanoticias.com/wp-content/uploads/2018/05/alejandra-trevino-25.jpg',
                function: null
            },{
                etiqueta: 'Eliminar documento',
                url: null,
                funcion: this.eliminarDocumento
            },{
                etiqueta: 'Eliminar',
                url: null,
                funcion: this.eliminar
            }
        ],
        tiposDocumento: [],
        opcionesTiposDocumento: [],
        mensajes: [],
        documentos: [],
        seguimiento: [],
        historialPrestamos: [],
        historialPrestamosCols: [
            {
                llave: 'folio',
                etiqueta: 'Folio'
            },{
                llave: 'fecha_creacion',
                etiqueta: 'Fecha',
                tipo: 'fecha'
            },{
                llave: 'monto',
                etiqueta: 'Monto',
                tipo: 'dinero'
            },{
                llave: 'adeudo_total',
                etiqueta: 'Adeudo total',
                tipo: 'dinero'
            },{
                llave: 'amortizaciones_restantes',
                etiqueta: 'Amortizaciones restantes'
            },{
                llave: 'plazo',
                etiqueta: 'Plazo'
            },{
                llave: 'tipo',
                etiqueta: 'Tipo'
            },{
                llave: 'estatus',
                etiqueta: 'Estatus'
            }
        ],
        informacionCrediticia: {},
        datosElimCompComprobante: {},
        datosElimDocComprobante: {},
        datosInvalidarComp: {},
        datosValidarComp: {},
        datosReenviar:{},
        editarComprobante: false,
        indiceComprobante: null,
        indiceOrdenDisp: null,
        datosElimContrato: {},
        idDoc: null,
        indiceDoc: null,
        mostrarConfElimDocContrato: false,
        mostrarFrmComentario: false,
        mostrarConfElimComentario: false,
        datosElimComentario: {},
        mostrarModal: false,
        dataPrestamo: {},
        errorsPrestamo: {
            fecha_primera_amortizacion: [],
            fecha_dispersion: []
        },
        mostrarModalReferencia: false,
        tituloModalReferencia: '',
        accionReferencia: '',
        referencia: {},
        errorsReferencia: [],
        showReqDispersion: false,
        dispersionAutorizada: null,
        errorsDispersionAutorizada: [],
        formOrdenDisp: false,
        dispersionGuardarDisabled: false,
        cuentasBancarias: [],
        motivosRechazo: [],
        datosElimCompOrdenDisp: {},
        datosEnviarCompOrdenDisp: {
            obj:{},
            indice:{}
        },
        mostrarConfElimOrdenDisp: false,
        mostrarConfEnviarOrdenDisp: false,
        clasificacion: {
            clasificacion: null,
            subclasificacion: null,
            tipo_garantia: null
        },
        ordenVenta: {},
        renovacion: {},
        disabledButtons: false,
        disabledSend: false,

        params: {},
        datos: {
            search: ''
        },
        prestamoRenovar: {},
        mensaje: '',
        confirmarEliminarVinculo: false,
        modalCartaBuro: false,
        modalReactivarSolicitud: false,

        confDatosBC: false,
        confUnificacion: false,
        unificandoEmpleado: false,
        dataUnificacion: {},
        dataEmpleadoUnificacion: {},
        loadingBC: false,
        empleadoBC: {},
        domicilioBC: {},
        data_bc: {},
        errors_data_bc: [],
        error_consulta_bc: "",
        intentos_bc: 0, 

        loadingComentario: false,
        INEFrontalValido: false,
        notasDeCredito: {
            no_valido_antiguedad: false,
            incluye_compra_deuda: false
        },
        modalMsi:false,
        infoBancariaEstatus: "",
        mostrarConfValidarCuentaClabe: false,
        bancos: [],
        ia: {},
        validacionesRescate: {
            validaciones: [],
            showValidarRescate: false,
            nuevaValidacion: {}
        },
        analistas: []
    }

    componentWillMount() {
        const id = this.props.match.params.id
        const titulo = 'Ver solicitud'
        this.setState({ id, titulo })
    }

    async obtenerInformacionUnificacion() {
        const { empleado, dataEmpleadoUnificacion } = this.state
        try {
          const response = await getDatosUnificacion(empleado.id);
          console.log(response.data)
          this.setState({dataUnificacion: response.data});
          this.setState({confUnificacion: true})
          

          dataEmpleadoUnificacion['email'] = response.data.empleado_nuevo.email
        
            if (response.data.empleado_nuevo.telefono_celular != null){
                dataEmpleadoUnificacion['telefono_celular'] = response.data.empleado_nuevo.telefono_celular
            }
            else{
                dataEmpleadoUnificacion['telefono_celular'] = response.data.empleado_nuevo.telefono_celular
            }
          

            dataEmpleadoUnificacion['curp'] = response.data.empleados_anteriores[0].curp
            dataEmpleadoUnificacion['rfc'] = response.data.empleados_anteriores[0].rfc
            dataEmpleadoUnificacion['no_electoral'] = response.data.empleados_anteriores[0].no_electoral
            

          this.setState({dataEmpleadoUnificacion})


        } catch (error) {
          console.log(error);
        }
    }
    handleChangeDataUnificacion  = async (e) => {
        
        let { dataEmpleadoUnificacion } = this.state
        const { name, value } = e.target;
        
        dataEmpleadoUnificacion[name] = value
                
        this.setState({
            dataEmpleadoUnificacion
        });
        console.log(value)
        console.log(dataEmpleadoUnificacion.nombre)
    }

    async unificarEmpleado() {
        const { empleado, dataEmpleadoUnificacion} = this.state
        try {
            this.setState({unificandoEmpleado: true}) 
            const response = await UnificarEmpleado(empleado.id, dataEmpleadoUnificacion);
            this.setState({confUnificacion: false}) 
            this.mostrarToast('Se unificó empleado correctamente');

        } catch (error) {
            this.setState({confUnificacion: false})
            this.mostrarToast('Ocurrió un error', 'error');
        }
    }



    obtenerAnalistas = async () => {
        try {
          const response = await getAnalistas();
          this.setState({analistas: response.data});
        } catch (error) {
          console.log(error);
        }
    }

    onChangeAnalista = async (e) => {
        let analista = e.target.value;

        if (e.target.value === 'sin analista') {
            analista = null;
        }

        try {
            await asignarAnalista(this.state.objeto.id, {analista})
            Swal.fire({
                type: 'success',
                text: `Analista asignado a la solicitud ${this.state.objeto.folio} exitosamente`,
                toast: true,
                position: 'top-end',
                timer: 5000,
                showConfirmButton: false
            })
            this.componentDidMount();
        } catch (err) {
            console.log(err);
        }
    }

    async componentDidMount() {
        const { id, validacionesRescate } = this.state
        try {
            let res = await obtenerSolicitud(id)
            let objeto = res.data
            let renovacion = {}
            let ordenVenta = {}
            let disabledButtons = false

            if(objeto.renovacion_solicitud !== null) {
                renovacion = await obtenerRenovacion(objeto.renovacion_solicitud)
                renovacion = renovacion.data
            }
            if(objeto.orden_venta_solicitud !== null) {
                ordenVenta = await solicitudObtenerOrdenVenta(objeto.id)
                ordenVenta = ordenVenta.data
                if(ordenVenta.estatus === "generada" || ordenVenta.ns_id_record !== null){
                    disabledButtons = true
                }
            }
            const dataPrestamo = {};
            if (objeto.fecha_primera_amortizacion){
                dataPrestamo.fecha_primera_amortizacion = objeto.fecha_primera_amortizacion;
            }

            const clasificacion = {
                clasificacion: "consumo", //objeto.clasificacion,
                subclasificacion: "nomina", //objeto.subclasificacion,
                tipo_garantia: objeto.tipo_garantia,
            };

            objeto = quitarNulos(objeto)
            const dataEstatus = {
                estatus: objeto.estatus,
                analisis_buro: objeto.analisis_buro,
                analisis_credito: objeto.analisis_credito,
                revision_documentos: objeto.revision_documentos,
                tipo_autorizacion: objeto.tipo_autorizacion_buro,
                autorizacion_valida: objeto.autorizacion_valida,
                ultima_consulta: objeto.buro_ultima_consulta,
                pld: objeto.pld,
                nip: objeto.nip
            }
            const historialPrestamos = objeto.historial_prestamos
            let informacionCrediticia = objeto.informacion_crediticia
            informacionCrediticia = quitarNulos(informacionCrediticia)
            let { empleado } = objeto
            const empleadoBC = empleado
            empleado = quitarNulos(empleado)
            let { valid_rfc } = empleado
            let { valid_curp } = empleado
            let { producto } = objeto
            producto = quitarNulos(producto)
            const infoBancaria = empleado.informacion_bancaria || {}
            const infoBancariaEstatus = empleado.informacion_bancaria.estatus_verificacion || ''
            const { percepciones } = empleado
            const { domicilio } = empleado
            const domicilioBC = domicilio
            const { cotizacion } = objeto
            res = await obtenerComprobantesSolicitud(id)
            const comprobantes = res.data
            res = await obtenerDocumentos()
            const tiposDocumento = []
            res.data.forEach(obj => {
                tiposDocumento.push({ label: obj.nombre, value: obj.id });
            })
            const opcionesTiposDocumento = tiposDocumento
            let ordenesDispersion = []
            let dataErrorDispersion = {}
            try{
                res = await obtenerOrdenesDispersionSolicitud(id)
                ordenesDispersion = this.addPropsObjectDispersion(res.data);
            }catch(err){
                console.log(err)
                // this.setState({showReqDispersion: false});
            }
            res = await obtenerMensajeSolicitud(id)
            const mensajes = res.data
            res = await obtenerDocumentosSolicitud(id)
            const documentos = res.data
            res = await obtenerSeguimiento(id)
            const seguimiento = res.data

            // Nos traemos las referencias de la solicitud
            res = await obtenerReferenciasSolicitud(id);
            const referencias = res.data;

            // Nos traemos las notas del crédito
            let notasDeCredito = this.state.notasDeCredito;
            try {
                const respNotasCredito = await getNotasCredito(id);
                notasDeCredito = respNotasCredito.data;
            }catch (err) {
                // console.log(err.status);
            }


            res = await ObtenerMotivosRechazo()
            const motivosRechazo = []
            res.data.forEach(obj => {
                motivosRechazo.push({ label: obj.etiqueta, value: obj.nombre });
                // console.log(obj)
            })
            motivosRechazo.unshift({
                label:'Seleccionar...', value:''
            })

            res = await obtenerCuentasBancarias()
            const cuentasBancarias = []
            res.data.forEach(obj => {
                if(obj.banco === 'BANORTE' || obj.banco === 'STP')
                    cuentasBancarias.push({ label: obj.banco+' - '+obj.no_cuenta, value: obj.id });
            })
            cuentasBancarias.unshift({
                label:'Seleccionar...', value:''
            })

            res = await obtenerEstados()
            let res_sepomex = await obtenerEstadosSepomex()
            let estados = this.unionEstados(res.data, res_sepomex.data)
            estados.unshift({
                label:'Seleccionar...', value:''
            })

            res = await obtenerBancosList()
            const bancos = []
            res.data.forEach(obj => {
                    bancos.push({ label: obj.nombre, value: obj.nombre });
            })
            bancos.unshift({
                label:'Seleccionar...', value:''
            })

            let municipios = []
            if (domicilio.estado){
                let estado_selected = estados.filter( e => e.value_o === domicilio.estado)[0]
                // console.log("============ ESTADO SELECTED ============")
                // console.log(estado_selected)
                domicilio['estado'] = estado_selected.value
                const res = await obtenerMunicipios(estado_selected.value_o);
                const res_sepomex = await obtenerMunicipiosSepomex(estado_selected.value);
                municipios = this.unionMunicipios(res.data, res_sepomex.data)
                municipios.unshift({
                    label: '--Selecciona--', value: ''
                })
                let municipio_selected = ''
                if (domicilio.municipio){
                    municipio_selected = municipios.filter( e => e.value_o === domicilio.municipio)[0]
                    domicilio['municipio'] = municipio_selected.value
                }else{
                    domicilio['municipio'] = ''
                }
                // console.log("============ MUNICIPIO SELECTED ============")
                // console.log(municipio_selected)
            }
            // console.log("============ DOMICILIO ============")
            // console.log(domicilio)

            let ordenDispersion = {}
            ordenDispersion.beneficiario_banco = infoBancaria.banco_str
            ordenDispersion.beneficiario_clabe = infoBancaria.clabe
            ordenDispersion.beneficiario_nombre = empleado.nombre_completo

            this.setDataBC(empleadoBC, domicilioBC)

            let intentos_bc = objeto.intentos_bc

            this.setState({
                objeto,
                dataPrestamo,
                empleado,
                valid_rfc,
                valid_curp,
                producto,
                infoBancaria,
                domicilio,
                estados,
                municipios,
                cotizacion,
                percepciones,
                referencias,
                comprobantes,
                ordenDispersion,
                ordenesDispersion,
                tiposDocumento,
                opcionesTiposDocumento,
                mensajes,
                documentos,
                seguimiento,
                dataEstatus,
                dataErrorDispersion,
                historialPrestamos,
                informacionCrediticia,
                cuentasBancarias,
                clasificacion,
                renovacion,
                ordenVenta,
                disabledButtons,
                intentos_bc,
                notasDeCredito,
                motivosRechazo,
                infoBancariaEstatus,
                bancos

            })
            this.validarMontoTotalOrdenDispersion()
        } catch (error) {
            console.log(error)
        }
        
        let INEValido = this.state.comprobantes.find( comprobante => comprobante.tipo_documento_nombre === "INE/IFE Frontal" && comprobante.valido);

        if (typeof INEValido === "object") {
            if (INEValido.valido && this.state.objeto.bc_consulto === false) {
                this.setState({INEFrontalValido: false});
            } else{
                this.setState({INEFrontalValido: true});
            }
        } else {
            this.setState({INEFrontalValido: true});
        }

        // Obtenemos las validaciones de rescate de la solicitud
        try {
            const req = await getValidacionesRescateSolicitud(id);
            validacionesRescate.validaciones = req.data;
            this.setState(validacionesRescate);
        } catch (error) {
            console.log(error);
        }
        this.obtenerAnalistas();
    }

    setDataBC = (empleadoBC, domicilioBC) => {
        let data_bc = {}

        data_bc.type = 'pdf'
        data_bc.nombre = (empleadoBC.nombre === '--') ? '' : empleadoBC.nombre;
        data_bc.apellido_paterno = (empleadoBC.apellido_paterno === '--') ? '' : empleadoBC.apellido_paterno;
        data_bc.apellido_materno = (empleadoBC.apellido_materno === '--') ? '' : empleadoBC.apellido_materno;
        data_bc.fecha_nacimiento = (empleadoBC.fecha_nacimiento === '--') ? '' : empleadoBC.fecha_nacimiento;
        data_bc.rfc = (empleadoBC.rfc === '--') ? '' : empleadoBC.rfc;
        data_bc.direccion1 = (domicilioBC.calle) ? domicilioBC.calle: ` ${domicilioBC.numero_exterior}`? domicilioBC.numero_exterior : ''
        data_bc.colonia_poblacion = domicilioBC.colonia
        data_bc.delegacion_municipio = domicilioBC.municipio
        data_bc.estado = domicilioBC.estado
        data_bc.ciudad = ''
        data_bc.codigo_postal = domicilioBC.codigo_postal
        // console.log(data_bc)

        this.setState({data_bc})

    }

    actualizarListadoDocumentos = async (idSolicitud) => {
        try {
            const res = await obtenerDocumentosSolicitud(idSolicitud)
            const documentos = res.data
            this.setState({documentos})
        } catch (error) {
            console.log(error);
        }
    }


    unionEstados = (estados, estados_sepomex) => {
        let estados_unificados = []
        // console.log("============ Estados ============")
        // console.log(estados)
        // console.log("============ Estados Sepomex ============")
        // console.log(estados_sepomex)
        estados.forEach(obj =>{
            estados_sepomex.forEach(o => {
                if(o.nombre === obj.nombre){
                    estados_unificados.push({ label: o.nombre, value: o.uuid, value_o: obj.id })
                }
            })
        })
        // console.log("============ Estados Unifcados ============")
        // // estados_unificados = this.removeDuplicates(estados_unificados, "value")
        // console.log(estados_unificados)
        return estados_unificados
    }

    unionMunicipios = (municipios, municipios_sepomex) => {
        let municipios_unificados = []
        // console.log("============ Municipios ============")
        // console.log(municipios)
        // console.log("============ Municipios Sepomex ============")
        // console.log(municipios_sepomex)
        municipios.forEach(obj =>{
            municipios_sepomex.forEach(o => {
                if(o.nombre === obj.nombre){
                    municipios_unificados.push({ label: o.nombre, value: o.uuid, value_o: obj.id })
                }
            })
        })

        // console.log("============ Municipios Unifcados ============")
        // // estados_unificados = this.removeDuplicates(estados_unificados, "value")
        // console.log(municipios_unificados)
        return municipios_unificados
    }

    handleChangeDomicilio = async (e) => {
        const { data_bc } = this.state;
        let { estados, municipios } = this.state
        const { target: { name, value } } = e;

        data_bc[name] = value;
        if (name === 'estado') {
            let estado_selected = estados.filter( e => e.value === value)[0]
            this.obtenerEstablecerMunicipios(estado_selected)

        } else if (name === 'codigo_postal' && value.length === 5) {
            const respEstadoMunicipiosSepomex = await obtenerEstadoMunicipiosSepomex(value);
            // console.log("============ Estado y Municipios ============")
            // console.log(respEstadoMunicipiosSepomex.data)
            if (respEstadoMunicipiosSepomex.data.length > 0) {
                let estado_sepomex = respEstadoMunicipiosSepomex.data[0].estado
                data_bc["estado"] = estado_sepomex.uuid

                let estado_selected = estados.filter( e => e.value === estado_sepomex.uuid)[0]
                this.obtenerEstablecerMunicipios(estado_selected, respEstadoMunicipiosSepomex.data[0].municipio.uuid)
            } else {

                data_bc["estado"] = ""
                data_bc["delegacion_municipio"] = ""
                municipios = []
            }
            this.setState({ data_bc, municipios })
        } else {
            this.setState({ data_bc })
        }
    }

    obtenerEstablecerMunicipios = async(estado, municipio='') =>{
        const { data_bc } = this.state;
        const res = await obtenerMunicipios(estado.value_o);
        const res_sepomex = await obtenerMunicipiosSepomex(estado.value);
        let municipios = this.unionMunicipios(res.data, res_sepomex.data)
        municipios.unshift({
            label: '--Selecciona--', value: ''
        })

        if (municipio === '') {
            data_bc.delegacion_municipio = '';
        } else {
            data_bc.delegacion_municipio = municipio
        }
        this.setState({ data_bc, municipios })
    }

    handleChangeColonia(e){
        const { data_bc } = this.state;
        const {label/* , value */} = e
        data_bc["colonia_poblacion"] = label
        this.setState({data_bc})
    }

    addPropsObjectDispersion(data){
        const compDisp = []
        if(typeof data != 'undefined'){
            if(Array.isArray(data)){
                if(data.length > 0){
                    data.forEach((item/* , key */) => {
                        compDisp.push({
                            ...item,
                            name_pdf: this.getNameFile(item.comprobante_pago_pdf),
                            name_xml: this.getNameFile(item.comprobante_pago_xml),
                        })
                    })
                }
                return compDisp;
            }else{
                data = {
                    ...data,
                    name_pdf: this.getNameFile(data.comprobante_pago_pdf),
                    name_xml: this.getNameFile(data.comprobante_pago_xml),
                }
                return data;
            }
        }
    }

    getNameFile(file){
        if(typeof file=='string'){
            const fileArr = file.split('/');
            const filename = fileArr[fileArr.length - 1];
            return filename;
        }
        return "--";
    }

    mostrarToast = (mensaje, tipo = 'success') => {
        Swal.fire({
            type: tipo,
            text: mensaje,
            toast: true,
            position: 'top-end',
            timer: 3000,
            showConfirmButton: false
        })
    }
    
    mostrarFormularioComprobanteDispersion(editar = false, indice = null){
        let { comprobantesDispersion } = this.state;
        const editarComprobanteDisp = editar
        const indiceComprobanteDisp = indice
        let dataComprobanteDisp = {}
        let comprobanteDispId = null
        if(editar){
            dataComprobanteDisp = comprobantesDispersion[indice]
            comprobanteDispId = comprobantesDispersion[indice].id
        }

        this.setState({
            editarComprobanteDisp,
            dataComprobanteDisp,
            indiceComprobanteDisp,
            comprobanteDispId,
            errorsComprobanteDisp: {}
        })
    }

    mostrarFormularioComprobante(editar = false, indice = null, comprobanteEditarObj = {}) {
        let { comprobantes/* , tiposDocumento */, opcionesTiposDocumento } = this.state;

        const comprobantes_tipos_ids = comprobantes.map(comprobante => comprobante.tipo_documento);

        // Elimina los tipos de documento para que no agreguen repetidos
        opcionesTiposDocumento = opcionesTiposDocumento.filter((tipo_documento) => {
            if (editar){
                // Mostramos el actual y los que aun no agrega
                if (comprobantes[indice].tipo_documento === tipo_documento.value){
                    return true
                }
            }

            return comprobantes_tipos_ids.indexOf(tipo_documento.value) === -1

        })

        const editarComprobante = editar
        const indiceComprobante = indice
        let dataComprobante = {}
        let comprobanteId = null
        if(editar){
            dataComprobante = comprobantes[indice]
            comprobanteId = comprobantes[indice].id
        }else{
            // Seteamos el comprobante al primero de la lista
            if(opcionesTiposDocumento.length > 0){
                dataComprobante.tipo_documento = opcionesTiposDocumento[0].value;
            }
        }
        this.setState({
            mostrarFrmComp : true,
            editarComprobante,
            dataComprobante,
            indiceComprobante,
            comprobanteId,
            opcionesTiposDocumento,
            errorsComprobante: {}
        })
    }

    mostrarConfElimDocComprobante(obj, indice) {
        const datosElimDocComprobante = { obj, indice }
        this.setState({ mostrarConfCompDoc : true, datosElimDocComprobante })
    }

    mostrarConfElimOrdenDispersion(obj, indice) {
        const datosElimCompOrdenDisp = { obj, indice }
        this.setState({ mostrarConfElimOrdenDisp : true, datosElimCompOrdenDisp })
    }


    mostrarConfEnviarOrdenDispersion(obj, indice) {
        const datosEnviarCompOrdenDisp = { obj, indice }
        this.setState({datosEnviarCompOrdenDisp})
        this.setState({ mostrarConfEnviarOrdenDisp : true })
    }

    mostrarConfElimComprobante(obj, indice) {
        const datosElimCompComprobante = { obj, indice }
        this.setState({ mostrarConfComp : true, datosElimCompComprobante })
    }

    mostrarComprobanteInvalido(obj, indice) {
        const datosInvalidarComp = { obj, indice }
        this.setState({ mostrarConfInvalidar : true, datosInvalidarComp })
    }

    mostrarComprobanteValido(obj, indice) {
        const datosValidarComp = { obj, indice }
        this.setState({ mostrarConfValidar : true, datosValidarComp })
    }

    mostrarConfirmacionReenvio(obj, indice) {
        const datosReenviar = { obj, indice }
        this.setState({ mostrarConfReenvio : true, datosReenviar })
    }

    showModalCartaBuro = () => {
        this.setState({modalCartaBuro: !this.state.modalCartaBuro});
        this.componentDidMount();
    }

    validDocument(){
        const { comprobante_pago_xml, comprobante_pago_pdf } = this.state.ordenDispersion;
        let res = {
            error:false,
            comprobanteDisp: {},
            msg_err : {}
        };
        let {comprobanteDisp} = res;
        let {msg_err} = res;
        if(comprobante_pago_xml instanceof File){
            if(comprobante_pago_xml.type.includes('xml')){
                comprobanteDisp = {
                    ...comprobanteDisp,
                    comprobante_pago_xml
                }
                res = {
                    ...res,
                    comprobanteDisp
                }
            }else{
                msg_err = {
                    ...msg_err,
                    comprobante_pago_xml: ["El archivo no es XML, por favor de seleccionar el archivo con formato correcto."]
                }
                res = {
                    ...res,
                    error: true,
                    msg_err
                }
            }
        }
        if(comprobante_pago_pdf instanceof File){
            if(comprobante_pago_pdf.type.includes('pdf')){
                comprobanteDisp = {
                    ...comprobanteDisp,
                    comprobante_pago_pdf
                }
                res = {
                    ...res,
                    comprobanteDisp
                }
            }else{
                msg_err = {
                    ...msg_err,
                    comprobante_pago_pdf: ["El archivo no es PDF, por favor de seleccionar el archivo con formato correcto."]
                }
                res = {
                    ...res,
                    error: true,
                    msg_err
                }
            }
        }

        return res
    }

    async guardarDispersion(){
        const { id, ordenesDispersion, ordenDispersion, indiceOrdenDisp, mostrarFrmOrdenDispEdit} = this.state
        let { errorsOrdenDisp } = this.state
        let orderDisp = {...ordenDispersion}
        const formData = new FormData();

        if (orderDisp.fecha_dispersion){
        //    Cambiamos el formato de la fecha
            orderDisp.fecha_dispersion = moment(orderDisp.fecha_dispersion).format("YYYY-MM-DD");
        }

        let validType = this.validDocument();
        if(!validType.error){
            if(validType.comprobanteDisp.comprobante_pago_xml){
                orderDisp.comprobante_pago_xml = ordenDispersion.comprobante_pago_xml;
            }
            if(validType.comprobanteDisp.comprobante_pago_pdf){
                orderDisp.comprobante_pago_pdf = ordenDispersion.comprobante_pago_pdf;
            }
            if(mostrarFrmOrdenDispEdit){
                orderDisp = this.delAttrNull(orderDisp);
            }
            Object.keys(orderDisp).forEach((key) => {
                formData.append(key, orderDisp[key])
            })
            this.setState({dispersionGuardarDisabled: true});
            try {
                if (!mostrarFrmOrdenDispEdit){
                    const resp = await agregarOrdenDispersionSolicitud(formData, id)
                    ordenesDispersion.push(this.addPropsObjectDispersion(resp.data));
                } else {
                    const resp = await actualizarOrdenDispersionSolicitud(formData, id, ordenDispersion.id)
                    ordenesDispersion[indiceOrdenDisp] = this.addPropsObjectDispersion(resp.data);
                }
                this.setState({ordenesDispersion, formOrdenDisp:false, ordenDispGuardarDisabled: false, errorsOrdenDisp: [], ordenDispersion: {}})
                this.mostrarToast('Guardando Orden de Dispersión.')
            } catch (error) {
                errorsOrdenDisp = error.data
                if(error.data.comprobante_pago_pdf){
                    errorsOrdenDisp = {...errorsOrdenDisp, comprobante_pago_pdf: error.data.comprobante_pago_pdf};
                }
                if(error.data.comprobante_pago_xml){
                    errorsOrdenDisp = {...errorsOrdenDisp, comprobante_pago_xml: error.data.comprobante_pago_xml};
                }
                this.setState({ errorsOrdenDisp, dispersionGuardarDisabled: false })
            }
            this.validarMontoTotalOrdenDispersion()
        }else{
            errorsOrdenDisp = validType.msg_err
            this.setState({ errorsOrdenDisp, dispersionGuardarDisabled: false })
        }
    }

    delAttrNull(orderDisp){
        for (let prop in orderDisp){
            if(prop==='comprobante_pago_xml' || prop==='comprobante_pago_pdf'){
                if(typeof orderDisp[prop] === 'string'){
                    orderDisp[prop] = ''
                }
            }
            if(orderDisp[prop]===null || orderDisp[prop]===''){
                delete orderDisp[prop];
            }
        }

        return orderDisp
    }

    async guardarComprobante(){
        const { id, comprobantes, dataComprobante, editarComprobante, indiceComprobante, comprobanteId/* , mensaje */ } = this.state
        const formData = new FormData();
        dataComprobante.mensaje = ''
        dataComprobante.estatus = ''
        Object.keys(dataComprobante).forEach((key) => {
            formData.append(key, dataComprobante[key])
        })
        this.setState({comprobanteGuardarDisabled: true});
        try {
            if (!editarComprobante){
                const resp = await agregarComprobanteSolicitud(formData, id)
                comprobantes.push(resp.data)
            } else {
                const resp = await actualizarComprobanteSolicitud(formData, id, comprobanteId)
                comprobantes[indiceComprobante] = resp.data
            }
            this.setState({ comprobantes, mostrarFrmComp: false, comprobanteGuardarDisabled: false, errorsComprobante: [] });
            document.getElementById("archivoComprobante").value = "";
        } catch (error) {
            const errorsComprobante = error.data
            this.setState({ errorsComprobante, comprobanteGuardarDisabled: false })
        }
    }

    async validarInformacionBancaria(){
        const { id } = this.state
        try {
            const res = await solicitudvalidarInformacionBancaria(id)
            this.setState({ infoBancariaEstatus : 'verificando', mostrarConfValidarCuentaClabe: false});
            this.mostrarToast('Verificación en proceso')
        }
        catch (error) {
            this.mostrarToast('Ocurrio un error al dispersar la prueba del peso', 'error');
        }
    }


    async eliminarOrdenDisp() {
        const { id, ordenesDispersion, datosElimCompOrdenDisp } = this.state
        const { obj, indice } = datosElimCompOrdenDisp
        await eliminarOrdenDispersionSolicitud(id, obj.id)
        ordenesDispersion.splice(indice, 1)
        this.validarMontoTotalOrdenDispersion()
        this.setState({ ordenesDispersion, mostrarConfElimOrdenDisp : false })
    }

    async enviarOrdenDisp() {
        const { id, ordenesDispersion, datosEnviarCompOrdenDisp } = this.state
        const { obj, indice } = datosEnviarCompOrdenDisp
        try {
            this.setState({ mostrarConfEnviarOrdenDisp : false })
            Swal.fire({
                type: 'success',
                text: '  Procesando...  ',
                toast: true,
                position: 'top-end',
                timer: 3000,
                showConfirmButton: false
            })
            const res = await enviarOrdenDispersionSolicitud(id, obj.id)
            obj.estatus = 'enviado'
            this.setState({ ordenesDispersion, mostrarConfEnviarOrdenDisp : false })
            this.mostrarToast('Verificación en proceso')
        }
        catch (error) {
            this.setState({ mostrarConfEnviarOrdenDisp : false })
            this.mostrarToast('Ocurrio un error al enviar la dispersión', 'error');
        }
    }

    async eliminarComprobante() {
        const { id, comprobantes, datosElimCompComprobante } = this.state
        const { obj, indice } = datosElimCompComprobante
        await eliminarComprobantesSolicitud(id, obj.id)
        comprobantes.splice(indice, 1)
        this.setState({ comprobantes, mostrarConfComp : false })
    }

    async eliminarDocumento() {
        const { id, comprobantes, datosElimDocComprobante } = this.state
        const { obj, indice } = datosElimDocComprobante
        obj.documento = null
        obj.mensaje = ''
        obj.valido = false
        obj.estatus = ''
        await actualizarComprobanteSolicitud(obj, id, obj.id)
        comprobantes[indice].documento = null
        this.setState({ comprobantes, mostrarConfCompDoc : false })
    }

    async invalidarDocumento() {
        const { id, comprobantes, datosInvalidarComp, mensaje, comentario, estatus } = this.state
        const { obj, indice } = datosInvalidarComp
        obj.estatus = estatus
        obj.valido = false
        if (mensaje === 'otro') {
            obj.mensaje = comentario 
        }
        else {
            obj.mensaje = mensaje
        }
        obj.documento = undefined
        await actualizarComprobanteSolicitud(obj, id, obj.id)
        comprobantes[indice].valido = false
        this.mostrarToast('Guardando cambios en el documento')
        this.setState({ comprobantes, mostrarConfInvalidar: false })
    }

    async validarDocumento() {
        const { id, comprobantes, datosValidarComp } = this.state
        const { obj, indice } = datosValidarComp 
        obj.valido = true
        obj.documento = undefined
        obj.estatus = 'valido'
        await actualizarComprobanteSolicitud(obj, id, obj.id)
        comprobantes[indice].valido = true
        this.mostrarToast('Guardando como válido este documento')
        this.setState({ comprobantes, mostrarConfValidar: false })
    }

    async reenviarCarta() {
        const { id, comprobantes, datosReenviar } = this.state
        const { obj/* , indice */ } = datosReenviar
        obj.documento = undefined
        obj.estatus = 'reenviado'
        await actualizarComprobanteSolicitud(obj, id, obj.id)
        this.mostrarToast('Reenviando la carta de autorización de buró')
        this.setState({ comprobantes, mostrarConfReenvio: false })
    }

    mostrarFormularioDocumento() {
        this.setState({ mostrarFrmDoc : true })
    }

    mostrarFormDocFirmado(idDoc, indiceDoc) {
        this.setState({ mostrarFrmDocFirm : true, idDoc, indiceDoc })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.invalidarDocumento();
      };
      
    async agregarDocumento(){
        const { id, dataDocumento } = this.state
        const formData = new FormData()
        Object.keys(dataDocumento).forEach((key) => {
            formData.append(key, dataDocumento[key])
        })
        this.setState({documentoGuardarDisabled: true})
        try {
            const resp = await agregarDocumentosSolicitud(formData, id)
            const { documentos } = this.state
            documentos.push(resp.data)
            this.setState({ documentos, mostrarFrmDoc: false, documentoGuardarDisabled: true })
        } catch (error) {
            const errorsDocumento = error.data
            this.setState({ errorsDocumento, documentoGuardarDisabled: true })
        }
    }

    mostrarConfElimContrato(obj, indice) {
        const datosElimContrato = { obj, indice }
        this.setState({ mostrarConfElimContrato : true, datosElimContrato })
    }

    async eliminarContrato() {
        const { id, documentos, datosElimContrato } = this.state
        const { obj, indice } = datosElimContrato
        await eliminarDocumentosSolicitud(id, obj.id)
        documentos.splice(indice, 1)
        this.setState({ documentos, mostrarConfElimContrato : false })        
    }

    mostrarFormularioDeRechazo() {
        this.setState({ mostrarFrmRechazo : true })
    }

    mostrarFormularioDeCancelacion() {
        this.setState({ mostrarFrmCancelacion : true })
    }

    mostrarFormComentario() {
        this.setState({ mostrarFrmComentario : true })
    }

    mostrarConfElimComentario(idComentario, indice) {
        const datosElimComentario = { idComentario, indice }
        this.setState({ mostrarConfElimComentario : true, datosElimComentario })
    }

    mostrarConfValidarCuentaClabe() {
        this.setState({ mostrarConfValidarCuentaClabe : true})
    }

    async agregarComentario(){
        const { id, dataMensaje } = this.state
        this.setState({loadingComentario: true})
        try {
            const resp = await agregarMensajeSolicitud(dataMensaje, id)
            const { mensajes } = this.state
            mensajes.push(resp.data)
            this.setState({
                dataMensaje: {},
                errorsMensaje: [],
                mostrarFrmComentario: false
            })
            this.setState({loadingComentario: false})
        } catch (error) {
            this.setState({loadingComentario: false})
            this.setState({ errorsMensaje: error.data })
        }
    }

    async eliminarMensaje() {
        const { id, mensajes, datosElimComentario } = this.state
        const { idComentario, indice } = datosElimComentario
        await eliminarMensajeSolicitud(id, idComentario)
        mensajes.splice(indice, 1)
        this.setState({
            mensajes,
            dataMensaje: {},
            errorsMensaje: [],
            mostrarConfElimComentario: false
        })
    }

    async agregarDocFirmado(){
        const { id, documentos, dataDocFirmado, idDoc, indiceDoc } = this.state
        const formData = new FormData()
        Object.keys(dataDocFirmado).forEach((key) => {
            formData.append(key, dataDocFirmado[key])
        })
        this.setState({documentoFirmadoGuardarDisabled: true});
        try {
            const resp = await agregarDocumentosFirmadosSolicitud(formData, id, idDoc)
            documentos[indiceDoc].documentos_firmados.push(resp.data)
            this.setState({ documentos, mostrarFrmDocFirm: false, documentoFirmadoGuardarDisabled: false })
        } catch (error) {
            const errorsDocFirmado = error.data
            this.setState({ errorsDocFirmado, documentoFirmadoGuardarDisabled: false })
        }
    }

    mostrarConfElimDocContrato(documentoId, docFirmadoId, indice, indiceDoc) {
        const datosElimContrato = { documentoId, docFirmadoId, indice, indiceDoc }
        this.setState({ mostrarConfElimDocContrato : true, datosElimContrato })
    }

    async eliminarDocFirmado() {
        const { id, documentos, datosElimContrato } = this.state
        const { documentoId, docFirmadoId, indice, indiceDoc } = datosElimContrato
        await eliminarDocumentosFirmadosSolicitud(id, documentoId, docFirmadoId)
        documentos[indiceDoc].documentos_firmados.splice(indice, 1)
        this.setState({ documentos, mostrarConfElimDocContrato : false })        
    }

    async rechazar(){
        const { id, dataRechazo } = this.state
        try {
            await rechazarSolicitud(dataRechazo, id)
            this.setState({ mostrarFrmRechazo: false })
            this.componentDidMount();
        } catch (error) {
            const errorsRechazo = error.data
            this.setState({ errorsRechazo })
        }
    }

    async cancelar(){
        const { id, dataCancelacion } = this.state;
        try {
            await cancelarSolicitud(dataCancelacion, id);
            this.setState({ mostrarFrmCancelacion: false });
            this.componentDidMount();
        } catch (error) {
            const errorsCancelacion = error.data;
            this.setState({ errorsCancelacion });
        }
    }

    cerrarModal() {
        const {validacionesRescate} = this.state;
        validacionesRescate.showValidarRescate = false;
        this.setState({
            mostrarFrmComp : false,
            mostrarFrmDoc : false,
            mostrarFrmRechazo : false,
            mostrarConfElimOrdenDisp: false,
            mostrarConfEnviarOrdenDisp: false,
            mostrarFrmCancelacion : false,
            mostrarConfComp : false,
            mostrarConfCompDoc : false,
            mostrarFrmDocFirm : false,
            mostrarConfElimContrato : false,
            mostrarConfElimDocContrato : false,
            mostrarFrmComentario : false,
            mostrarConfElimComentario : false,
            mostrarModal: false,
            mostrarConfInvalidar: false,
            validacionesRescate,
            mostrarConfValidar: false,
            mostrarConfValidarCuentaClabe: false
        });
    }

    async mostrarFormularioReferencia(editar=false, referencia={}){
        const accionReferencia = editar ? 'editar' : 'agregar';
        const tituloModalReferencia = editar ? 'Editar referencia' : 'Agregar referencia';
        this.setState({mostrarModalReferencia: true, referencia, accionReferencia, tituloModalReferencia})
    }

    async cerrarModalReferencia() {
        this.setState({mostrarModalReferencia: false})
    }

    async guardarReferencia() {
        const { referencia, accionReferencia } = this.state;

        if (accionReferencia === 'editar'){
            try {
                /* const resp =  */await actualizarReferenciaSolicitud(referencia, this.state.id, referencia.id);
                this.mostrarToast('Se actualizó la referencia correctamente');
                this.setState({mostrarModalReferencia: false, referencia: {}});
            } catch (error) {
                // console.log('Ocurrio un error');
                this.setState({errorsReferencia: error.data});
            }
        }else {
            try {
                /* const resp =  */await agregarReferenciaSolicitud(referencia, this.state.id, referencia.id);
                this.mostrarToast('Se agregó la referencia correctamente');
                this.setState({mostrarModalReferencia: false, referencia: {}});
            } catch (error) {
                // console.log('Ocurrio un error');
                this.setState({errorsReferencia: error.data});
            }
        }

        this.refrescarReferencias();
    }

    async refrescarReferencias(){
        const { id } = this.state;
        const res = await obtenerReferenciasSolicitud(id);
        const referencias = res.data;
        this.setState({referencias});
    }

    seleccionarPestana(pestanaSeleccionada) {
        this.setState({ pestanaSeleccionada })
    }

    seleccionarPestanaIA = async(pestanaSeleccionada) => {
        const { id, ia } = this.state;
        ia.cargandoData = true;

        this.setState({ pestanaSeleccionada, ia });

        // Consultamos información de IA
        try {
            const req = await obtenerSolicitudAnalizarIA(id);

            ia.data = req.data;
            ia.puedeAnalizar = true;

        }catch (e) {
            console.log(e);
            ia.puedeAnalizar = false;

        }

        ia.cargandoData = false;
        this.setState(ia);
    }

    validarMontoTotalOrdenDispersion(){
        const { ordenesDispersion } = this.state
        let totalDispersion = 0
        ordenesDispersion.forEach((od, i)=>{
            totalDispersion += parseFloat(od.monto)
        })
        this.setState({totalDispersion})
    }

    async mostrarGenerar() {
        const { totalDispersion, objeto/* , renovacion  */} = this.state
        let dispersionCompleto = false;
        dispersionCompleto = (totalDispersion.toFixed(2) == parseFloat(objeto.monto))
        // switch (renovacion.tipo){
        //     case "renovacion":
        //         dispersionCompleto = (totalDispersion === parseInt(objeto.monto))
        //     break;
        //     case "reestructura vigente":
        //         dispersionCompleto = (totalDispersion <= parseInt(objeto.monto))
        //     break;
        // }

        this.setState({ mostrarModal : true, dispersionCompleto })
    }

    onChange = async (key, e) => {
        const { dataPrestamo } = this.state;
        dataPrestamo[key] = moment(e[0]).format('YYYY-MM-DD');
        this.setState({dataPrestamo})
    }

    async generarPrestamo(){
        const { dataPrestamo, id } = this.state
        try {
            const resp = await generarPrestamo(dataPrestamo, id)
            this.cerrarModal()
            this.mostrarToast(resp.data.data)
            this.props.history.push('/solicitudes')
        } catch (error) {
            const errorsPrestamo = error.data
            console.log('error', errorsPrestamo)
            this.setState({ errorsPrestamo })
        }
    }

    // Start Notas de crédito
    handleChangeNotasCredito = (e) => {
        const {notasDeCredito} = this.state;
        notasDeCredito[e.target.name] = e.target.checked;
        this.setState({notasDeCredito});
    }

    handleSubmitNotasDeCredito = async() => {
        const {id, notasDeCredito} = this.state;
        try {
            /* const req =  */await updateNotasCredito(id, notasDeCredito);
            this.mostrarToast('Se guardó la información con éxito');
        }catch (e) {
            this.mostrarToast('Ocurrió un error', 'error');
        }
    }
    // End Notas de crédito

    // Start Clasificación del credito
    handleChangeClasificacion = (e) => {
        const {clasificacion} = this.state;
        if (e.target.name === 'clasificacion' && clasificacion.clasificacion !== e.target.value){
            clasificacion.subclasificacion = null;
        }
        clasificacion[e.target.name] = e.target.value;
        this.setState({clasificacion});
    };

    handleSubmitClasificacion = async() => {
        const {id, clasificacion} = this.state;

        try {
            /* const req =  */await solicitudPartialUpdate(clasificacion, id);
            this.mostrarToast('Se agregó la clasificación correctamente');
        } catch (error) {
            this.mostrarToast('Ocurrió un error', 'error');
        }
    }

    obtenerOrdenVenta = async() => {
        const {id} = this.state;
        try {
            const req = await solicitudObtenerOrdenVenta(id);
            this.setState({ordenVenta: req.data});
        }catch (e) {
            console.log("Aun no tiene orden de venta");
        }
    }

    generarOrdenVenta = async() => {
        this.setState({disabledSend: true})
        const { id, dataPrestamo} = this.state;
        let { errorsPrestamo } = this.state;

        try {
            // Enviamos primero la clasificacion (temp)
            await this.handleSubmitClasificacion()

            if(dataPrestamo.fecha_primera_amortizacion) {
                errorsPrestamo.fecha_primera_amortizacion = []
                // dataPrestamo.fecha_primera_amortizacion = moment(dataPrestamo.fecha_primera_amortizacion, "DD-MM-YYYY").format("YYYY-MM-DD");

                const req = await solicitudGenerarOrdenVenta(id, dataPrestamo);
                const ordenVenta = req.data
                let disabledButtons = false
                if(ordenVenta.estatus === 'pendiente'){
                    disabledButtons = true
                }
                this.setState({ ordenVenta, errorsPrestamo, mostrarModal: false, disabledSend:false, disabledButtons });
                this.mostrarToast('Se ha solicitado generar el Préstamo, favor de esperar unos minutos.');
            }else{
                errorsPrestamo.fecha_primera_amortizacion.push("Debe seleccionar una fecha.")
                this.setState({errorsPrestamo})
            }
        } catch (error) {
            console.log(error)
            this.setState({mostrarModal: false, disabledSend :false, disabledButtons: false})
            if(error.hasOwnProperty('data')) {
                if (error.data.hasOwnProperty('error')) {
                    mostrarToast(error.data.error, 'error');
                } else if (error.data.hasOwnProperty('mensaje')) {
                    mostrarToast(error.data.mensaje, 'error');
                } else {
                    mostrarToast("Error a enviar petición en NetSuite", "error")
                }
            }else{
                mostrarToast('Ocurrió un error', 'error');
            }
        }
    }
    // End Clasifiacción del credito

    exportar = async () => {
        const { objeto, id } = this.state;
        try {
            const resp = await exportarSolicitud(id);
            const url = window.URL.createObjectURL(new Blob([resp.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                `solicitud_${objeto.num_solicitud}_${moment().format('YYYYMMDD')}.xlsx`
            );
            document.body.appendChild(link);
            link.click();
        } catch (error) {
            console.log(error);
        }
    };

    header = () => {
        const guia = [
            { nombre: 'Solicitudes', url: '/solicitudes', clase: '' },
            { nombre: 'Ver', url: '', clase: 'active' }
        ];

        const titulo = "Ver solicitud";

        const { empleado/* , cotizacion */, objeto, historialPrestamos } = this.state;

        return (
            <div className="row wrapper border-bottom white-bg page-heading">
                <div className="col-lg-2">
                    <h2>{titulo}</h2>
                    <ol className="breadcrumb">
                        {guia.map((obj, indice) => {
                            return (
                            <li className={`breadcrumb-item ${obj.clase}`} key={indice}>
                                {obj.url ? (
                                    <Link to={obj.url}>{obj.nombre}</Link>
                                ) : (
                                    <strong>{obj.nombre}</strong>
                                )}
                            </li>
                            );
                        })}
                    </ol>
                </div>

              <div className="col-lg-10">
                  <div className="row">
                      <div className="col-lg-4 m-t-10">
                          <h4 className="text-center">Empleado</h4>
                          <dl className="row mb-0">
                              <div className="col-sm-3">
                                  <dt>Cliente:</dt>
                              </div>
                              <div className="col-sm-9">
                                  <dd className="mb-1">{ empleado.nombre_completo }</dd>
                              </div>
                          </dl>
                          <dl className="row mb-0">
                              <div className="col-sm-3">
                                  <dt>UUID:</dt>
                              </div>
                              <div className="col-sm-9">
                                  <Link to={`/empleados/ver/${empleado.id}`}>
                                    <dd className="mb-1">{ empleado.uuid }</dd>
                                  </Link>
                              </div>
                          </dl>
                          <dl className="row mb-0">
                              <div className="col-sm-3">
                                  <dt>Empresa:</dt>
                              </div>
                              <div className="col-sm-9">
                                  <dd className="mb-1">{ empleado.empresa_str }</dd>
                              </div>
                          </dl>
                          <dl className="row mb-0">
                              <div className="col-sm-3">
                                  <dt>Score BC:</dt>
                              </div>
                              <div className="col-sm-9">
                                  <dd className="mb-1">{ objeto.score_bc } ({ dateFormat(objeto.fecha_score) })</dd>
                              </div>
                          </dl>
                      </div>

                        <div className="col-lg-3 m-t-10">
                            <h4 className="text-center">Solicitud</h4>
                            <dl className="row mb-0">
                                <div className="col-sm-8">
                                    <dt>Monto solicitado:</dt>
                                </div>
                                <div className="col-sm-4">
                                    <dd className="mb-1">{ currencyFormat(objeto.monto)  }</dd>
                                </div>
                            </dl>
                            <dl className="row mb-0">
                                <div className="col-sm-8">
                                    <dt># de periodos:</dt>
                                </div>
                                <div className="col-sm-4">
                                    <dd className="mb-1">{ objeto.num_amortizaciones }</dd>
                                </div>
                            </dl>
                            <dl className="row mb-0">
                                <div className="col-sm-8">
                                    <dt>Monto a desc:</dt>
                                </div>
                                <div className="col-sm-4">
                                    <dd className="mb-1">{ currencyFormat(objeto.monto_descontar) }</dd>
                                </div>
                            </dl>
                            <dl className="row mb-0">
                                <div className="col-sm-8">
                                    <dt>Periodo:</dt>
                                </div>
                                <div className="col-sm-4">
                                    <dd className="mb-1 text-capitalize">{ objeto.frecuencia_cobro }</dd>
                                </div>
                            </dl>
                        </div>

                        <div className="col-lg-5 m-t-10">
                            <h4 className="text-center">Historial de crédito</h4>

                            {
                                historialPrestamos.length > 0 ?
                                    <table width="100%">
                                        <thead>
                                        <tr>
                                            <th className="text-center">Folio</th>
                                            <th className="text-center">Saldo</th>
                                            <th className="text-center">Pagos</th>
                                            <th className="text-center">Atraso</th>
                                            <th className="text-center">Sig. pago</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            historialPrestamos.map((item, key) => (
                                            <tr key={key}>
                                                <td className="text-center">
                                                    <Link to={`/prestamos/ver/${item.id}`} target='_blank'>{item.folio}</Link>
                                                </td>
                                                <td>{currencyFormat(item.adeudo_total)}</td>
                                                <td
                                                    className="text-center">{`${item.amortizaciones_pagadas}/${item.num_amortizaciones}`}</td>
                                                <td className="text-center">{item.dias_retraso}</td>
                                                <td
                                                    className="text-center">{item.fecha_siguiente_pago ? item.fecha_siguiente_pago : 'N/A'}</td>
                                            </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                :
                                <p>Sin créditos registrados</p>
                            }
                        </div>

                    </div>
                </div>
                <style jsx="true">{`
                    .m-t-10 {
                        margin-top:10px;
                    }
                `}</style>
            </div>
        )
    }

    consulta_buro = async() => {
        this.setState({loadingBC:true, errors_data_bc:[]})
        let { id, objeto, data_bc, estados, municipios } = this.state
        try {
            // console.log("============ DOMICILIO ============")
            let estado_selected = estados.filter( e => e.value === data_bc.estado)[0]
            // console.log(estado_selected)
            let municipio_selected = municipios.filter( e => e.value === data_bc.delegacion_municipio)[0]
            // console.log(municipio_selected)
            data_bc['estado'] = estado_selected.value_o
            data_bc['delegacion_municipio'] = municipio_selected.value_o
            // console.log(data_bc)
            let peticion_bc = await consultarBuroCredito(id, data_bc)
            // console.log(peticion_bc.data)

            let res = await obtenerComprobantesSolicitud(id)
            const comprobantes = res.data

            objeto['score_bc'] = peticion_bc.data.score
            objeto['fecha_score'] = peticion_bc.data.fecha_creacion
            objeto['bc_consulto'] = true

            res = await obtenerSeguimiento(id)
            const seguimiento = res.data

            data_bc['estado'] = estado_selected.value
            data_bc['delegacion_municipio'] = municipio_selected.value

            mostrarToast("Se ha realizado con éxito la consulta a B.C.");
            this.setState({objeto: objeto, comprobantes, seguimiento, confDatosBC: false, loadingBC: false, error_consulta_bc: ''})

        } catch (error) {
            // console.log(error)
            let estado_selected = estados.filter( e => e.value_o === data_bc.estado)[0]
            let municipio_selected = municipios.filter( e => e.value_o === data_bc.delegacion_municipio)[0]
            let error_consulta_bc = ''
            data_bc['estado'] = estado_selected.value
            data_bc['delegacion_municipio'] = municipio_selected.value
            if(error.data.error) {
                mostrarToast("Hubo un error de conexión con B.C.", 'error');
                error_consulta_bc = '"Hubo un error al Consultar BC, favor de hacerlo manualmente."'
            }else{
                mostrarToast("Hubo un error en los datos para consultar B.C.", 'error');
            }
            this.setState({errors_data_bc: error.data, loadingBC: false, error_consulta_bc, data_bc})
            /* ToDo: Validar si viene con error_bc y colocar el mensaje*/
        }

    }

    consulta_buro_sb = async() => {
        this.setState({loadingBC:true, errors_data_bc:[]})
        let { id, objeto, data_bc, estados, municipios } = this.state
        var axios = require('axios');
        var res = null
        try {
            //console.log("============ DOMICILIO ============")
            let estado_selected = estados.filter( e => e.value === data_bc.estado)[0]
            //console.log(estado_selected)
            let municipio_selected = municipios.filter( e => e.value === data_bc.delegacion_municipio)[0]
            //console.log(municipio_selected)
            data_bc['estado'] = estado_selected.value_o
            data_bc['delegacion_municipio'] = municipio_selected.value_o
            //console.log(data_bc)
            res = await getJsonBuroCredito(id, data_bc)
            //console.log(res.data)
            //console.log(data_bc)
            //console.log(data_bc['type'])

            data_bc['estado'] = estado_selected.value
            data_bc['delegacion_municipio'] = municipio_selected.value

        } catch (error) {
            console.log(error)
            let error_consulta_bc = ''
            console.log(error.data.error)
            if(error.data.error) {
                mostrarToast("Hubo un error de conexión con B.C.", 'error');
                error_consulta_bc = '"Hubo un error al Consultar BC, favor de hacerlo manualmente."'
            }else{
                mostrarToast("Hubo un error en los datos para consultar B.C.", 'error');
            }
            this.setState({errors_data_bc: error.data, loadingBC: false, error_consulta_bc})
        }

        if(res){
            var config = {
                method: 'post',
                // url: `http://127.0.0.1:8000/api/v1/buro/testConnectionBC/consulta-buro/`,
                // headers: {
                //     'Authorization': 'Api-Key 8xApZY7n.CsjIMQ9ItdYIaW7s1CPogNQDSuWt7IgA',
                url: `https://api.yuhu.mx/api/v1/buro/testConnectionBC/consulta-buro/`,
                headers: {
                    'Authorization': 'Api-Key BtY7gnsV.tff9etixCi3IXeWkTKR061MYOh0AOW4h',
                    'Content-Type': 'application/json'
                },
                data : { data: res.data, type: data_bc["type"] }
            }

            axios(config)
              .then(async(response) => {
                  // console.log(JSON.stringify(response.data));
                  const peticion_buro = response.data
                  // console.log(peticion_buro)

                  let saveBuro = await saveBuroCredito(id, peticion_buro)
                  // console.log("==================")
                  // console.log(saveBuro)
                  // console.log(saveBuro.data.estatus)
                  // console.log("==================")

                  if (saveBuro.data.estatus === "error_bc" || saveBuro.data.estatus === "error"){
                      mostrarToast("Hubo un error de conexión con B.C.", 'error');
                      this.setState({loadingBC: false, error_consulta_bc: saveBuro.data.mensaje, intentos_bc: saveBuro.data.intentos})
                  }else{
                      let res = await obtenerComprobantesSolicitud(id)
                      const comprobantes = res.data

                      objeto['score_bc'] = saveBuro.data.score
                      objeto['fecha_score'] = saveBuro.data.fecha_creacion
                      objeto['bc_consulto'] = true

                      res = await obtenerSeguimiento(id)
                      const seguimiento = res.data

                      mostrarToast("Se ha realizado con éxito la consulta a B.C.");
                      this.setState({objeto: objeto, comprobantes, seguimiento, confDatosBC: false, loadingBC: false, error_consulta_bc: ''})
                  }

              })
              .catch((error) => {
                  console.log(error)
                  mostrarToast("Hubo un error de conexión con B.C.", 'error');
                  this.setState({loadingBC: false, error_consulta_bc: "Hubo un error al Consultar BC, favor de hacerlo manualmente."})
              })
        }

    }

    onChangeSearch = async (campo, e) => {
        const { datos } = this.state;
        datos[campo] = e.target.value;
        this.setState({ datos });
        if (datos[campo]  === '') {
            await this.limpiar()
        }
    }

    limpiar = async () => {
        this.setState({
            params: {},
            datos: { search: '' },
            prestamoRenovar: {},
        });
    };

    buscar = async () => {
        let { params } = this.state;
        const {
            empleado,
            datos: { search }
        } = this.state;
        delete params.search;
        delete params.page;
        params = Object.assign({ search }, params);
        try {
            const res = await obtenerPrestamoActivoRenovacion(empleado.id, params);
            const prestamoRenovar = res.data
            if (!prestamoRenovar.mensaje){
                this.setState({ prestamoRenovar: prestamoRenovar, mensaje: '' })
            }else{
                const { data: { mensaje } } = res;
                this.setState({ prestamoRenovar: {}, mensaje })
            }
            
        } catch (err) {
            // const { data: { mensaje } } =  err
            // this.setState({ prestamoRenovar: {}, mensaje })
            console.log(err)
        }
    }

    handleKeyDown = async(e) => {
        if (e.key === 'Enter') {
            await this.buscar()
        }
    }

    crearVinculo = async() => {
        const { id, objeto, prestamoRenovar } = this.state
        let datos = {}
        let avance_pagos = prestamoRenovar.avance_pagos.split('/')
        let total_dispersion_cliente = objeto.monto - prestamoRenovar.monto_saldar

        datos['prestamo_anterior'] = prestamoRenovar.id
        datos['avance_capital'] = prestamoRenovar.avance_capital
        datos['avance_pagos'] = avance_pagos[0].trim()
        datos['capital'] = prestamoRenovar.capital
        datos['interes'] = prestamoRenovar.intereses_ordinarios
        datos['monto_liquidar_credito'] = prestamoRenovar.monto_saldar
        datos['total_dispersion_cliente'] = total_dispersion_cliente.toFixed(2)
        try {
            const res = await crearVinculoRenovacion(datos, id)
            const renovacion = res.data
            this.setState({renovacion: renovacion})
            await this.actualizarTablaOrdenesDispersion()
            await this.validarMontoTotalOrdenDispersion()
            this.mostrarToast('Se creó la Renovación y la Orden Dispersión correspondiente.');

        }catch (e) {
            this.mostrarToast('Ocurrió un error', 'error');
            console.log(e)
        }
    }

    eliminarVinculo = async() => {
        const { renovacion/* , ordenesDispersion  */} = this.state
        try{
            const res = await eliminarVinculoRenovacion(renovacion.id)
            const renov = res.data
            if(renov.id){
                this.mostrarToast('No se pudo eliminar la Renovación','error');
            }else {
                this.setState({
                    renovacion: renov,
                    confirmarEliminarVinculo: false
                })
                await this.limpiar()
                await this.actualizarTablaOrdenesDispersion()
                await this.validarMontoTotalOrdenDispersion()
                this.mostrarToast('Se eliminó la Renovación y la Orden Dispersión correspondiente.');
            }
        }catch (e) {
            this.mostrarToast('Ocurrió un error', 'error');
            console.log(e)
        }
    }

    actualizarTablaOrdenesDispersion = async() => {
        const { id } = this.state
        try{
            const res = await obtenerOrdenesDispersionSolicitud(id)
            const ordenesDispersion = this.addPropsObjectDispersion(res.data);
            this.setState({ordenesDispersion})
        }catch (e) {
            console.log(e)
        }
    }

    confirmar_datos_bc = () => {
        this.setState({confDatosBC: true})
    }

    reactivarSolicitud = async (solicitud) => {
        console.log('esta es la solicitud --> ', solicitud);
        try {
            await reactivarSolicitudCredito(solicitud.id);
            this.setState({modalReactivarSolicitud: false});
            this.componentDidMount();
        } catch (err) {
            console.log(err);
        }
    }

    actualizarDatosSolicitud = () => {
        this.componentDidMount();
    }

    empleadoForm(){

        const { data_bc, errors_data_bc, estados, municipios} = this.state;

        return (
          <div>
              <div className="row">
                  <div className="col-sm-6">
                      <div className={ errors_data_bc.nombre || errors_data_bc.primer_nombre ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Nombre*</label>
                          <div className="col-sm-8">
                              <input type="text" name="nombre" className="form-control"  onChange={this.handleChangeDataBC} value={data_bc.nombre || ''} />
                              { errors_data_bc.nombre || errors_data_bc.primer_nombre && <span className="form-text m-b-none text-error">{ errors_data_bc.nombre || errors_data_bc.primer_nombre}</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errors_data_bc.apellido_paterno ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Apellido paterno*</label>
                          <div className="col-sm-8">
                              <input type="text" name="apellido_paterno" className="form-control" onChange={this.handleChangeDataBC} value={data_bc.apellido_paterno || ''} />
                              { errors_data_bc.apellido_paterno && <span className="form-text m-b-none text-error">{ errors_data_bc.apellido_paterno }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errors_data_bc.apellido_materno ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Apellido materno</label>
                          <div className="col-sm-8">
                              <input type="text" name="apellido_materno" className="form-control" onChange={this.handleChangeDataBC} value={data_bc.apellido_materno || ''} />
                              { errors_data_bc.apellido_materno && <span className="form-text m-b-none text-error">{ errors_data_bc.apellido_materno }</span> }
                          </div>
                      </div>
                  </div>
                  <DatePicker
                      key={'fecha_nacimiento'}
                      label={'Fecha de nacimiento*'}
                      name={'fecha_nacimiento'}
                      value={data_bc.fecha_nacimiento || ''}
                      options={{ locale: Spanish, maxDate: 'today', dateFormat: 'Y-m-d'}}
                      helpText={''}
                      error={errors_data_bc.fecha_nacimiento ? errors_data_bc.fecha_nacimiento : []}
                      onChange={(v) => {
                        console.log(v.length)
                        if (v.length) {
                            data_bc.fecha_nacimiento = moment(v[0]).format('YYYY-MM-DD');
                        }else{
                            data_bc.fecha_nacimiento = ''
                        }
                        console.log(data_bc.fecha_nacimiento)
                          this.setState({ data_bc });
                      }}
                  />
                  <div className="col-sm-6">
                      <div className={ errors_data_bc.rfc ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">RFC*</label>
                          <div className="col-sm-8">
                              <input type="text" name="rfc" className="form-control" onChange={this.handleChangeDataBC} value={data_bc.rfc || ''}/>
                              { errors_data_bc.rfc && <span className="form-text m-b-none text-error">{ errors_data_bc.rfc }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errors_data_bc.direccion1 ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Calle y Número</label>
                          <div className="col-sm-8">
                              <input type="text" name="direccion1" className="form-control" onChange={this.handleChangeDataBC} value={data_bc.direccion1 || ''} />
                              { errors_data_bc.direccion1 && <span className="form-text m-b-none text-error">{ errors_data_bc.direccion1 }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errors_data_bc.colonia_poblacion ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Colonia*</label>
                          <div className="col-sm-8">
                              <input type="text" name="colonia_poblacion" className="form-control" onChange={this.handleChangeDomicilio} value={data_bc.colonia_poblacion || ''} />
                              { errors_data_bc.colonia_poblacion && <span className="form-text m-b-none text-error">{ errors_data_bc.colonia_poblacion }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errors_data_bc.codigo_postal ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Código postal*</label>
                          <div className="col-sm-8">
                              <input type="text" name="codigo_postal" className="form-control" onChange={this.handleChangeDomicilio} value={data_bc.codigo_postal || ''} />
                              { errors_data_bc.codigo_postal && <span className="form-text m-b-none text-error">{ errors_data_bc.codigo_postal }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errors_data_bc.estado ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Estado*</label>
                          <div className="col-sm-8">
                              <select name="estado" id="estado_select" className="form-control" value={data_bc.estado || ''} onChange={this.handleChangeDomicilio}>
                                  { estados.map((estado) => {
                                      return <option key={`estado-${estado.value}`} value={estado.value}>{estado.label}</option>
                                  }) }
                              </select>
                              { errors_data_bc.estado && <span className="form-text m-b-none text-error">{ errors_data_bc.estado }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errors_data_bc.ciudad ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Ciudad*</label>
                          <div className="col-sm-8">
                              <input type="text" name="ciudad" className="form-control" onChange={this.handleChangeDomicilio} value={data_bc.ciudad || ''} />
                              { errors_data_bc.ciudad && <span className="form-text m-b-none text-error">{ errors_data_bc.ciudad }</span> }
                          </div>
                      </div>
                  </div>
                  <div className="col-sm-6">
                      <div className={ errors_data_bc.delegacion_municipio ? "form-group row has-error" : "form-group row" }>
                          <label className="col-sm-4 col-form-label">Municipio*</label>
                          <div className="col-sm-8">
                              <select name="delegacion_municipio" id="municipio_select" className="form-control" value={data_bc.delegacion_municipio || ''} onChange={this.handleChangeDomicilio}>
                                  { municipios.map((municipio) => {
                                      return <option key={`municipio-${municipio.value}`} value={municipio.value}>{municipio.label}</option>
                                  }) }
                              </select>
                              { errors_data_bc.delegacion_municipio && <span className="form-text m-b-none text-error">{ errors_data_bc.delegacion_municipio }</span> }
                          </div>
                      </div>
                  </div>
              </div>
          </div>
        )
    }

    handleChangeDataBC = async (e) => {
        const { data_bc } = this.state;
        const {target: {name, value}} = e;

        data_bc[name] = value;

        if (name === 'estado') {
            const municipios = [{label: '--Selecciona--', value: ''}];
            data_bc.delegacion_municipio = '';
            if (value !== ''){
                const respMunicipios = await obtenerMunicipios(value);
                respMunicipios.data.forEach(m => {
                    municipios.push({label: m.nombre, value: m.id});
                });
            }

            this.setState({ data_bc, municipios })
        } else {
            this.setState({ data_bc })
        }

    }

    analizarIA = async (e) => {
        const { id, ia } = this.state;
        ia.analizando = true;
        this.setState(ia);

        try {
            const resp = await analizarSolicitudIA(id);

            if (resp.data.success){
                this.mostrarToast('Se analizó con IA exitosamente', '')
                this.actualizarDatosSolicitud();
                // TODO: Actualizar información de IA.
                ia.data.analisis_yuhu_ia = resp.data.analisis_yuhu_ia;
                ia.data.analisis_yuhu_score_ia = resp.data.analisis_yuhu_score_ia;
                this.setState(ia);
            }else {
                this.mostrarToast('Ocurrió un error en el servidor', 'error');
            }

        } catch (err) {
            console.log(err);
            this.mostrarToast('Ocurrió un error en el servidor', 'error');
        }

        ia.analizando = false;
        this.setState(ia);

    }

    showValidarRescate = () => {
        const {validacionesRescate} = this.state;
        validacionesRescate.showValidarRescate = true;
        this.setState(validacionesRescate);
    }

    handleChangeValidacionRescate = (e) => {
        const {validacionesRescate} = this.state;
        validacionesRescate.nuevaValidacion[e.target.name] = e.target.value;
        this.setState(validacionesRescate);
    }

    handleSubmitValidacionRescate = async () => {
        const {id, validacionesRescate} = this.state;

        try {
            const req = await agregarValidacionRescateSolicitud(id, validacionesRescate.nuevaValidacion);
            mostrarToast('Se pasó a validación de rescate con éxito');
            validacionesRescate.validaciones.push(req.data);
        }catch (error) {
            console.log(error);
            mostrarToast('Ocurrió un error al pasar la solicitud a validación de rescate', 'error');
        }
        validacionesRescate.showValidarRescate = false;
        validacionesRescate.nuevaValidacion = {};
        this.setState(validacionesRescate);

        // Actualizamos la información de la solicitud
        this.actualizarDatosSolicitud();

    }

    render () {
        const guia = [
            { nombre: 'Solicitudes', url: '/solicitudes', clase: '' },
            { nombre: 'Ver', url: '', clase: 'active' }
        ]

        const {
            pestanaSeleccionada,
            titulo,
            mostrarFrmOrdenDispEdit,
            mostrarFrmComp,
            mostrarConfComp,
            mostrarConfCompDoc,
            mostrarConfInvalidar,
            mostrarConfValidar,
            mostrarConfReenvio,
            mostrarFrmDoc,
            mostrarFrmDocFirm,
            mostrarConfElimContrato,
            mostrarFrmRechazo,
            objeto,
            empleado,
            valid_rfc,
            valid_curp,
            producto,
            infoBancaria,
            domicilio,
            cotizacion,
            percepciones,
            // referencias,
            // cotCreColumnas,
            ordenesDispersion,
            ordenDispersion,
            errorsOrdenDisp,
            comprobantes,
            // comprobantesCols,
            // comprobantesBtns,
            // tiposDocumento,
            opcionesTiposDocumento,
            mensajes,
            documentos,
            seguimiento,
            dataEstatus,
            // dataErrorDispersion,
            historialPrestamos,
            historialPrestamosCols,
            // informacionCrediticia,
            editarComprobante,
            // editarComprobanteDisp,
            mostrarConfElimDocContrato,
            mostrarFrmComentario,
            mostrarConfValidarCuentaClabe,
            mostrarConfElimComentario,
            mostrarModal,
            dataPrestamo,
            errorsPrestamo,
            // mostrarModalReferencia,
            // tituloModalReferencia,
            // accionReferencia,
            // referencia,
            // errorsReferencia,
            // showReqDispersion,
            // dispersionAutorizada,
            // errorsDispersionAutorizada,
            formOrdenDisp,
            cuentasBancarias,
            mostrarConfElimOrdenDisp,
            mostrarConfEnviarOrdenDisp,
            dispersionCompleto,
            totalDispersion,
            renovacion,
            // ordenVenta,
            disabledButtons,
            disabledSend,
            datos: { search },
            prestamoRenovar,
            mensaje,
            confirmarEliminarVinculo,
            confDatosBC,
            confUnificacion,
            unificandoEmpleado,
            loadingBC,
            error_consulta_bc,
            intentos_bc,
            INEFrontalValido,
            infoBancariaEstatus,
            bancos,
            datosEnviarCompOrdenDisp,
            motivosRechazo,
            dataUnificacion,
            dataEmpleadoUnificacion,
            ia
        } = this.state;

        let {
            id,
            // ocultarTabs,
            // referenciaId
        } = this.state;

        let formComprobante = {};

        if (editarComprobante){
            formComprobante = {
                fields:[{
                    name:'documento',
                    className: 'col-md-12',
                    label:'Comprobante',
                    type:'file',
                    helpText:'',
                    id:'archivoComprobante'
                }],
                data: this.state.dataComprobante,
                errors: this.state.errorsComprobante
            }
        }else {
            formComprobante = {
                fields:[{
                    name:'tipo_documento',
                    className: 'col-md-12',
                    label:'Tipo de documento',
                    type:'select',
                    options: opcionesTiposDocumento,
                    helpText:'',
                    id:'archivoComprobante'
                },{
                    name:'documento',
                    className: 'col-md-12',
                    label:'Comprobante',
                    type:'file',
                    helpText:''
                }],
                data: this.state.dataComprobante,
                errors: this.state.errorsComprobante
            }
        }
        // let nombre_xml = (typeof this.state.ordenDispersion.name_xml === 'undefined') ? '':this.state.ordenDispersion.name_xml
        // let nombre_pdf = (typeof this.state.ordenDispersion.name_pdf === 'undefined') ? '':this.state.ordenDispersion.name_pdf

        const formComprobanteDisp = {
            fields:[{
                name:'beneficiario_banco',
                label:'Banco Beneficiario',
                type:'select',
                helpText:'',
                options: bancos,
                id:'cuentaOrdenante'
            },{
                name:'beneficiario_clabe',
                label:'CLABE Beneficiario',
                type:'text',
                helpText:''
            },{
                name:'beneficiario_nombre',
                label:'Nombre Beneficiario',
                type:'text',
                helpText:''
            },
            //     {
            //     name:'beneficiario_rfc_curp',
            //     label:'CURP / RFC',
            //     type:'text',
            //     helpText:''
            // },
            {
                name:'monto',
                label:'Monto',
                type:'text',
                helpText:''
            },{
                name:'cuenta_ordenante',
                label:'Cuenta Ordenante',
                type:'select',
                options: cuentasBancarias,
                helpText:'',
                id:'cuentaOrdenante'
            },{
                name:'dispersion_realizada',
                label:'Dispersion Realizada',
                type:'checkbox',
                helpText:'Selecciona si la dispersión ya ha sido realizada',
                id:'dispersionRealizada'
            },{
                name:'clave_rastreo',
                label:'Clave Rastreo',
                type:'text',
                helpText:'',
            },
            // {
            //     name:'concepto',
            //     label:'Concepto',
            //     type:'text',
            //     helpText:''
            // },{
            //     name:'referencia',
            //     label:'Referencia',
            //     type:'text',
            //     helpText:''
            // },
            {
                name:'fecha_dispersion',
                label:'Fecha Dispersión',
                type:'date',
                options: {dateFormat: 'Y-m-d', locale: Spanish},
                disabled: false,
                helpText: '',
            },
            // {
            //     name:'estatus',
            //     label:'Estatus',
            //     type:'select',
            //     options: [
            //         {label:'Seleccionar...', value:''},
            //         {label: 'Pendiente', value:'pendiente'},
            //         {label: 'Error', value:'error'},
            //         {label: 'Exito', value:'exito'}
            //     ],
            //     disabled: false,
            //     helpText: '',
            // },{
            //     name:'error',
            //     label:'Error',
            //     type:'checkbox',
            //     disabled: false,
            //     helpText: '',
            // },{
            //     name:'tipo_error',
            //     label:'Tipo de Error',
            //     type:'select',
            //     options: [
            //         {label:'Seleccionar...', value:''},
            //         {label:'Cuenta inexistente', value:'1'},
            //         {label:'Cuenta bloqueada', value:'2'},
            //         {label:'Cuenta cancelada', value:'3'},
            //         {label:'Cuenta en otra divisa', value:'4'},
            //         {label:'Cuenta no pertenece al banco receptor', value:'5'},
            //         {label:'Beneficiario no reconoce el pago', value:'6'},
            //         {label:'Falta información mandatorio para completar el pago', value:'7'},
            //         {label:'Tipo de pago erróneo', value:'8'},
            //         {label:'Tipo de operación errónea', value:'9'},
            //         {label:'Tipo de cuenta no corresponde', value:'10'},
            //         {label:'A solicitud del emisor', value:'11'},
            //         {label:'Carácter inválido', value:'12'},
            //         {label:'Excede el límite de saldo autorizado de la cuenta', value:'13'},
            //         {label:'Excede el límite de abonos permitidos en el mes en la cuenta', value:'14'},
            //         {label:'Número de telefonía móvil no registrado', value:'15'},
            //         {label:'Cuenta adicional no recibe pagos que no proceden de Banxico', value:'16'},
            //     ],
            //     helpText:'',
            // },{
            //     name:'error_descripcion',
            //     label:'Descripción error',
            //     type:'text',
            //     helpText:''
            // },{
            //     name:'comprobante_pago_xml',
            //     className: 'col-md-12',
            //     label: 'XML ['+nombre_xml+']',
            //     type:'file',
            //     helpText:'',
            //     id:'archivoComprobanteDispXML'
            // },{
            //     name:'comprobante_pago_pdf',
            //     className: 'col-md-12',
            //     label: 'PDF ['+nombre_pdf+']',
            //     type:'file',
            //     helpText:'',
            //     id:'archivoComprobanteDispPDF'
            // }
            ],
            data: ordenDispersion,
            errors: errorsOrdenDisp
        }

        const formDocumento = {
            fields:[{
                name:'nombre',
                className: 'col-md-12',
                label:'Nombre',
                type:'text',
                helpText:''
            },{
                name:'documento',
                className: 'col-md-12',
                label:'Documento',
                type:'file',
                helpText:''
            }],
            data: this.state.dataDocumento,
            errors: this.state.errorsDocumento
        }

        const formDocFirmado = {
            fields:[{
                name:'documento',
                className: 'col-md-12',
                label:'Documento',
                type:'file',
                helpText:''
            }],
            data: this.state.dataDocFirmado,
            errors: this.state.errorsDocFirmado
        }

        const frmMensaje = {
            button:{
                label:'Guardar',
                onSubmit: async (event, values) => {
                    try {
                        const resp = await agregarMensajeSolicitud(values, id)
                        const { mensajes } = this.state
                        mensajes.push(resp.data)
                        this.setState({ dataMensaje: {}, errorsMensaje: [] })
                    } catch (error) {
                        this.setState({ errorsMensaje: error.data })
                    }
                }
            },
            fields:[{
                name:'comentario',
                label:'Comentario',
                type:'textarea',
                helpText:'',
                className: 'form-control',
                labelClassName: 'col-sm-3',
                controlClassName: 'col-sm-9'
            },{
                name:'visible_solicitante',
                label:'Visible solicitante',
                type:'checkbox',
                helpText:'',
                className: 'col-sm-12',
                labelClassName: 'col-sm-3',
                controlClassName: 'col-sm-9'
            }],
            data: this.state.dataMensaje,
            errors: this.state.errorsMensaje
        }

        const formRechazarSol = {
            fields:[{
                name:'motivo_rechazo',
                className: 'col-md-12',
                label:'Motivo del rechazo',
                type:'select',
                options: motivosRechazo,
                helpText:''
            },{
                name:'comentario',
                className: 'col-md-12',
                label:'Comentario',
                type:'text',
                helpText:''
            },{
                name:'enviar_email_activacion',
                className: 'col-md-12',
                label:'Enviar comentario al solicitante',
                type:'checkbox',
                helpText:''
            }],
            data: this.state.dataRechazo,
            errors: this.state.errorsRechazo
        }

        const formCancelarSol = {
            fields:[{
                name:'motivo_cancelacion',
                className: 'col-md-12',
                label:'Motivo de cancelación',
                type:'select',
                options: [
                    {label:'Seleccionar...', value:''},
                    // {label:'No quiso firmar buró', value:'no quiso firmar buro'},
                    // {label:'Nos tardamos mucho', value:'nos tardamos mucho'},
                    // {label:'Se le hicieron muchos requisitos', value:'se le hicieron muchos requisitos'},
                    // {label:'Quería mas dinero', value:'queria mas dinero'},
                    // {label:'Se le hizo muy caro', value:'se le hizo muy caro'},
                    // {label:'Ya no le interesa', value:'ya no le interesa'},
                    // {label:'Después nos busca', value:'despues nos busca'},
                    // {label:'Encontró otra opción', value:'encontro otra opcion'},
                    {label:'Antigüedad laboral', value:'antigüedad laboral'},
                    {label:'Cancelado por cliente desde landing', value:'cancelado por cliente desde landing'},
                    {label:'Capacidad de pago', value:'capacidad de pago'},
                    {label:'Deja en visto', value:'deja en visto'},
                    {label:'Después nos busca', value:'despues nos busca'},
                    {label:'Encontró otra opción', value:'encontro otra opción'},
                    {label:'Giro no permitido', value:'giro no permitido'},
                    {label:'Imposible renovar', value:'imposible renovar'},
                    {label:'Indeciso', value:'indeciso'},
                    {label:'Marketing', value:'marketing'},
                    {label:'No comprueba ingresos', value:'no comprueba ingresos'},
                    {label:'No proporcionó documentación', value:'no proporciono documentacion'},
                    {label:'No Proporcionó ID', value:'no proporciono ID'},
                    {label:'No quiso firmar buró', value:'no quiso firmar buro'},
                    {label:'No respondió SMS', value:'no respondio sms'},
                    {label:'No solicitó el trámite', value:'no solicito el tramite'},
                    {label:'Nos tardamos mucho', value:'nos tardamos mucho'},
                    {label:'Quería mas dinero', value:'queria mas dinero'},
                    {label:'Se le hicieron muchos requisitos', value:'se le hicieron muchos requisitos'},
                    {label:'Se le hizo muy caro', value:'se le hizo muy caro'},
                    {label:'Sin ID vigente', value:'sin id vigente'},
                    {label:'Solicitud duplicada', value:'solicitud duplicada'},
                    {label:'Ya no hubo respuesta', value:'ya no hubo respuesta'},
                    {label:'Ya no le interesa', value:'ya no le interesa'},
                    {label:'Cliente en mora de 5 a 35 días', value:'cliente en mora de 5 a 35 dias'},

                ],
                helpText:''
            },{
                name:'comentario',
                className: 'col-md-12',
                label:'Comentario',
                type:'text',
                helpText:''
            }],
            data: this.state.dataCancelacion,
            errors: this.state.errorsCancelacion
        }

        // const formReferencia = {
        //     button: {
        //         label: 'Guardar',
        //         onSubmit: async (event, values) => {
        //             console.log(values)
        //         }
        //     },
        //     fields: [
        //         {
        //             name: 'nombre',
        //             label: 'Nombre',
        //             type: 'text',
        //             className: 'col-sm-12'
        //         },
        //         {
        //             name: 'telefono',
        //             label: 'Teléfono',
        //             type: 'text',
        //             className: 'col-sm-12',
        //             maxLength: 10,
        //         },
        //         {
        //             name: 'domicilio',
        //             label: 'Domicilio',
        //             type: 'text',
        //             className: 'col-sm-12'
        //         },
        //         {
        //             name: 'parentesco',
        //             label: 'Parentesco',
        //             type: 'text',
        //             className: 'col-sm-12'
        //         },
        //         {
        //             name: 'comentario',
        //             label: 'Comentario',
        //             type: 'text',
        //             className: 'col-sm-12'
        //         },
        //     ],
        //     data: referencia,
        //     errors: errorsReferencia
        // };

        const formEstatus = {
            button: {
                label:'Guardar',
                onSubmit: async (event, values) => {
                    try {
                        await actualizarEstatusSolicitud(values, id)
                        const res = await obtenerSeguimiento(id)
                        const seguimiento = res.data
                        this.setState({ seguimiento })
                        this.mostrarToast('Estatus actualizado')
                    } catch (error) {
                        if (error.status === 500) {
                            this.mostrarToast('Ocurrio un error en el servidor.', 'error');
                        } else if (error.status === 400) {
                            // Error de formulario
                            let message = "Error en el formulario";
                            if (error.data && error.data.non_field_errors){
                                message = error.data.non_field_errors;
                            }
                            if(error.data.exception){
                                message = error.data.exception;
                            }
                          this.mostrarToast(message, 'error');
                        }else{
                            this.mostrarToast(error.data.message, 'error');
                        }
                        const errorsEstatus = error.data
                        console.log(error.data);
                        this.setState({ errorsEstatus })
                    }
                }
            },
            fields:[{
                name:'estatus',
                label:'Estatus',
                type:'select',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Incompleta', value:'incompleta'},
                    {label:'Preanálisis', value:'preanalisis'},
                    {label:'Stand By', value:'stand by'},
                    {label:'Validar rescate', value:'validar rescate', disabled:true},
                    {label:'Pendiente de analizar', value:'pendiente analizar'},
                    {label:'Comité', value:'comite'},
                    {label:'Autorizada', value:'autorizada'},
                    {label:'Condicionado', value:'condicionado'},
                    {label:'Pendiente de firmar', value:'pendiente firmar'},
                    {label:'Por dispersar', value:'por dispersar'},
                    {label:'Revolvente autorizado', value:'revolvente autorizado'},
                    {label:'Por ejercer', value:'por ejercer'},
                    // {label:'Cancelada por el cliente', value:'cancelada'},
                ],
                helpText:'',
                className: 'col-sm-12'
            },{
                name:'analisis_buro',
                label:'Análisis de buró de crédito',
                type:'select',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Aprobado', value:'aprobado'},
                    {label:'Pendiente', value:'pendiente'},
                    {label:'Rechazado', value:'rechazado'}
                ],
                helpText:'',
                className: 'col-sm-12'
            },{
                name:'analisis_credito',
                label:'Análisis de crédito',
                type:'select',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Aprobado', value:'aprobado'},
                    {label:'Pendiente', value:'pendiente'},
                    {label:'Rechazado', value:'rechazado'}
                ],
                helpText:'',
                className: 'col-sm-12'
            },{
                name:'pld',
                label:'PLD',
                type:'select',
                options: [
                    {label:'Por consultar', value:'por consultar'},
                    {label:'Si', value:'si'},
                    {label:'No', value:'no'},
                ],
                helpText:'',
                className: 'col-sm-12'
            },{
                name:'revision_documentos',
                label:'Revisión de documentos',
                type:'select',
                options: [
                    {label:'Seleccionar...', value:''},
                    {label:'Aprobado', value:'aprobado'},
                    {label:'Pendiente', value:'pendiente'},
                    {label:'Rechazado', value:'rechazado'}
                ],
                helpText:'',
                className: 'col-sm-12'
            }],
            data: this.state.dataEstatus,
            errors: this.state.errorsEstatus
        };

        // const rowPregunta = (
        //     pregunta,
        //     nombre,
        //     checkSi,
        //     checkNo,
        //     onChangeSi,
        //     onChangeNo,
        //     error = [],
        //     respuestaSi = 'Si',
        //     respuestaNo = 'No',
        // ) => (
        //     <div className="row mt2">
        //         <div className="col-sm-6">
        //             <span>{pregunta}</span>
        //             {error.map((obj, index) => (
        //             <div key={`${nombre}-${index}`} className="invalid-feedback">
        //                 {obj}
        //             </div>
        //             ))}
        //         </div>
        //         <div className="col-sm-6 flex-start">
        //             <div className="custom-control custom-radio">
        //                 <input
        //                     type="radio"
        //                     id={`${nombre}1`}
        //                     className="custom-control-input"
        //                     name="dispersion"
        //                     checked={checkSi}
        //                     onChange={onChangeSi}
        //                 />
        //                 <label className="custom-control-label" htmlFor={`${nombre}1`}>
        //                     {respuestaSi}
        //                 </label>
        //             </div>
        //             <div className="custom-control custom-radio ml2">
        //                 <input
        //                     type="radio"
        //                     id={`${nombre}2`}
        //                     className="custom-control-input"
        //                     name="dispersion"
        //                     checked={checkNo}
        //                     onChange={onChangeNo}
        //                 />
        //                 <label className="custom-control-label" htmlFor={`${nombre}2`}>
        //                     {respuestaNo}
        //                 </label>
        //             </div>
        //         </div>
        //     </div>
        // );

        return (
            <Autenticado titulo={ titulo } guia={ guia } header>

                { this.header() }

                <div className="wrapper wrapper-content">
                    <div>
                        <div className="row">
                            <div className="col-4">
                                { objeto.msi && <span className="bg-danger p-w-sm"><b>Meses Sin Intereses</b></span> }
                            </div>
                            <div className="col-8">
                                <button type="button" onClick={this.exportar} className="btn btn-sm btn-primary fa-margin right">
                                    <i className="fa fa-download fa-margin" />
                                    Exportar
                                </button>
                                {/* Boton de unificacion   */}
                                { verificarPermiso('empleados.add_empleadounificacion,') && objeto.requiere_unificacion &&
                                    <button type="button" onClick={() => this.obtenerInformacionUnificacion()} className="btn btn-sm btn-danger right btns-space">
                                    Unificar
                                    </button>
                                }
                                {dataUnificacion.empleado_nuevo && 
                                dataUnificacion.empleados_anteriores[0] ?
                                    
                                <Modal visible={ confUnificacion } width="80%" height="90%">
                                <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
                                        <div className="modal-header">
                                            Selecciona la información para asignarla al empleado unificado
                                        </div>
                                        <div className="modal-body " style={{'overflow-y':'scroll'}}>
                                        <div className="row">
                                            <div className="col-6 m-b-md">
                                                <h3>Empleado nuevo</h3>
                                            </div>
                                            <div className="col-6 m-b-md">
                                                <h3>Empleado anterior</h3>
                                            </div>
                                            <div className="col-6 m-b-md">
                                                <h5> {dataUnificacion.empleado_nuevo.nombre_completo} </h5>
                                            </div>
                                            <div className="col-6 m-b-md">
                                                <h5> {dataUnificacion.empleados_anteriores[0].nombre_completo} </h5>
                                            </div>
                                            {dataUnificacion.empleado_nuevo.email != dataUnificacion.empleados_anteriores[0].email
                                             ?    
                                            <div className="col-12 m-b-md">
                                                <h5>Correo: </h5>
                                                <div className="row">
                                                <Radio
                                                        key={'email_empleado_nuevo'}
                                                        label={dataUnificacion.empleado_nuevo.email}
                                                        name={'email'}
                                                        value={dataUnificacion.empleado_nuevo.email}
                                                        type={'radio'}
                                                        onChange={this.handleChangeDataUnificacion}
                                                        checked={dataEmpleadoUnificacion.email === dataUnificacion.empleado_nuevo.email}
                                                        labelClassName = {'col-sm-10'}
                                                        controlClassName = {'col-sm-2'}
                                                        
                                                        error={[]}
                                                    />
                                                    <Radio
                                                        key={'email_empleado_anterior'}
                                                        label={dataUnificacion.empleados_anteriores[0].email}
                                                        name={'email'}
                                                        value={dataUnificacion.empleados_anteriores[0].email}
                                                        type={'radio'}
                                                        onChange={this.handleChangeDataUnificacion}
                                                        checked={dataEmpleadoUnificacion.email === dataUnificacion.empleados_anteriores[0].email}
                                                        labelClassName = {'col-sm-10'}
                                                        controlClassName = {'col-sm-2'}
                                                        error={[]}
                                                    />
                                                </div>
                                           </div>
                                           : null }
                                        {dataUnificacion.empleado_nuevo.telefono_celular != dataUnificacion.empleados_anteriores[0].telefono_celular 
                                         ? 
                                            <div className="col-12 m-b-md">
                                                <h5>Celular: </h5>
                                                <div className="row">
                                                <Radio
                                                        key={'celular_empleado_nuevo'}
                                                        label={dataUnificacion.empleado_nuevo.telefono_celular}
                                                        name={'telefono_celular'}
                                                        value={dataUnificacion.empleado_nuevo.telefono_celular}
                                                        type={'radio'}
                                                        onChange={this.handleChangeDataUnificacion}
                                                        checked={dataEmpleadoUnificacion.telefono_celular === dataUnificacion.empleado_nuevo.telefono_celular}
                                                        labelClassName = {'col-sm-10'}
                                                        controlClassName = {'col-sm-2'}
                                                        disabled = {dataUnificacion.empleado_nuevo.telefono_celular === null }
                                                        error={[]}
                                                    />
                                                    <Radio
                                                        key={'celular_empleado_anterior'}
                                                        label={dataUnificacion.empleados_anteriores[0].telefono_celular}
                                                        name={'telefono_celular'}
                                                        value={dataUnificacion.empleados_anteriores[0].telefono_celular}
                                                        type={'radio'}
                                                        onChange={this.handleChangeDataUnificacion}
                                                        checked={dataEmpleadoUnificacion.telefono_celular === dataUnificacion.empleados_anteriores[0].telefono_celular}
                                                        labelClassName = {'col-sm-10'}
                                                        controlClassName = {'col-sm-2'}
                                                        disabled = {dataUnificacion.empleados_anteriores[0].telefono_celular === null }
                                                        error={[]}
                                                    />
                                                </div>
                                           </div>
                                           : null}
                                            <div className="col-12 m-b-md">
                                                <h5>CURP: </h5>
                                                <div className="row">
                                                <Radio
                                                        key={'curp_empleado_nuevo'}
                                                        label={dataUnificacion.empleado_nuevo.curp}
                                                        name={'curp'}
                                                        value={dataUnificacion.empleado_nuevo.curp}
                                                        type={'radio'}
                                                        onChange={this.handleChangeDataUnificacion}
                                                        labelClassName = {'col-sm-10'}
                                                        controlClassName = {'col-sm-2'}
                                                        disabled = {dataUnificacion.empleados_anteriores[0].prestamos.length > 0 || dataUnificacion.empleado_nuevo.curp === null }
                                                        error={[]}
                                                    />
                                                    <Radio
                                                        key={'curp_empleado_anterior'}
                                                        label={dataUnificacion.empleados_anteriores[0].curp}
                                                        name={'curp'}
                                                        value={dataUnificacion.empleados_anteriores[0].curp}
                                                        type={'radio'}
                                                        onChange={this.handleChangeDataUnificacion}
                                                        labelClassName = {'col-sm-10'}
                                                        controlClassName = {'col-sm-2'}
                                                        checked={dataEmpleadoUnificacion.curp === dataUnificacion.empleados_anteriores[0].curp}
                                                        disabled = {dataUnificacion.empleados_anteriores[0].prestamos.length > 0 || dataUnificacion.empleados_anteriores[0].curp === null }
                                                        error={[]}
                                                    />
                                                </div>
                                           </div>
                                           <div className="col-12 m-b-md">
                                                <h5>RFC: </h5>
                                                <div className="row">
                                                <Radio
                                                        key={'rfc_empleado_nuevo'}
                                                        label={dataUnificacion.empleado_nuevo.rfc}
                                                        name={'rfc'}
                                                        value={dataUnificacion.empleado_nuevo.rfc}
                                                        type={'radio'}
                                                        onChange={this.handleChangeDataUnificacion}
                                                        labelClassName = {'col-sm-10'}
                                                        controlClassName = {'col-sm-2'}
                                                        checked={dataEmpleadoUnificacion.rfc === dataUnificacion.empleado_nuevo.rfc}
                                                        disabled = {dataUnificacion.empleados_anteriores[0].prestamos.length > 0 || dataUnificacion.empleado_nuevo.rfc === null }
                                                        error={[]}
                                                    />
                                                    <Radio
                                                        key={'rfc_empleado_anterior'}
                                                        label={dataUnificacion.empleados_anteriores[0].rfc}
                                                        name={'rfc'}
                                                        value={dataUnificacion.empleados_anteriores[0].rfc}
                                                        type={'radio'}
                                                        onChange={this.handleChangeDataUnificacion}
                                                        labelClassName = {'col-sm-10'}
                                                        controlClassName = {'col-sm-2'}
                                                        checked={dataEmpleadoUnificacion.rfc === dataUnificacion.empleados_anteriores[0].rfc}
                                                        disabled = {dataUnificacion.empleados_anteriores[0].prestamos.length > 0 || dataUnificacion.empleados_anteriores[0].rfc === null }

                                                        error={[]}
                                                    />
                                                </div>
                                           </div>
                                           <div className="col-12 m-b-md">
                                                <h5>Número electoral: </h5>
                                                <div className="row">
                                                <Radio
                                                        key={'no_electoral_empleado_nuevo'}
                                                        label={dataUnificacion.empleado_nuevo.no_electoral}
                                                        name={'no_electoral'}
                                                        value={dataUnificacion.empleado_nuevo.no_electoral}
                                                        type={'radio'}
                                                        onChange={this.handleChangeDataUnificacion}
                                                        labelClassName = {'col-sm-10'}
                                                        controlClassName = {'col-sm-2'}
                                                        checked={dataEmpleadoUnificacion.no_electoral === dataUnificacion.empleado_nuevo.no_electoral}
                                                        disabled = {dataUnificacion.empleados_anteriores[0].prestamos.length > 0 || dataUnificacion.empleado_nuevo.no_electoral === null }
                                                        error={[]}
                                                    />
                                                    <Radio
                                                        key={'no_electoral_empleado_anterior'}
                                                        label={dataUnificacion.empleados_anteriores[0].no_electoral}
                                                        name={'no_electoral'}
                                                        value={dataUnificacion.empleados_anteriores[0].no_electoral}
                                                        type={'radio'}
                                                        onChange={this.handleChangeDataUnificacion}
                                                        labelClassName = {'col-sm-10'}
                                                        controlClassName = {'col-sm-2'}
                                                        checked={dataEmpleadoUnificacion.no_electoral === dataUnificacion.empleados_anteriores[0].no_electoral}
                                                        disabled = {dataUnificacion.empleados_anteriores[0].prestamos.length > 0 || dataUnificacion.empleados_anteriores[0].no_electoral === null }
                                                        error={[]}
                                                    />
                                                </div>
                                           </div>
                                        </div>
                                            <div className="row">
                                                <div className="col-6 m-b-md">
                                                    <h3>Solicitudes</h3>
                                                    {dataUnificacion.empleado_nuevo.solicitudes.map(solicitud => {
                                                        return( <div className="row">
                                                            <div className="col-4 m-b-md">{solicitud.folio}</div>
                                                            <div className="col-4 m-b-md">{solicitud.estatus}</div>    
                                                            </div>)
                                                       
                                                    })}
                                                </div>
                                                <div className="col-6 m-b-md">
                                                <h3>Solicitudes</h3>
                                                    {dataUnificacion.empleados_anteriores[0].solicitudes.map(solicitud => {
                                                        return( <div className="row">
                                                            <div className="col-4 m-b-md">{solicitud.folio}</div>
                                                            <div className="col-4 m-b-md">{solicitud.estatus}</div>    
                                                            </div>)
                                                       
                                                    })}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-white"
                                              onClick={() => this.setState({confUnificacion: false}) }
                                            >
                                                Cerrar
                                            </button>
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => this.unificarEmpleado() }
                                              disabled={unificandoEmpleado}>
                                            { unificandoEmpleado ? 'Unificando...' : 'Unificar' }

                                            </button>
                                        </div>
                                </div>
                                </Modal>
                                : null }
                                { objeto.estatus && objeto.estatus !== 'credito generado' && !disabledButtons && verificarPermiso('solicitudes.change_solicitudcredito,') &&
                                    <Link
                                        to={`/solicitudes/editar/${id}/`}
                                        className="btn btn-warning btn-sm btns-space right"
                                    >
                                        <i className="fa fa-pencil fa-margin" />Editar
                                    </Link>
                                }
                                { verificarPermiso('buro_credito.add_peticionburocredito,') &&
                                    <button
                                        type="button"
                                        className="btn btn-success btn-sm fa-margin right"
                                        onClick={ () => this.setState({confDatosBC: true}) }
                                        disabled={INEFrontalValido}
                                    >
                                        
                                        <i className="fa fa-info fa-margin"/>
                                        {process.env.REACT_APP_API_URL === "https://api.yuhu.mx" ?
                                            "Consulta Buró Crédito"
                                            :
                                            "Consulta Buró Crédito SB"
                                        }
                                    </button>
                                }
                                  
                                <Modal visible={ mostrarConfValidarCuentaClabe } width="30%" height="31%" effect="fadeInUp">
                                    <div className="modal-content animated bounceInRight">
                                        <div className="modal-header">
                                            <h4 className="modal-title">Verificación de cuenta CLABE</h4>
                                        </div>
                                        <div className="modal-body">
                                            <p>
                                                Se realizará una dispersión a la cuenta CLABE del cliente para realizar la prueba del peso. ¿Estás seguro de continuar?
                                            </p>
                                        </div>
                                        <div className="modal-footer">
                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                            <button type="button" className="btn btn-primary" onClick={() => this.validarInformacionBancaria()}>Aceptar</button>
                                        </div>
                                    </div>
                                </Modal>

                                <Modal visible={ confDatosBC } width="50%" height="55%" effect="fadeInUp">
                                    <div className="modal-content animated bounceInRight" style={{'height':'inherit'}}>
                                        <div className="modal-header">
                                            { process.env.REACT_APP_API_URL === "https://api.yuhu.mx" ?
                                              <h4 className="modal-title">Confirmar Datos</h4>
                                              :
                                              <h4 className="modal-title">Confirmar Datos SB - {process.env.REACT_APP_API_URL}</h4>
                                            }
                                        </div>
                                        <div className="modal-body" style={{'overflowY':'scroll'}}>
                                            <div className="row">
                                                {this.empleadoForm()}
                                                <div><b>Estos datos no se guardarán en el empleado, solo para consulta de BC *</b></div>
                                            </div>
                                            <div className="row">
                                                <div><b>Intentos: {intentos_bc}</b></div>
                                            </div>
                                            { error_consulta_bc !== '' &&
                                                <div className="row">
                                                    <div className="alertBC"><b>{error_consulta_bc}</b></div>
                                                </div>
                                            }
                                        </div>
                                        <div className="modal-footer">
                                            <button
                                              type="button"
                                              className="btn btn-white"
                                              onClick={() => this.setState({confDatosBC: false}) }
                                              disabled={loadingBC}
                                            >
                                                Cerrar
                                            </button>
                                            {/* <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={ process.env.REACT_APP_API_URL === "https://api.yuhu.mx" ?
                                                () => this.consulta_buro()
                                                :
                                                () => this.consulta_buro_sb()
                                              }
                                              disabled={loadingBC}
                                            >
                                                { loadingBC ? 'Consultando...' : 'Consultar' }
                                            </button> */}
                                            <button
                                              type="button"
                                              className="btn btn-primary"
                                              onClick={() => this.consulta_buro()}
                                              disabled={loadingBC}
                                            >
                                                { loadingBC ? 'Consultando...' : 'Consultar' }
                                            </button>
                                        </div>
                                    </div>
                                </Modal>
                            </div>
                        </div>
                        <br/>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="tabs-container">
                                    <ul className="nav nav-tabs" role="tablist">
                                    <li>
                                        <a className={`nav-link ${pestanaSeleccionada === 1 && 'active'}`} onClick={() => this.seleccionarPestana(1)} >Información de la solicitud</a>
                                    </li>
                                    <li>
                                        <a className={`nav-link ${pestanaSeleccionada === 2 && 'active'}`} onClick={() => this.seleccionarPestana(2)} >Información del empleado</a>
                                    </li>
                                    <li>
                                        <a className={`nav-link ${pestanaSeleccionada === 3 && 'active'}`} onClick={() => this.seleccionarPestana(3)} >Documentos</a>
                                    </li>
                                    <li>
                                        <a className={`nav-link ${pestanaSeleccionada === 4 && 'active'}`} onClick={() => this.seleccionarPestana(4)} >Contratos</a>
                                    </li>
                                    <li>
                                        <a className={`nav-link ${pestanaSeleccionada === 5 && 'active'}`} onClick={() => this.seleccionarPestana(5)} >Observaciones</a>
                                    </li>
                                    <li>
                                        <a className={`nav-link ${pestanaSeleccionada === 6 && 'active'}`} onClick={() => this.seleccionarPestana(6)} >Seguimiento</a>
                                    </li>
                                    <li>
                                        <a className={`nav-link ${pestanaSeleccionada === 7 && 'active'}`} onClick={() => this.seleccionarPestana(7)} >SPEI o Dispersión</a>
                                    </li>
                                    <li>
                                        <a className={`nav-link ${pestanaSeleccionada === 8 && 'active'}`} onClick={() => this.seleccionarPestana(8)} >Renovación</a>
                                    </li>
                                    <li>
                                        <a className={`nav-link ${pestanaSeleccionada === 9 && 'active'}`} onClick={() => this.seleccionarPestana(9)} >CDD</a>
                                    </li>
                                    <li>
                                        <a className={`nav-link ${pestanaSeleccionada === 10 && 'active'}`} onClick={() => this.seleccionarPestanaIA(10)} >IA Scoring</a>
                                    </li>
                                    <li>
                                        <a className={`nav-link ${pestanaSeleccionada === 11 && 'active'}`} onClick={() => this.seleccionarPestanaIA(11)} >INE</a>
                                    </li>
                                </ul>
                                    <div className="tab-content">
                                        <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 1 && 'active'}`}>
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Información de la solicitud de crédito
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Solicitante</label>
                                                                        <label>{ empleado.nombre } { empleado.apellido_paterno} { empleado.apellido_materno}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Fecha de solicitud</label>
                                                                        <label>{ dateTimeFormat(objeto.fecha_creacion) }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Estatus</label>
                                                                        <label>{ objeto.estatus}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Fecha solicitud completa</label>
                                                                        <label>{ objeto.fecha_completa ? dateTimeFormat(objeto.fecha_completa) : '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Promotor</label>
                                                                        <label>{ objeto.promotor || '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>No. de solicitud</label>
                                                                        <label>{ objeto.num_solicitud }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Folio</label>
                                                                        <label>{ objeto.folio }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Días de cobro</label>
                                                                        <label>{ objeto.dias_cobro || '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Canal</label>
                                                                        <label>{ objeto.canal || '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Salesforce ID</label>
                                                                        <label>{ objeto.sf_solicitud_id || '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Clase del crédito</label>
                                                                        <label>{ objeto.clase_credito_display || '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Analista</label>
                                                                        <label>
                                                                            {/* <SelectAnalista solicitud={objeto} analistas={this.state.analistas} /> */}
                                                                            {objeto.estatus !== 'credito generado' &&
                                                                                objeto.estatus !== 'rechazada' &&
                                                                                objeto.estatus !== 'cancelada' &&
                                                                                verificarPermiso('solicitudes.change_solicitudcredito,')
                                                                            ?   <select 
                                                                                    className="form-control"
                                                                                    onChange={(e) => this.onChangeAnalista(e)}
                                                                                    value={objeto.analista}
                                                                                >
                                                                                    <option value={"sin analista"}>Sin analista</option>  
                                                                                    { 
                                                                                        this.state.analistas.map(
                                                                                            analista => (
                                                                                                <option
                                                                                                    key={analista.id}
                                                                                                    value={analista.id}
                                                                                                    // selected={analista.id === objeto.analista}
                                                                                                >
                                                                                                    {`${analista.nombre} ${analista.apellido_paterno}`}
                                                                                                </option>
                                                                                            ) 
                                                                                        )
                                                                                    }
                                                                                </select>
                                                                            : objeto.analista
                                                                            }
                                                                        
                                                                        </label>
                                                                        {/* <label>{ objeto.agente || '--' }</label> */}
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>UUID</label>
                                                                        <label>{ objeto.uuid || '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Referencia</label>
                                                                        <label>{ objeto.referencia || '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Enlace solicitud</label>
                                                                        <label>
                                                                            { objeto.landing_link 
                                                                                ?
                                                                                    <a href={`${objeto.landing_link}`} target="_blank">{objeto.landing_link}</a>
                                                                                :
                                                                                    '--' 
                                                                            }
                                                                        </label>
                                                                    </div>
                                                                    {/* SEMAFORO */}
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Color</label>
                                                                        <SemaforoColor colorBuro={objeto.semaforo_buro} />
                                                                    </div>
                                                                    { renovacion.prestamo_anterior &&
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Renovación del crédito</label>
                                                                            <Link to={`/prestamos/ver/${renovacion.prestamo_anterior}`}>{renovacion.prestamo_anterior_str}</Link>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Información del crédito
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Monto solicitado</label>
                                                                        <label>{ currencyFormat(objeto.monto) }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Dinero nuevo</label>
                                                                        <label>{ currencyFormat(objeto.dinero_nuevo) }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Monto a descontar</label>
                                                                        <label>{ currencyFormat(objeto.monto_descontar) }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Número de periodos</label>
                                                                        <label>{ objeto.num_amortizaciones }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Periodo</label>
                                                                        <label>{ objeto.frecuencia_cobro }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Producto</label>
                                                                        <label>{ producto.nombre }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Costo</label>
                                                                        <label>{ objeto.costo }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Costo Anual</label>
                                                                        <label>{ objeto.costo_anual }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Tasa de interés</label>
                                                                        <label>{ objeto.tasa_interes } %</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Tipo de descuento</label>
                                                                        <label>{ objeto.tipo_descuento_str || '--' }</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Información bancaria
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Número de cuenta</label>
                                                                        <label>{ infoBancaria.cuenta || '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Cuenta CLABE</label>
                                                                        <label>{ infoBancaria.clabe || '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Banco</label>
                                                                        <label>{ infoBancaria.banco_str || '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Verificación</label>
                                                                        <label>{ infoBancariaEstatus || '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Fecha Verificación</label>
                                                                        <label>{ infoBancaria.fecha_verificacion || '--' }</label>
                                                                    </div>
                                                                    { infoBancariaEstatus == 'pendiente' &&
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                        <label></label>
                                                                        <label>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-sm btn-primary"
                                                                                onClick={()=> this.mostrarConfValidarCuentaClabe()}
                                                                            >
                                                                                Verificar cuenta CLABE
                                                                            </button>
                                                                        </label>
                                                                    </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    { objeto.yuhudils ? (
                                                        <div className="col-lg-12">
                                                            <div className="panel panel-default">
                                                                <div className="panel-heading">
                                                                    Orden Yuhudils
                                                                </div>
                                                                <div className="panel-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Producto</label>
                                                                            <label>{ objeto.orden_marketplace.producto }</label>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Estatus</label>
                                                                            <label>{ objeto.orden_marketplace.estatus }</label>
                                                                        </div> 
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Sku</label>
                                                                            <label>{ objeto.orden_marketplace.sku }</label>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Fecha de entrega</label>
                                                                            <label>{ objeto.orden_marketplace.fecha_entrega }</label>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Vendor</label>
                                                                            <label>{ objeto.orden_marketplace.vendor }</label>
                                                                        </div>  
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Dirección de envío</label>
                                                                            <label>{ objeto.orden_marketplace.direccion }</label>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>No. de orden</label>
                                                                            <label>{ objeto.orden_marketplace.numero_orden_vendor }</label>
                                                                        </div>
                                                                        { objeto.is_bimbo === true && 
                                                                            <>
                                                                                <div className="col-sm-12 col-md-6 sol">
                                                                                    <label>CURP Lista blanca</label>
                                                                                    <label>{ objeto.orden_marketplace.curp_lista_blanca  }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 col-md-6 sol">
                                                                                    <label>CURP INE</label>
                                                                                    <label>{ objeto.orden_marketplace.curp_ine }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 col-md-6 sol">
                                                                                    <label>Verificación lista blanca</label>
                                                                                    <label>
                                                                                        { objeto.orden_marketplace.verificacion_lista_blanca === true 
                                                                                            ? <i className="fa fa-check text-navy"></i>
                                                                                            : <i className="fa fa-close text-danger"></i>
                                                                                        }
                                                                                    </label>
                                                                                </div>
                                                                            
                                                                            </>
                                                                        }


                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Código de embajador</label>
                                                                            <label>{ objeto.orden_marketplace.codigo_referido }</label>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Estatus de código embajador</label>
                                                                            <label>{ objeto.orden_marketplace.codigo_referido_estatus }</label>
                                                                        </div>
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Fecha de verificación</label>
                                                                            <label className='text-capitalize'>{ objeto.orden_marketplace.codigo_referido_fecha_verificacion }</label>
                                                                        </div>
                                                                        
                                                                    </div>   
                                                                </div>   
                                                            </div>
                                                        </div>
                                                    ) : (null) }
                                        
                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Cotización de crédito
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        <TablaCotizacion cotizacion={cotizacion} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Cuestionario de compliance
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                    <CuestionarioCompliance solicitudId={objeto.id} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>



                                                    {/* <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Información crediticia
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Crédito automotriz</label>
                                                                        <label>{ informacionCrediticia.credito_automotriz }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Crédito hipotecario</label>
                                                                        <label>{ informacionCrediticia.credito_hipotecario }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Tarjeta de credito</label>
                                                                        <label>{ informacionCrediticia.tarjeta_credito }</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}

                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Historial de creditos del empleado
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        { historialPrestamos.length > 0 ?
                                                                            <Tabla
                                                                                objetos={ historialPrestamos }
                                                                                columnas={ historialPrestamosCols }
                                                                            />
                                                                            :
                                                                            <p>Aun no tiene créditos registrados</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 2 && 'active'}`}>
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Información personal
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Nombre del cliente</label>
                                                                        <label>{ empleado.nombre } { empleado.apellido_paterno} { empleado.apellido_materno }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Correo electrónico</label>
                                                                        <label>{ empleado.email }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Fecha de nacimiento</label>
                                                                        <label>{ empleado.fecha_nacimiento}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Género</label>
                                                                        <label>{ empleado.genero }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Nacionalidad</label>
                                                                        <label>{ empleado.nacionalidad}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Estado civil</label>
                                                                        <label>{ empleado.estado_civil }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>RFC</label>
                                                                        <div className="row">
                                                                            <label className="col-sm-12 right">{ empleado.rfc }</label>
                                                                            <small className={`col-sm-12 right ${valid_rfc.is_valid ? 'success-rfc-curp' : 'warning-rfc-curp'}`}>{ valid_rfc.msg }</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>CURP</label>
                                                                        <div className="row">
                                                                            <label className="col-sm-12 right">{ empleado.curp }</label>
                                                                            <small className={`col-sm-12 right ${valid_curp.is_valid ? 'success-rfc-curp' : 'warning-rfc-curp'}`}>{ valid_curp.msg }</small>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Celular</label>
                                                                        <label>{ empleado.telefono_celular}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Teléfono fijo</label>
                                                                        <label>{ empleado.telefono_casa}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Dirección</label>
                                                                        <label className="right">
                                                                            <div>Calle:  { domicilio.calle }</div>
                                                                            <div>Núm:  { domicilio.numero_exterior } { domicilio.numero_interior ? `-${domicilio.numero_interior} ` : '' }</div>
                                                                            <div>C.P.:  { domicilio.codigo_postal }</div>
                                                                            <div>Colonia:  { domicilio.colonia }</div>
                                                                            <div>Estado: { domicilio.estado_str }</div>
                                                                            <div>Municipio: { domicilio.municipio_str }</div>
                                                                        </label>
                                                                    </div>
                                                                  <div className="col-sm-12 col-md-6 sol">
                                                                    <label>Tipo de vivienda</label>
                                                                    <label className="text-capitalize">{ domicilio.tipo_vivienda || '--'}</label>
                                                                  </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Salesforce empleado ID</label>
                                                                        <label>{ empleado.sf_empleado_id}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Usuario caja ahorro</label>
                                                                        <label>{ empleado.usuario_caja_ahorro }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Score BC</label>
                                                                        { objeto.error_bc !== '--' && objeto.error_bc !== '' ?
                                                                            <small>{ objeto.error_bc }</small>
                                                                          :
                                                                            <label>{ objeto.score_bc }</label>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-12 text-right">
                                                                        { verificarPermiso('empleados.change_empleado') &&
                                                                            <Link to={`/empleados/editar/${empleado.id}`} target='_blank' className='btn btn-warning btn-sm'>Editar <i className="fa fa-edit"></i></Link>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Información laboral
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Empresa</label>
                                                                        <label>{ empleado.empresa_str }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Unidad oprativa</label>
                                                                        <label>{ objeto.unidad_operativa }</label>
                                                                    </div>
                                                                    {objeto.direccion_ma !== '--' ?
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Dirección</label>
                                                                            <label>{ objeto.direccion_ma }</label>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {objeto.empresa_ma !== '--' ?
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Empresa donde labora</label>
                                                                            <label>{ objeto.empresa_ma }</label>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>No. de empleado</label>
                                                                        <label>{ empleado.no_empleado }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Fecha de ingreso</label>
                                                                        <label>{ empleado.fecha_contratacion}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Puesto</label>
                                                                        <label>{ empleado.puesto }</label>
                                                                    </div>
                                                                    { empleado.empresa_whirlpool_str !== '--' && 
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <label>Empresa Whirlpool</label>
                                                                            <label>{ empleado.empresa_whirlpool_str }</label>
                                                                        </div>
                                                                    }
                                                                </div>
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Percepciones
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Sueldo declarado</label>
                                                                        <label>{ percepciones.sueldo_declarado ? currencyFormat(percepciones.sueldo_declarado) : '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Salario diario</label>
                                                                        <label>{ percepciones.salario_diario ? currencyFormat(percepciones.salario_diario) : '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Salario diario integrado</label>
                                                                        <label>{ percepciones.salario_diario_integrado ? currencyFormat(percepciones.salario_diario_integrado) : '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Sueldo mensual</label>
                                                                        <label>{ percepciones.sueldo_mensual ? currencyFormat(percepciones.sueldo_mensual) : '--' }</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Sueldo mensual neto</label>
                                                                        <label>{ percepciones.sueldo_mensual_real ? currencyFormat(percepciones.sueldo_mensual_real) : '--' }</label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 3 && 'active'}`}>
                                            <div className="panel-body">
                                                { comprobantes.length > 0 ?
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="panel panel-default">
                                                                <div className="panel-heading">
                                                                    Comprobantes
                                                                </div>
                                                                <div className="panel-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="ibox ">
                                                                                <div className="ibox-content">

                                                                                    <CartaBuro
                                                                                        visible={this.state.modalCartaBuro}
                                                                                        title="Generación de carta de autorización de buró"
                                                                                        solicitud={objeto}
                                                                                    >
                                                                                        <button
                                                                                          className="btn btn-white"
                                                                                          type="button"
                                                                                          onClick={this.showModalCartaBuro}
                                                                                        >
                                                                                            Cancelar
                                                                                        </button>
                                                                                    </CartaBuro>

                                                                                    { dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado' &&
                                                                                        <div className="row">
                                                                                            <div className="col-sm-12 m-b-xs">
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-sm btn-primary"
                                                                                                    onClick={() => this.mostrarFormularioComprobante()}
                                                                                                >
                                                                                                    <i className="fa fa-plus fa-margin"></i>
                                                                                                    Agregar
                                                                                                </button>

                                                                                                {objeto &&
                                                                                                    <button
                                                                                                        type="button"
                                                                                                        className="btn btn-primary btn-sm m-l-xs"
                                                                                                        onClick={this.showModalCartaBuro}
                                                                                                    >
                                                                                                        <i className="fas fa-file-contract"></i>
                                                                                                        Carta De Buró
                                                                                                    </button>
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                    }
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-striped">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Comprobante</th>
                                                                                                    <th>Archivo</th>
                                                                                                    <th>Válido</th>
                                                                                                    <th>Acciones</th>
                                                                                                    {objeto.is_bimbo === true &&
                                                                                                        <th>Estatus</th>
                                                                                                    }

                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                { comprobantes.map((obj, indice) => (
                                                                                                    <tr key={ obj.id }>
                                                                                                        <td>{ obj.tipo_documento_nombre }</td>
                                                                                                        <td>{ obj.documento_nombre || '--' }</td>
                                                                                                        <td>{ (obj.valido === true && <i className="fa fa-check fa-fw fa-margin"></i>) || ('--') }</td>
                                                                                                        <td>
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-sm btn-primary btns-space"
                                                                                                                onClick={() => this.mostrarFormularioComprobante(true, indice)}
                                                                                                            >
                                                                                                                <i className="fa fa-pencil fa-margin"></i>
                                                                                                                Editar
                                                                                                            </button>
                                                                                                            { obj.documento !== null &&
                                                                                                            <button
                                                                                                                type="button"
                                                                                                                className="btn btn-primary btn-sm btns-space"
                                                                                                                onClick={()=> window.open(obj.documento, "_blank")}
                                                                                                            >
                                                                                                                <i className="fa fa-eye fa-margin"></i>
                                                                                                                Ver
                                                                                                            </button>
                                                                                                            }
                                                                                                             {objeto.is_bimbo === true && obj.documento !== null && dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado' && obj.tipo_documento_nombre === 'Comprobante de domicilio 1' &&
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm btns-space"
                                                                                                                    onClick={()=> this.mostrarComprobanteInvalido(obj, indice)}
                                                                                                                >
                                                                                                                    Cambiar estatus
                                                                                                                </button>
                                                                                                            }
                                                                                                            { objeto.is_bimbo !== true && obj.documento !== null && dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado' &&
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm btns-space"
                                                                                                                    onClick={()=> this.mostrarConfElimDocComprobante(obj, indice)}
                                                                                                                >
                                                                                                                    <i className="fa fa-trash fa-margin"></i>
                                                                                                                    Eliminar documento
                                                                                                                </button>
                                                                                                            }
                                                                                                            { objeto.is_bimbo !== true && dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado' &&
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm btns-space"
                                                                                                                    onClick={()=> this.mostrarConfElimComprobante(obj, indice)}
                                                                                                                >
                                                                                                                    <i className="fa fa-trash fa-margin"></i>
                                                                                                                    Eliminar
                                                                                                                </button>
                                                                                                            }
                                                                                                            { objeto.is_bimbo !== true && obj.documento !== null && obj.valido === true && dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado' 
                                                                                                                &&(obj.tipo_documento_nombre === 'Pasaporte' || obj.tipo_documento_nombre === 'INE/IFE Frontal' || obj.tipo_documento_nombre === 'Carta de Autorización de Buró de Crédito' 
                                                                                                                || obj.tipo_documento_nombre === 'Comprobante de domicilio 1' || obj.tipo_documento_nombre === 'Estado de cuenta 1' || obj.tipo_documento_nombre === 'Recibo de Nómina 1' || obj.tipo_documento_nombre === 'Recibo de Nómina 2')&&

                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm btns-space"
                                                                                                                    onClick={()=> this.mostrarComprobanteInvalido(obj, indice)}
                                                                                                                >
                                                                                                                    <i className="fa fa-times fa-margin"></i>
                                                                                                                    Invalidar
                                                                                                                </button>
                                                                                                            }
                                                                                                            {obj.documento !== null && obj.valido === false && dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado'
                                                                                                                &&(obj.tipo_documento_nombre === 'Pasaporte' || obj.tipo_documento_nombre === 'INE/IFE Frontal' || obj.tipo_documento_nombre === 'Carta de Autorización de Buró de Crédito' 
                                                                                                                || obj.tipo_documento_nombre === 'Comprobante de domicilio 1' || obj.tipo_documento_nombre === 'Estado de cuenta 1' || obj.tipo_documento_nombre === 'Recibo de Nómina 1' || obj.tipo_documento_nombre === 'Recibo de Nómina 2')&&
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm btns-space"
                                                                                                                    onClick={()=> this.mostrarComprobanteValido(obj, indice)}
                                                                                                                >
                                                                                                                    <i className="fa fa-check fa-margin"></i>
                                                                                                                    Validar
                                                                                                                </button>
                                                                                                            }
                                                                                                            {obj.documento !== null && obj.valido === false && dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado'
                                                                                                                && obj.tipo_documento_nombre === 'Carta de Autorización de Buró de Crédito' &&
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm btns-space"
                                                                                                                    onClick={()=> this.mostrarConfirmacionReenvio(obj, indice)}
                                                                                                                >
                                                                                                                    <i className="fa fa-envelope-o fa-margin"></i>
                                                                                                                    Reenviar
                                                                                                                </button>
                                                                                                            }
                                                                                                        </td>
                                                                                                        {objeto.is_bimbo === true &&  obj.tipo_documento_nombre === 'Comprobante de domicilio 1' &&
                                                                                                        <td>{obj.estatus}</td>
                                                                                                    }
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                :
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="wrapper wrapper-content">
                                                                <div className="ibox-content m-b-sm">
                                                                    <div className="text-center p-lg">
                                                                        <h2>Aún no tienes documentos agregados</h2>
                                                                        <button
                                                                            className="btn btn-primary btn-sm"
                                                                            type="button"
                                                                            onClick={() => this.mostrarFormularioComprobante()}
                                                                        >
                                                                            <i className="fa fa-plus"></i>
                                                                            <span className="bold">Agregar</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <Modal visible={ mostrarFrmComp } width="50%" height="25%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            { editarComprobante ?
                                                                <h4 className="modal-title">Editar comprobante</h4>
                                                            :
                                                                <h4 className="modal-title">Agregar comprobante</h4>
                                                            }
                                                        </div>
                                                        <div className="modal-body">
                                                            {
                                                                editarComprobante &&
                                                                    <div className="row m-b-sm">
                                                                        <div className="col-sm-4">Tipo de documento</div>
                                                                        <div className="col-sm-8">{ this.state.dataComprobante.tipo_documento_nombre }</div>
                                                                    </div>
                                                            }
                                                            <Formulario
                                                                fields={ formComprobante.fields }
                                                                data={ formComprobante.data }
                                                                errors={ formComprobante.errors }
                                                            />

                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" disabled={this.state.comprobanteGuardarDisabled} onClick={() => this.guardarComprobante()}>{ this.state.comprobanteGuardarDisabled ? 'Guardando...' : 'Guardar' }</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Modal visible={ mostrarConfComp } width="50%" height="27.55%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Eliminar comprobante</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>
                                                                ¿Estás seguro de eliminar este comprobante?
                                                            </p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.eliminarComprobante()}>Aceptar</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Modal visible={ mostrarConfInvalidar } width="50%" height="27.55%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            { objeto.is_bimbo !== true  ? 
                                                            <h4 className="modal-title">Invalidar Documento</h4>:
                                                            <h4 className="modal-title">Cambio de estatus</h4> } 
                                                        </div>
                                                        <form onSubmit = {this.handleSubmit} >
                                                            <div className="modal-body">
                                                                { objeto.is_bimbo !== true  ? 
                                                                    <h4>Selecciona la razón por la que este documento se inválida:</h4>:
                                                                null}
                                                                { objeto.is_bimbo !== true ? 
                                                                    <select name="comentarios" className="form-control" onChange={(e) => this.setState({mensaje: e.target.value, estatus: 'invalido'})} required> 
                                                                        <option value="">Seleccionar</option>
                                                                        <option value="Información no legible">Información no legible</option>
                                                                        <option value="Antigüedad mayor a 2 meses">Antigüedad mayor a 2 meses</option>
                                                                        <option value="otro">Otro</option>
                                                                    </select>
                                                                :
                                                                    <select name="comentarios" className="form-control" onChange={(e) => this.setState({estatus: e.target.value})} required> 
                                                                        <option value="">Seleccionar</option>
                                                                        <option value="Alterado">Alterado</option>
                                                                        <option value="Ilegible">Ilegible</option>
                                                                        <option value="Ilegible-incompleto">Ilegible-incompleto</option>
                                                                        <option value="Incompleto">Incompleto</option>
                                                                        <option value="Invalido">Invalido</option>
                                                                        <option value="Vencido">Vencido</option>
                                                                        <option value="Vencido-Ilegible-Incompleto">Vencido-Ilegible-Incompleto</option>
                                                                        <option value="Vencido-incompleto">Vencido-incompleto</option>
                                                                        <option value="Estado de cuenta coteja vs INE">Estado de cuenta coteja vs INE</option>
                                                                    </select>

                                                                }
                                                                
                                                                {this.state.mensaje === "otro" ? 
                                                                    <div className='row mt-md-3'>
                                                                    <div className='col-sm-12 m-b-xs'>
                                                                    <textarea
                                                                        className="form-control"
                                                                        name="comentarios"
                                                                        rows="5"
                                                                        cols="30"
                                                                        placeholder='Escribe aquí otra razón para invalidar el documento'
                                                                        onChange={(e) => this.setState({comentario: e.target.value})}
                                                                        required>
                                                                    </textarea>
                                                                    </div>
                                                                    </div>
                                                                : null }
                                                                
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                                <button type="submit" className="btn btn-primary" >Aceptar</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </Modal>
                                                <Modal visible={ mostrarConfValidar } width="50%" height="27.55%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Validar Documento</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>¿Estás seguro de validar este documento? </p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.validarDocumento() }>Aceptar</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Modal visible={ mostrarConfReenvio } width="50%" height="27.55%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Reenviar carta de aceptación de buro</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>¿Estás seguro de reenviar la carta de aceptación de Buró? </p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.reenviarCarta() }>Aceptar</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Modal visible={ mostrarConfCompDoc } width="50%" height="27.55%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Eliminar documento del comprobante</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>
                                                                ¿Estás seguro de eliminar este documento?
                                                            </p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.eliminarDocumento()}>Aceptar</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </div>
                                        </div>
                                        <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 4 && 'active'}`}>
                                            <div className="panel-body">
                                                { documentos.length > 0 ?
                                                        <div className="row">
                                                            <div className="col-lg-12">
                                                            <div className="panel panel-default">
                                                                <div className="panel-heading">
                                                                Contratos
                                                                </div>
                                                                <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-lg-12">
                                                                        <div className="ibox ">
                                                                            <div className="ibox-content">
                                                                                { dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado' &&
                                                                                <div className="row">
                                                                                    <div className="col-sm-12 m-b-xs">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-sm btn-primary"
                                                                                        onClick={() => this.mostrarFormularioDocumento()}
                                                                                    >
                                                                                        <i className="fa fa-plus fa-margin"></i>
                                                                                        Agregar
                                                                                    </button>
                                                                                    </div>
                                                                                </div>
                                                                                }
                                                                                <GenerarContrato solicitud={ objeto } cargarDocumentos={ this.actualizarListadoDocumentos } >
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-striped">
                                                                                        <thead>
                                                                                        <tr>
                                                                                            <th>Nombre</th>
                                                                                            <th>Docs firmados</th>
                                                                                            <th>Acciones</th>
                                                                                            <th>Estatus</th>
                                                                                        </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                        { documentos.map((obj, indice) => (
                                                                                            <tr key={ obj.id }>
                                                                                            <td>{ obj.nombre }</td>
                                                                                            <td>
                                                                                                <table>
                                                                                                <tbody>
                                                                                                { obj.documentos_firmados.length > 0 ?
                                                                                                    <tr>
                                                                                                    <td className="celda-delgada">Fecha de creación</td>
                                                                                                    <td className="celda-delgada">Acciones</td>
                                                                                                    </tr>
                                                                                                    :
                                                                                                    <tr><td>Aún no hay documentos firmados</td></tr>
                                                                                                }
                                                                                                { obj.documentos_firmados.map((doc, indice2) => (
                                                                                                    <tr key={`doc${obj.id}${indice2}`} className="renglon-delgado">
                                                                                                    <td className="celda-delgada">{dateTimeFormat(doc.fecha_creacion)}</td>
                                                                                                    <td className="celda-delgada">
                                                                                                        <button
                                                                                                        type="button"
                                                                                                        className="btn btn-primary btn-xs btns-space"
                                                                                                        onClick={()=> window.open(doc.documento, "_blank")}
                                                                                                        >
                                                                                                        <i className="fa fa-eye fa-margin"></i>
                                                                                                        Ver
                                                                                                        </button>
                                                                                                        <button
                                                                                                        type="button"
                                                                                                        className="btn btn-xs btn-primary btns-space"
                                                                                                        onClick={() => this.mostrarConfElimDocContrato(obj.id, doc.id, indice2, indice)}
                                                                                                        >
                                                                                                        <i className="fa fa-trash fa-margin"></i>
                                                                                                        Eliminar
                                                                                                        </button>
                                                                                                        
                                                                                                    </td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                                </tbody>
                                                                                                </table>
                                                                                            </td>
                                                                                            <td>
                                                                                                <button
                                                                                                type="button"
                                                                                                    className="btn btn-sm btn-primary btns-space"
                                                                                                    onClick={() => this.mostrarFormDocFirmado(obj.id, indice)}
                                                                                                >
                                                                                                    <i className="fa fa-plus fa-margin"></i>
                                                                                                    Agregar
                                                                                                </button>
                                                                                                { obj.documento !== null &&
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-primary btn-sm btns-space"
                                                                                                    onClick={()=> window.open(obj.documento, "_blank")}
                                                                                                >
                                                                                                    <i className="fa fa-eye fa-margin"></i>
                                                                                                    Ver
                                                                                                    </button>
                                                                                                }
                                                                                                { dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado' &&
                                                                                                <button
                                                                                                    type="button"
                                                                                                    className="btn btn-primary btn-sm btns-space"
                                                                                                    onClick={()=> this.mostrarConfElimContrato(obj, indice)}
                                                                                                >
                                                                                                <i className="fa fa-trash fa-margin"></i>
                                                                                                    Eliminar
                                                                                                </button>
                                                                                                }
                                                                                                { verificarPermiso('solicitudes.enviar_documento,') && obj.nombre.toLowerCase() === 'contrato'?     
                                                                                                    <EnviarDocumento
                                                                                                        solicitud={objeto}
                                                                                                        documento={obj}
                                                                                                        actualizarSolicitud={this.actualizarDatosSolicitud}
                                                                                                    />
                                                                                                    :
                                                                                                    null                                                                               
                                                                                                }

                                                                                            </td>
                                                                                            <td>
                                                                                                <span className="text-muted">
                                                                                                    {obj.estatus_absign_str}
                                                                                                </span>
                                                                                            </td>

                                                                                            </tr>
                                                                                        ))}
                                                                                        </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </GenerarContrato>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </div>  
                                                :
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="wrapper wrapper-content">
                                                                <div className="ibox-content m-b-sm">
                                                                    <div className="text-center p-lg">
                                                                        <h2>Aún no tienes contratos agregados</h2>
                                                                        <button
                                                                            className="btn btn-primary btn-sm m-r"
                                                                            type="button"
                                                                            onClick={() => this.mostrarFormularioDocumento()}
                                                                        >
                                                                            <i className="fa fa-plus"></i>
                                                                            <span className="bold">Agregar</span>
                                                                        </button>
                                                                        <GenerarContrato solicitud={ objeto } cargarDocumentos={ this.actualizarListadoDocumentos } />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <Modal visible={ mostrarFrmDoc } width="50%" height="45%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Agregar documento</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                        <Formulario
                                                            fields={ formDocumento.fields }
                                                            data={ formDocumento.data }
                                                            errors={ formDocumento.errors }
                                                        />
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" disabled={this.state.documentoGuardarDisabled} onClick={() => this.agregarDocumento()}>{ this.state.documentoGuardarDisabled ? 'Guardando...' : 'Guardar'}</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Modal visible={ mostrarConfElimContrato } width="50%" height="31%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Eliminar contrato</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>
                                                                ¿Estás seguro de eliminar este contrato?
                                                            </p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.eliminarContrato()}>Aceptar</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Modal visible={ mostrarFrmDocFirm } width="50%" height="35%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Agregar documento firmado</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                        <Formulario
                                                            fields={ formDocFirmado.fields }
                                                            data={ formDocFirmado.data }
                                                            errors={ formDocFirmado.errors }
                                                        />
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" disabled={this.state.documentoFirmadoGuardarDisabled} onClick={() => this.agregarDocFirmado()}>{ this.state.comprobanteFirmadoGuardarDisabled ? 'Guardando...' : 'Guardar' }</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Modal visible={ mostrarConfElimDocContrato } width="50%" height="31%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Eliminar contrato</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>
                                                                ¿Estás seguro de eliminar este documento firmado?
                                                            </p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.eliminarDocFirmado()}>Aceptar</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </div>
                                        </div>
                                        <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 5 && 'active'}`}>
                                            <div className="panel-body">
                                                { mensajes.length > 0 &&
                                                    <div className="row">
                                                        <div className="col-sm-12 m-b-xs">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-primary"
                                                                onClick={() => this.mostrarFormComentario()}
                                                            >
                                                                <i className="fa fa-plus fa-margin"></i>
                                                                Agregar
                                                            </button>
                                                        </div>
                                                    </div>
                                                }
                                                { mensajes.length > 0 ?
                                                    <div className="table-responsive">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th>Fecha y hora</th>
                                                                    <th>Usuario</th>
                                                                    <th>Comentario</th>
                                                                    <th className="centrado">Correo a empleado</th>
                                                                    <th>Acciones</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                { mensajes.map((obj, indice) => (
                                                                    <tr key={ obj.id }>
                                                                        <td>{ dateTimeFormat(obj.fecha_creacion) }</td>
                                                                        <td>{ obj.usuario }</td>
                                                                        <td>{ obj.comentario }</td>
                                                                        <td className="centrado">
                                                                            { obj.visible_solicitante === true ?
                                                                                <i className="fa fa-check verde"></i>
                                                                            :
                                                                            <i className="fa fa-close rojo"></i>
                                                                            }
                                                                        </td>
                                                                        <td>
                                                                            <button
                                                                                type="button"
                                                                                className="btn btn-primary btn-sm btns-space"
                                                                                onClick={()=> this.mostrarConfElimComentario(obj.id, indice)}
                                                                            >
                                                                                <i className="fa fa-trash fa-margin"></i>
                                                                                Eliminar
                                                                            </button>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                :
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="wrapper wrapper-content">
                                                                <div className="ibox-content m-b-sm">
                                                                    <div className="text-center p-lg">
                                                                        <h2>Aún no tienes comentarios agregados</h2>
                                                                        <button
                                                                            className="btn btn-primary btn-sm"
                                                                            type="button"
                                                                            onClick={() => this.mostrarFormComentario()}
                                                                        >
                                                                            <i className="fa fa-plus"></i>
                                                                            <span className="bold">Agregar</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <Modal visible={ mostrarFrmComentario } width="50%" height="35%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Agregar comentario</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                            <Formulario
                                                                fields={ frmMensaje.fields }
                                                                data={ frmMensaje.data }
                                                                errors={ frmMensaje.errors }
                                                            />
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            {this.state.loadingComentario ?
                                                                <button type="button" className="btn btn-primary" disabled>Guardando...</button>
                                                                :
                                                                <button type="button" className="btn btn-primary" onClick={() => this.agregarComentario()}>Guardar</button>
                                                            }
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Modal visible={ mostrarConfElimComentario } width="50%" height="31%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Eliminar comentario</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>
                                                                ¿Estás seguro de eliminar este comentario?
                                                            </p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.eliminarMensaje()}>Aceptar</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </div>
                                        </div>
                                        <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 6 && 'active'}`}>
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-sm-12 m-b-xs">

                                                        { dataEstatus.estatus === 'rechazada' || dataEstatus.estatus === 'cancelada' ?
                                                            <button
                                                            type="button"
                                                            className="btn btn-sm btn-danger right"
                                                            onClick={() => this.setState({modalReactivarSolicitud: true})}
                                                            // disabled={disabledButtons}
                                                            >
                                                                Reactivar Solicitud
                                                            </button> :
                                                            null
                                                        }
                                                    </div>
                                                    { dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado' && dataEstatus.estatus !== 'cancelada'  &&
                                                        <div className="col-sm-12 m-b-xs">

                                                            { verificarPermiso('solicitudes.rechazar_solicitudcredito,') &&
                                                                <button
                                                                  type="button"
                                                                  className="btn btn-sm btn-danger right"
                                                                  onClick={() => this.mostrarFormularioDeCancelacion()}
                                                                  disabled={disabledButtons}
                                                                >
                                                                    Cancelar Solicitud
                                                                </button>
                                                            }
                                                            { verificarPermiso('solicitudes.rechazar_solicitudcredito,') &&
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-sm btn-danger right btns-space"
                                                                    onClick={() => this.mostrarFormularioDeRechazo()}
                                                                    disabled={disabledButtons}
                                                                >
                                                                    Rechazar Solicitud
                                                                </button>
                                                            }
                                                            { verificarPermiso('prestamos.add_prestamo,') && !objeto.msi &&
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btn-sm right btns-space"
                                                                    onClick={ () => this.mostrarGenerar() }
                                                                    disabled={disabledButtons}
                                                                >
                                                                    Generar préstamo
                                                                </button>
                                                            }
                                                            { verificarPermiso('prestamos.add_prestamo,') && objeto.msi &&
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary btn-sm right btns-space"
                                                                    onClick={ () => this.setState({modalMsi:true}) }
                                                                    disabled={disabledButtons}
                                                                >
                                                                    Generar préstamo MSI
                                                                </button>
                                                            }
                                                        </div>
                                                    }
                                                    <div className="col-lg-5">
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="panel panel-default">
                                                                    <div className="panel-heading">
                                                                        Estatus
                                                                    </div>
                                                                    <div className="panel-body">
                                                                        {/*  Alerta de crédito con adeudo  */}
                                                                        { dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado' && dataEstatus.estatus !== 'cancelada' && empleado.credito_con_adeudo !== '--' && this.state.objeto.id &&
                                                                          <div className="row">
                                                                              <div className="col-12">
                                                                                <p style={{padding: '10px'}} className="bg-danger">
                                                                                    <i className="fa fa-warning"></i>  El cliente cuenta con un crédito con adeudo. <b>{empleado.credito_con_adeudo}</b>
                                                                                </p>
                                                                              </div>
                                                                          </div>
                                                                        }

                                                                        { dataEstatus.estatus !== 'rechazada' && dataEstatus.estatus !== 'credito generado' && dataEstatus.estatus !== 'cancelada'?
                                                                            <Formulario
                                                                                buttonLabel={ formEstatus.button.label }
                                                                                onSubmit={ formEstatus.button.onSubmit }
                                                                                fields={ formEstatus.fields }
                                                                                data={ formEstatus.data }
                                                                                errors={ formEstatus.errors }
                                                                            />
                                                                        :
                                                                            <div className="row">
                                                                                <div className="col-sm-12  sol">
                                                                                    <label>Estatus</label>
                                                                                    <label className="text-capitalize">{ dataEstatus.estatus }</label>
                                                                                </div>
                                                                                {/*<div className="col-sm-12 col-md-6 sol">*/}
                                                                                {/*    <label>Análisis de crédito</label>*/}
                                                                                {/*    <label>{ dataEstatus.analisis_credito }</label>*/}
                                                                                {/*</div>*/}
                                                                                {/*<div className="col-sm-12 col-md-6 sol">*/}
                                                                                {/*    <label>Revisión de documentos</label>*/}
                                                                                {/*    <label>{ dataEstatus.revision_documentos }</label>*/}
                                                                                {/*</div>*/}
                                                                                { objeto.motivo_rechazo && objeto.motivo_rechazo !== '--' &&
                                                                                    <div className="col-sm-12 sol">
                                                                                        <label>Motivo de rechazo</label>
                                                                                        <label className="">{ objeto.motivo_rechazo }</label>
                                                                                    </div>
                                                                                }
                                                                                { objeto.motivo_cancelacion && objeto.motivo_cancelacion !== '--' &&
                                                                                    <div className="col-sm-12 sol">
                                                                                        <label>Motivo de cancelación</label>
                                                                                        <label className="">{ objeto.motivo_cancelacion }</label>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="panel panel-default">
                                                                    <div className="panel-heading">
                                                                        Validación de Rescate
                                                                    </div>
                                                                    <div className="panel-body">
                                                                        { this.state.validacionesRescate.validaciones.length > 0 ?
                                                                          <div className="feed-activity-list">
                                                                              { this.state.validacionesRescate.validaciones.map(validacion => (
                                                                                <div className="feed-element" key={validacion.id}>
                                                                                    <div className="media-body">
                                                                                        <div className="row">
                                                                                            <div className="col-4">
                                                                                                Motivo de negativa:
                                                                                            </div>
                                                                                            <div className="col-8 text-uppercase">
                                                                                                <strong>{validacion.motivo_negativa}</strong>
                                                                                            </div>

                                                                                            <div className="col-4">
                                                                                                Comentario de negativa:
                                                                                            </div>
                                                                                            <div className="col-8">
                                                                                                {validacion.comentario_negativa}
                                                                                            </div>

                                                                                            <div className="col-4">
                                                                                                Analista:
                                                                                            </div>
                                                                                            <div className="col-8">
                                                                                                {validacion.usuario_creo}
                                                                                            </div>

                                                                                            <div className="col-4">
                                                                                                Estatus:
                                                                                            </div>
                                                                                            <div className="col-8">
                                                                                                <label className={validacion.estatus === 'Pendiente' ? 'badge-info badge text-capitalize' : validacion.estatus == 'Soportada' ? 'badge-primary badge text-capitalize' : 'badge-warning badge text-capitalize' }>{validacion.estatus}</label>
                                                                                            </div>

                                                                                            <div className="col-4">
                                                                                                Comentario soporte:
                                                                                            </div>
                                                                                            <div className="col-8">
                                                                                                {validacion.comentario_soporte}
                                                                                            </div>

                                                                                            <div>
                                                                                                <small className="text-muted">{ dateTimeFormat(validacion.fecha_creacion) }</small>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                              )) }
                                                                          </div>
                                                                          :
                                                                          <div className="text-center">La Solicitud no cuenta con validaciones de rescate</div>
                                                                        }

                                                                        { this.state.objeto.estatus === 'rechazada' || this.state.objeto.estatus === 'credito generado' || this.state.objeto.estatus === 'cancelada'
                                                                            ? null
                                                                            :   <div className="row text-center">
                                                                                    {/* Permisos necesarios para pasar a validación de rescate */}
                                                                                    <div className="col-12 text-center">
                                                                                        <button className="btn btn-primary btn-lg m-t-md" disabled={this.state.validacionesRescate.validaciones.length > 0 && this.state.validacionesRescate.validaciones[this.state.validacionesRescate.validaciones.length-1].estatus === 'Pendiente' } onClick={()=> this.showValidarRescate()}>
                                                                                            Pasar a rescate
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                        }

                                                                        <Modal visible={ this.state.validacionesRescate.showValidarRescate } width="30%" height="27.55%" effect="fadeInUp">
                                                                            <div className="modal-content animated bounceInRight">
                                                                                <div className="modal-header">
                                                                                    <h4 className="modal-title">Pasar a validación de rescate</h4>
                                                                                </div>
                                                                                <form>
                                                                                    <div className="modal-body">
                                                                                        <h4>Selecciona el motivo de negativa:</h4>
                                                                                        <select name="motivo_negativa" className="form-control" onChange={(e) => this.handleChangeValidacionRescate(e)} required>
                                                                                            <option value="">Selecciona un motivo de negativa</option>
                                                                                            <option value="inconsistencia de datos">Inconsistencia de Datos</option>
                                                                                            <option value="arraigo laboral">Arraigo Laboral</option>
                                                                                            <option value="mal historial crediticio">Mal historial Créditicio</option>
                                                                                            <option value="capacidad de pago">Capacidad de Pago</option>
                                                                                            <option value="no comprueba ingresos via nomina">No comprueba ingresos vía Nómina</option>
                                                                                            <option value="extranjero">Extranjero</option>
                                                                                            <option value="actividad de alto riesgo">Actividad de Alto Riesgo</option>
                                                                                            <option value="giro no permitido">Giro no Permitido</option>
                                                                                            <option value="ine falsa">INE FALSA</option>
                                                                                            <option value="ine borrosa">INE Borrosa</option>
                                                                                            <option value="sobre pasa politica de edad">Sobre pasa política de edad</option>
                                                                                            <option value="edad menor a la permitida">Edad menor a la permitida</option>
                                                                                            <option value="cliente en mora <=60 dd">{"Cliente en Mora <= 60 dd"}</option>
                                                                                            <option value="cliente en mora >60 dd">Cliente en Mora >60 dd</option>
                                                                                            <option value="cliente no cumple con porcentaje requerido para renovar">Cliente no cumple con porcentaje requerido para renovar</option>
                                                                                            <option value="tentativa de fraude">Tentativa de Fraude</option>
                                                                                        </select>

                                                                                          <div className='row mt-md-3'>
                                                                                              <div className='col-sm-12 m-b-xs'>
                                                                                                <textarea
                                                                                                  className="form-control"
                                                                                                  name="comentario_negativa"
                                                                                                  rows="5"
                                                                                                  cols="30"
                                                                                                  placeholder='Escribe un comentario de la negativa'
                                                                                                  onChange={(e) => this.handleChangeValidacionRescate(e)}
                                                                                                  required>
                                                                                                </textarea>
                                                                                              </div>
                                                                                          </div>

                                                                                    </div>
                                                                                    <div className="modal-footer">
                                                                                        <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                                                        <button type="button" className="btn btn-primary" onClick={() => this.handleSubmitValidacionRescate()} >Aceptar</button>
                                                                                    </div>
                                                                                </form>
                                                                            </div>
                                                                        </Modal>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="panel panel-default">
                                                                    <div className="panel-heading">
                                                                        Notas de crédito
                                                                    </div>
                                                                    <div className="panel-body">
                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">No se pudo validar antigüedad laboral</div>
                                                                                    <div className="col-md-4">
                                                                                        <input type="checkbox" name="no_valido_antiguedad" onChange={this.handleChangeNotasCredito} checked={this.state.notasDeCredito.no_valido_antiguedad} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 m-t-md">
                                                                                <div className="row">
                                                                                    <div className="col-md-8">Incluye compra de deuda</div>
                                                                                    <div className="col-md-4">
                                                                                        <input type="checkbox" name="incluye_compra_deuda" onChange={this.handleChangeNotasCredito} checked={this.state.notasDeCredito.incluye_compra_deuda} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-12 m-t-lg">
                                                                                { this.state.objeto.estatus === 'rechazada' || this.state.objeto.estatus === 'credito generado' || this.state.objeto.estatus === 'cancelada'
                                                                                    ? null
                                                                                    : <button className="btn btn-primary btn-sm" onClick={this.handleSubmitNotasDeCredito}>Guardar</button>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <hr/>

                                                                        <div className="row">
                                                                            <div className="col-12">
                                                                                <div className="row">
                                                                                    <div className="col-12">
                                                                                        <h4>Excepción de Riesgo</h4>
                                                                                    </div>
                                                                                    <div className="col-12">
                                                                                        <ExcepcionRiesgo solicitud={this.state.objeto} notasCredito={this.state.notasDeCredito} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="panel panel-default">
                                                                    <div className="panel-heading">
                                                                        Autorización de consulta de Buró de Crédito
                                                                    </div>
                                                                    <div className="panel-body">                             
                                                                        <div className="row">
                                                                            <div className="col-sm-12  sol">
                                                                                <label>NIP</label>
                                                                                <label className="text-capitalize">{ dataEstatus.nip }</label>
                                                                            </div>
                                                                            <div className="col-sm-12  sol">
                                                                                <label>Autorizado</label>
                                                                                <label className="text-capitalize">{ dataEstatus.autorizacion_valida ? 'Si' : 'No' }</label>
                                                                            </div>
                                                                            <div className="col-sm-12  sol">
                                                                                <label>Tipo de autorización</label>
                                                                                <label className="text-capitalize">{ dataEstatus.tipo_autorizacion }</label>
                                                                            </div>
                                                                            <div className="col-sm-12  sol">
                                                                                <label>Última consulta</label>
                                                                                <label className="text-capitalize">{ timeFormat(dataEstatus.ultima_consulta)  }</label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        
                                                        <div className="row">
                                                            <div className="col-12">
                                                                <div className="panel panel-default">
                                                                    <div className="panel-heading">Clasificación del crédito</div>
                                                                    <div className="panel-body">

                                                                        <div className="form-group row">
                                                                            <label className="col-sm-4 col-form-label">Clasificación</label>
                                                                            <div className="col-sm-8">
                                                                                <select disabled={true} name="clasificacion" className="form-control" value={this.state.clasificacion.clasificacion || ''} onChange={this.handleChangeClasificacion}>
                                                                                    <option value="">Seleccionar</option>
                                                                                    <option value="comerciales simples">Comerciales Simples</option>
                                                                                    <option value="factoraje">Factoraje</option>
                                                                                    <option value="arrendamiento">Arrendamiento</option>
                                                                                    <option value="consumo">Consumo</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group row">
                                                                            <label className="col-sm-4 col-form-label">Subclasificación</label>
                                                                            <div className="col-sm-8">
                                                                                <select disabled={true} name="subclasificacion" className="form-control" value={this.state.clasificacion.subclasificacion || ''} onChange={this.handleChangeClasificacion}>
                                                                                    <option value="">Seleccionar</option>

                                                                                    <option value="microcreditos" disabled={this.state.clasificacion.clasificacion !== 'comerciales simples'}>Microcréditos</option>
                                                                                    <option value="revolvente" disabled={this.state.clasificacion.clasificacion !== 'comerciales simples'}>Cuenta corriente (revolvente)</option>
                                                                                    <option value="pha" disabled={this.state.clasificacion.clasificacion !== 'comerciales simples'}>PHA</option>
                                                                                    <option value="refaccionarios" disabled={this.state.clasificacion.clasificacion !== 'comerciales simples'}>Refaccionarios</option>

                                                                                    <option value="factoraje" disabled={this.state.clasificacion.clasificacion !== 'factoraje'}>Factoraje</option>

                                                                                    <option value="arrendamiento" disabled={this.state.clasificacion.clasificacion !== 'arrendamiento'}>Arrendamiento</option>

                                                                                    <option value="nomina" disabled={this.state.clasificacion.clasificacion !== 'consumo'}>Nómina (Simples)</option>
                                                                                    <option value="adelanto 911" disabled={this.state.clasificacion.clasificacion !== 'consumo'}>Adelanto 911</option>
                                                                                    <option value="personales" disabled={this.state.clasificacion.clasificacion !== 'consumo'}>Personales</option>
                                                                                    <option value="abcd" disabled={this.state.clasificacion.clasificacion !== 'consumo'}>ABCD (Adquisición de Bienes Duradero)</option>
                                                                                    <option value="tarjeta credito" disabled={this.state.clasificacion.clasificacion !== 'consumo'}>Tarjeta de crédito</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group row">
                                                                            <label className="col-sm-4 col-form-label">Tipo de garantía</label>
                                                                            <div className="col-sm-8">
                                                                                <select name="tipo_garantia" className="form-control" value={this.state.clasificacion.tipo_garantia || ''} onChange={this.handleChangeClasificacion}>
                                                                                    <option value="">Seleccionar</option>
                                                                                    <option value="real">Real</option>
                                                                                    <option value="natural">Natural</option>
                                                                                    <option value="personal">Personal</option>
                                                                                </select>
                                                                            </div>
                                                                        </div>

                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <button className="btn btn-primary btn-sm" disabled={dataEstatus.estatus === 'rechazada' || dataEstatus.estatus === 'credito generado'} onClick={this.handleSubmitClasificacion}>Guardar</button>
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-7">
                                                        <div className="ibox ">
                                                            <div className="ibox-content inspinia-timeline">
                                                                { seguimiento.map(obj => (
                                                                    <div className="timeline-item" key={ obj.id }>
                                                                        <div className="row">
                                                                            <div className="col-3 date">
                                                                                <i className="fa fa-file-text"></i>
                                                                                { timeFormat(obj.fecha_creacion, true) }
                                                                                <br/>
                                                                                <small className="text-navy">{ dateFormat(obj.fecha_creacion) }</small>
                                                                            </div>
                                                                            <div className="col-9 content">
                                                                                <p className="m-b-xs"><strong>{ obj.usuario }</strong></p>
                                                                                <p>{ obj.mensaje}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Modal visible={ this.state.modalReactivarSolicitud } width="20%" height="20%" effect="fadeInUp" className="modal-sm">
                                                        <div className="modal-content animated bounceInRight">
                                                            <div className="modal-header">
                                                                <h4 className="modal-title">Reactivar solicitud</h4>
                                                            </div>
                                                            <div className="modal-body text-center">
                                                                ¿Estás seguro que deseas reactivar esta solicitud? <br />
                                                                La solicitud pasara a estatus <b>"stand by"</b>.
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-white"
                                                                    onClick={() => this.setState({modalReactivarSolicitud: false})}
                                                                >
                                                                    Cerrar
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary"
                                                                    onClick={() => this.reactivarSolicitud(objeto)}
                                                                >
                                                                    Confirmar
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </Modal>
                                                    <Modal visible={ mostrarFrmRechazo } width="50%" height="45%" effect="fadeInUp">
                                                        <div className="modal-content animated bounceInRight">
                                                            <div className="modal-header">
                                                                <h4 className="modal-title">Rechazar solicitud</h4>
                                                            </div>
                                                            <div className="modal-body">
                                                                <Formulario
                                                                    fields={ formRechazarSol.fields }
                                                                    data={ formRechazarSol.data }
                                                                    errors={ formRechazarSol.errors }
                                                                />
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                                <button type="button" className="btn btn-primary" onClick={() => this.rechazar()}>Rechazar</button>
                                                            </div>
                                                        </div>
                                                    </Modal>
                                                    <Modal visible={ this.state.mostrarFrmCancelacion } width="50%" height="45%" effect="fadeInUp">
                                                        <div className="modal-content animated bounceInRight">
                                                            <div className="modal-header">
                                                                <h4 className="modal-title">Cancelar solicitud</h4>
                                                            </div>
                                                            <div className="modal-body">
                                                                <Formulario
                                                                  fields={ formCancelarSol.fields }
                                                                  data={ formCancelarSol.data }
                                                                  errors={ formCancelarSol.errors }
                                                                />
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                                <button type="button" className="btn btn-primary" onClick={() => this.cancelar()}>Cancelar solicitud</button>
                                                            </div>
                                                        </div>
                                                    </Modal>
                            
                                                    <Modal visible={this.state.modalMsi} width="50%" height="31%" effect="fadeInUp">
                                                       <div className="modal-content animated bounceInRight">
                                                            <div className="modal-header">
                                                                <h4 className="modal-title">Generar prestamo a meses sin intereses</h4>
                                                            </div>
                                                            <div className="modal-body">
                                                                <div className="row">
                                                                    <DatePicker
                                                                        value={dataPrestamo.fecha_primera_amortizacion}
                                                                        label="Primera amortización"
                                                                        error={errorsPrestamo.fecha_primera_amortizacion ? errorsPrestamo.fecha_primera_amortizacion : []}
                                                                        onChange={(e) => this.onChange('fecha_primera_amortizacion', e)}
                                                                        options={{"dateFormat" : "Y-m-d"}}
                                                                    />
                                                                    <DatePicker
                                                                        value={dataPrestamo.fecha_dispersion}
                                                                        label="Dispersión"
                                                                        error={errorsPrestamo.fecha_dispersion ?  errorsPrestamo.fecha_dispersion : []}
                                                                        onChange={(e) => this.onChange('fecha_dispersion', e)}
                                                                        options={{"dateFormat" : "Y-m-d"}}
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-white" onClick={() => this.setState({modalMsi:false}) }>Cerrar</button>
                                                                <button type="button" className="btn btn-primary" onClick={() => this.generarPrestamo()}>Aceptar</button>
                                                            </div>
                                                        </div>
                                                    </Modal>
                                                    <Modal visible={ mostrarModal } width="50%" height="25%" effect="fadeInUp">
                                                        <div className="modal-content animated bounceInRight">
                                                            <div className="modal-header">
                                                                <h4 className="modal-title">Generar prestamo</h4>
                                                            </div>
                                                            {!dispersionCompleto ? <>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-12">El total dispersado no es igual al monto de la solicitud.</div>
                                                                        <div className="col-sm-12">Total Dispersado: {currencyFormat(totalDispersion)}</div>
                                                                        <div className="col-sm-12">Monto Solicitado: {currencyFormat(objeto.monto)}</div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                                </div>
                                                            </> : renovacion.facturas_pendientes && renovacion.prestamo_anterior_tipo_descuento == "domiciliacion" ? <>
                                                                <div className="modal-body">
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            No es posible Generar porque el Préstamo a renovar cuenta ya con facturas pendientes.
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-white"
                                                                            onClick={() => this.cerrarModal()}>Cerrar
                                                                    </button>
                                                                </div>
                                                            </> : <>
                                                                <div className="modal-body">
                                                                    <div className="col-sm-12">¿Esta segur@ de Generar el Préstamo?</div>
                                                                    <DatePicker
                                                                        className="col-sm-12"
                                                                        value={dataPrestamo.fecha_primera_amortizacion}
                                                                        label="Primera amortización"
                                                                        error={errorsPrestamo.fecha_primera_amortizacion}
                                                                        onChange={(e) => this.onChange('fecha_primera_amortizacion', e)}
                                                                        options={{dateFormat: 'Y-m-d', locale: Spanish, minDate: moment().add(3, 'd').format('YYYY-MM-DD')}}
                                                                        disabled={disabledSend}
                                                                    />
                                                                </div>
                                                                <div className="modal-footer">
                                                                    <button type="button" className="btn btn-white" disabled={disabledSend} onClick={() => this.cerrarModal() }>Cerrar</button>
                                                                    <button type="button" className="btn btn-primary" disabled={disabledSend} onClick={() => this.generarOrdenVenta()}>Aceptar</button>
                                                                </div>
                                                            </>}
                                                        </div>
                                                    </Modal>
                                                </div>
                                            </div>
                                        </div>
                                        <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 7 && 'active'}`}>
                                            <div className="panel-body">
                                                { ordenesDispersion.length > 0 && !formOrdenDisp ?
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="panel panel-default">
                                                                <div className="panel-heading">
                                                                    Dispersión
                                                                </div>
                                                                <div className="panel-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="ibox ">
                                                                                <div className="ibox-content">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-10 m-b-xs">
                                                                                            {totalDispersion<parseInt(objeto.monto) &&
                                                                                                <button
                                                                                                  type="button"
                                                                                                  className="btn btn-sm btn-primary"
                                                                                                  onClick={() => this.setState({
                                                                                                      formOrdenDisp:true,
                                                                                                      mostrarFrmOrdenDispEdit: false
                                                                                                  })}
                                                                                                  disabled={objeto.estatus && objeto.estatus === 'credito generado'}
                                                                                                >
                                                                                                    <i className="fa fa-plus fa-margin"></i>
                                                                                                    Agregar
                                                                                                </button>
                                                                                            }
                                                                                        </div>
                                                                                        <div className="col-sm-2 m-b-xs">
                                                                                            <b>Total Dispersado: </b>{currencyFormat(totalDispersion)}
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="table-responsive">
                                                                                        <table className="table table-striped">
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    <th>Cuenta Ordenante</th>
                                                                                                    <th>Instrucción de deposito</th>
                                                                                                    <th>Fecha Dispersión</th>
                                                                                                    <th>Monto</th>
                                                                                                    <th>Id Seguimiento</th>
                                                                                                    <th>Estatus</th>
                                                                                                    <th>Acciones</th>
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                { ordenesDispersion.map((obj, indice) => (
                                                                                                    <tr key={ obj.id }>
                                                                                                        <td>{ obj.cuenta_ordenante_str }</td>
                                                                                                        { obj.tipo === "normal" ?
                                                                                                              <td> {`${obj.beneficiario_banco+" "+obj.beneficiario_clabe+" "+obj.beneficiario_nombre}`}  </td>
                                                                                                            : obj.tipo === "validacion" ?
                                                                                                                <td>Validación de cuenta</td>
                                                                                                            :
                                                                                                                <td>MOVIMIENTO INTERNO</td>
                                                                                                        }

                                                                                                        <td>{ obj.fecha_dispersion }</td>
                                                                                                        <td>{ currencyFormat(obj.monto) }</td>
                                                                                                        <td>{ obj.clave_rastreo }</td>
                                                                                                        <td>{ obj.estatus }</td>
                                                                                                        <td>
                                                                                                            { obj.comprobante_pago_pdf &&
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm btns-space"
                                                                                                                    // onClick={() => this.mostrarFormularioComprobanteDispersion(true, indice)}
                                                                                                                    onClick={()=> window.open(obj.comprobante_pago_pdf, "_blank")}
                                                                                                                >
                                                                                                                    <i className="fa fa-eye fa-margin"></i>
                                                                                                                    Ver
                                                                                                                </button>
                                                                                                            }
                                                                                                            {   obj.tipo === "normal" &&
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-sm btn-primary btns-space"
                                                                                                                    onClick={() => this.setState({
                                                                                                                        formOrdenDisp:true,
                                                                                                                        ordenDispersion:obj,
                                                                                                                        mostrarFrmOrdenDispEdit:true,
                                                                                                                        indiceOrdenDisp:indice
                                                                                                                    })}
                                                                                                                    disabled={objeto.estatus && objeto.estatus === 'credito generado'}
                                                                                                                >
                                                                                                                    <i className="fa fa-pencil fa-margin"></i>
                                                                                                                    Editar
                                                                                                                </button>
                                                                                                            }
                                                                                                            {   obj.tipo === "normal" &&
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-primary btn-sm btns-space"
                                                                                                                    onClick={()=> this.mostrarConfElimOrdenDispersion(obj, indice)}
                                                                                                                    disabled={objeto.estatus && objeto.estatus === 'credito generado'}
                                                                                                                >
                                                                                                                    <i className="fa fa-trash fa-margin"></i>
                                                                                                                    Eliminar
                                                                                                                </button>
                                                                                                             }

                                                                                                            {   obj.tipo === "normal" && obj.estatus == 'pendiente' && !obj.dispersion_realizada && verificarPermiso('ordenes_dispersion.dispersar_ordendispersion,') &&
                                                                                                                <button
                                                                                                                    type="button"
                                                                                                                    className="btn btn-danger btn-sm btns-space"
                                                                                                                    onClick={()=> this.mostrarConfEnviarOrdenDispersion(obj, indice)}
                                                                                                                    disabled={obj.estatus != 'pendiente'}
                                                                                                                >
                                                                                                                    Dispersar
                                                                                                                </button>
                                                                                                            }
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                ))}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                : !formOrdenDisp &&
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="wrapper wrapper-content">
                                                                <div className="ibox-content m-b-sm">
                                                                    <div className="text-center p-lg">
                                                                        <h2>Aún no hay dispersiones para la solicitud</h2>
                                                                        <button
                                                                            className="btn btn-primary btn-sm"
                                                                            type="button"
                                                                            onClick={() => this.setState({formOrdenDisp:true})}
                                                                            disabled={objeto.estatus && objeto.estatus === 'credito generado'}
                                                                        >
                                                                            <i className="fa fa-plus"></i>
                                                                            <span className="bold">Agregar</span>
                                                                        </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                { formOrdenDisp &&
                                                    <div className="row">
                                                        <div className="col-lg-12">
                                                            <div className="panel panel-default">
                                                                <div className="panel-heading">
                                                                    { mostrarFrmOrdenDispEdit ?
                                                                        <>Editar Dispersión</>
                                                                    :
                                                                        <>Agregar Dispersión</>
                                                                    }
                                                                </div>
                                                                <div className="panel-body">
                                                                    <div className="row">
                                                                        <div className="col-lg-12">
                                                                            <div className="ibox ">
                                                                                <div className="ibox-content">
                                                                                    <Formulario
                                                                                        fields={ formComprobanteDisp.fields }
                                                                                        data={ formComprobanteDisp.data }
                                                                                        errors={ formComprobanteDisp.errors }
                                                                                    />
                                                                                    <div className="row">
                                                                                        <div className="col-lg-12">
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-primary"
                                                                                                onClick={() => this.setState({formOrdenDisp:false, ordenDispersion:{}, errorsOrdenDisp:[]}) }
                                                                                            >
                                                                                                Atrás
                                                                                            </button>
                                                                                            <button
                                                                                                type="button"
                                                                                                className="btn btn-primary btn-sm right"
                                                                                                disabled={this.state.ordenDispGuardarDisabled}
                                                                                                onClick={() => this.guardarDispersion()}
                                                                                            >
                                                                                                { this.state.ordenDispGuardarDisabled ? 'Guardando...' : 'Guardar' }
                                                                                            </button>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <Modal visible={ mostrarConfElimOrdenDisp } width="50%" height="27.55%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Eliminar Orden de Dispersión</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>
                                                                ¿Estás seguro de eliminar esta Orden de Dispersión?
                                                            </p>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.eliminarOrdenDisp()}>Aceptar</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                                <Modal visible={ mostrarConfEnviarOrdenDisp } width="50%" height="27.55%" effect="fadeInUp">
                                                    <div className="modal-content animated bounceInRight">
                                                        <div className="modal-header">
                                                            <h4 className="modal-title">Enviar Orden de Dispersión</h4>
                                                        </div>
                                                        <div className="modal-body">
                                                            <p>
                                                                ¿Estás seguro de realizar la dispersión con los siguientes datos?
                                                            </p>
                                                            <table className="table table-striped">
                                                                <thead>
                                                                    <tr>
                                                                        <th>Banco</th>
                                                                        <th>Beneficiario</th>
                                                                        <th>Cuenta CLABE</th>
                                                                        <th>Monto</th>
                                                                        <th>Id Seguimiento</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                        <tr key={ datosEnviarCompOrdenDisp.obj.id }>
                                                                            <td>{ datosEnviarCompOrdenDisp.obj.beneficiario_banco } </td>
                                                                            <td>{ datosEnviarCompOrdenDisp.obj.beneficiario_clabe } </td>
                                                                            <td>{ datosEnviarCompOrdenDisp.obj.beneficiario_nombre } </td>
                                                                            <td>{ currencyFormat(datosEnviarCompOrdenDisp.obj.monto) } </td>
                                                                            <td>{ datosEnviarCompOrdenDisp.obj.clave_rastreo } </td>
                                                                        </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div className="modal-footer">
                                                            <button type="button" className="btn btn-white" onClick={() => this.cerrarModal() }>Cerrar</button>
                                                            <button type="button" className="btn btn-primary" onClick={() => this.enviarOrdenDisp()}>Enviar</button>
                                                        </div>
                                                    </div>
                                                </Modal>
                                            </div>
                                        </div>
                                        <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 8 && 'active'}`}>
                                            {renovacion.id ?
                                                <div className="panel-body">
                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Renovación al prestamo {renovacion.prestamo_anterior_str}
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Estatus</label>
                                                                        <label>{renovacion.estatus}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Capital</label>
                                                                        <label>{currencyFormat(renovacion.capital)}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Intéres Ordinario</label>
                                                                        <label>{currencyFormat(renovacion.interes)}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Nueva línea</label>
                                                                        <label>{currencyFormat(renovacion.nueva_linea)}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Monto para saldar</label>
                                                                        <label>{currencyFormat(renovacion.monto_liquidar_credito)}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Dispersión a Cliente</label>
                                                                        <label>{currencyFormat(renovacion.total_dispersion_cliente)}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Fecha creación</label>
                                                                        <label>{dateFormat(renovacion.fecha_creacion)}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Se aplicará liquidación desde la amortización:</label>
                                                                        <label>{renovacion.aplicar_liquidacion_desde}</label>
                                                                    </div>
                                                                </div>
                                                                { renovacion.facturas_pendientes &&
                                                                    <div className="row">
                                                                        <div className="col-sm-12 col-md-6 sol">
                                                                            <b>Cuenta con factura(s) pendiente(s)</b>
                                                                        </div>
                                                                    </div>
                                                                }
                                                                {objeto.estatus && objeto.estatus !== 'credito generado' &&
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <button
                                                                              type="button"
                                                                              className="btn btn-sm btn-danger right"
                                                                              disabled={!renovacion.id}
                                                                              onClick={() => {
                                                                                  this.setState({ confirmarEliminarVinculo: true })
                                                                              }}
                                                                            >
                                                                                Eliminar Vínculo
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <Modal visible={ confirmarEliminarVinculo } width="50%" height="27.55%" effect="fadeInUp">
                                                        <div className="modal-content animated bounceInRight">
                                                            <div className="modal-header">
                                                                <h4 className="modal-title">Eliminar Renovación</h4>
                                                            </div>
                                                            <div className="modal-body">
                                                                <p>
                                                                    ¿Estás seguro de eliminar esta Renovación?
                                                                </p>
                                                            </div>
                                                            <div className="modal-footer">
                                                                <button type="button" className="btn btn-white" onClick={() => this.setState({confirmarEliminarVinculo: false}) }>Cerrar</button>
                                                                <button type="button" className="btn btn-primary" onClick={() => this.eliminarVinculo()}>Eliminar</button>
                                                            </div>
                                                        </div>
                                                    </Modal>
                                                </div>
                                            :
                                                <div className="panel-body">
                                                    <div className="col-lg-12">
                                                        <div className="ibox-content">
                                                            <div className="row">
                                                                <div className="input-group">
                                                                    <input
                                                                        value={search}
                                                                        type="text"
                                                                        className="form-control form-control-lg"
                                                                        onChange={e => this.onChangeSearch('search', e)}
                                                                        onKeyDown={e => this.handleKeyDown(e)}
                                                                        placeholder="Buscar Préstamo (folio)"
                                                                        disabled={objeto.estatus && objeto.estatus === 'credito generado'}
                                                                    />
                                                                    <span className="input-group-append">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-info"
                                                                            onClick={() => this.limpiar()}
                                                                            disabled={objeto.estatus && objeto.estatus === 'credito generado'}
                                                                        >
                                                                            <i className="fa fa-close fa-margin"/>
                                                                        </button>
                                                                    </span>
                                                                    <span className="input-group-append">
                                                                        <button
                                                                            type="button"
                                                                            className="btn btn-sm btn-primary"
                                                                            onClick={() => this.buscar()}
                                                                            disabled={objeto.estatus && objeto.estatus === 'credito generado'}
                                                                        >
                                                                            <i className="fa fa-search fa-margin"/>
                                                                            Buscar
                                                                        </button>
                                                                    </span>
                                                                </div>
                                                            </div>
                                                            <div className="row"><b>{mensaje}</b></div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Préstamo {prestamoRenovar.folio} a Renovar
                                                            </div>
                                                            <div className="panel-body">
                                                                <div className="row">
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Estatus</label>
                                                                        <label>{prestamoRenovar.estatus}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Fecha Último Pago</label>
                                                                        {prestamoRenovar.ultimo_pago ?
                                                                            <label>{dateFormat(prestamoRenovar.ultimo_pago)}</label>
                                                                        :
                                                                            <label>{""}</label>
                                                                        }
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Avance de pagos</label>
                                                                        <label>{prestamoRenovar.avance_pagos}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Avance de capital</label>
                                                                        <label>{prestamoRenovar.avance_capital}%</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Capital actual</label>
                                                                        <label>{currencyFormat(prestamoRenovar.capital)}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Intéres Ordinario</label>
                                                                        <label>{currencyFormat(prestamoRenovar.intereses_ordinarios)}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Nuevo Saldo</label>
                                                                        {prestamoRenovar.folio ?
                                                                            <label>{currencyFormat(objeto.monto)}</label>
                                                                        :
                                                                            <label>{currencyFormat(0)}</label>
                                                                        }
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Monto para saldar</label>
                                                                        <label>{currencyFormat(prestamoRenovar.monto_saldar)}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Dispersión a Cliente</label>
                                                                        <label>{currencyFormat(objeto.monto - prestamoRenovar.monto_saldar)}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label>Amortización desde la que se aplicará la liquidación</label>
                                                                        <label>{prestamoRenovar.aplicar_liquidacion_desde}</label>
                                                                    </div>
                                                                    <div className="col-sm-12 col-md-6 sol">
                                                                        <label><b>Próxima Factura:</b></label>
                                                                        {prestamoRenovar.proxima_factura ?
                                                                          <label>{dateFormat(prestamoRenovar.proxima_factura)}</label>
                                                                          :
                                                                          <label>{""}</label>
                                                                        }
                                                                    </div>
                                                                </div>
                                                                {objeto.estatus && objeto.estatus !== 'credito generado' &&
                                                                    <div className="row">
                                                                        <div className="col-sm-12">
                                                                            <button
                                                                              type="button"
                                                                              className="btn btn-sm btn-primary right"
                                                                              disabled={!prestamoRenovar.id}
                                                                              onClick={() => {
                                                                                  this.crearVinculo()
                                                                              }}
                                                                            >
                                                                                Generar Vínculo a Renovar
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>

                                        {/* CDD */}
                                        <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 9 && 'active'}`}>
                                            <div className="panel-body">
                                                <CDDProvider >
                                                    <CDD solicitud={objeto} />
                                                </CDDProvider>
                                            </div>
                                        </div>

                                        {/*  IA  */}
                                        <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 10 && 'active'}`}>
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="panel panel-default">
                                                            <div className="panel-heading">
                                                                Análisis de crédito con IA
                                                            </div>
                                                            <div className="panel-body">
                                                                { ia.data ?
                                                                    <div className="row">
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Antigüedad laboral del solicitante</label>
                                                                                    <label>{ ia.data.Solicitante.AntiguedadLaboral }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Edad del solicitante</label>
                                                                                    <label>{ ia.data.Solicitante.Edad }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Entidad federativa</label>
                                                                                    <label>{ ia.data.Solicitante.EntidadFederativa }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Estado Civil</label>
                                                                                    <label>{ ia.data.Solicitante.EstadoCivil }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Fecha Contratación Laboral</label>
                                                                                    <label>{ dateFormat(ia.data.Solicitante.FechaContratacionLaboral) }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Fecha de Nacimiento</label>
                                                                                    <label>{ dateFormat(ia.data.Solicitante.FechaNacimiento) }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Ingreso Mensual</label>
                                                                                    <label>{ currencyFormat(ia.data.Solicitante.IngresoMensual) }</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <div className="row">
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Folio de la solicitud</label>
                                                                                    <label>{ ia.data.Solicitud.Folio }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Estatus de buró de crédito</label>
                                                                                    <label>{ ia.data.Solicitud.EstatusBuro }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Frecuencia de pagos</label>
                                                                                    <label>{ ia.data.Solicitud.FrecuenciaPagos }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Monto solicitado</label>
                                                                                    <label>{ currencyFormat(ia.data.Solicitud.MontoSolicitado) }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Número de amortizaciones</label>
                                                                                    <label>{ ia.data.Solicitud.NumAmortizaciones }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Monto de pago</label>
                                                                                    <label>{ currencyFormat(ia.data.Solicitud.MontoPago) }</label>
                                                                                </div>
                                                                                <div className="col-sm-12 sol">
                                                                                    <label>Estatus Wise IA</label>
                                                                                    <label className="label label-info text-capitalize">{ objeto.analisis_ia || 'PENDIENTE' }</label>
                                                                                </div>
                                                                                { ia.data.analisis_yuhu_ia &&
                                                                                    <>
                                                                                    <div className="col-sm-12 sol">
                                                                                        <label>Estatus Yuhu IA</label>
                                                                                        <label className="label label-info text-capitalize">{ ia.data.analisis_yuhu_ia || 'PENDIENTE' }</label>
                                                                                    </div>
                                                                                    <div className="col-sm-12 sol">
                                                                                        <label>Score Yuhu IA</label>
                                                                                        <label className="text-capitalize">{ ia.data.analisis_yuhu_score_ia + '%' || '--' }</label>
                                                                                    </div>
                                                                                    </>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                        <div className="col-12 m-t-lg">
                                                                            <div className="row">
                                                                                { verificarPermiso('solicitudes.analizar_ia,') && objeto.analisis_ia === 'pendiente' && ia.puedeAnalizar &&
                                                                                  <div className="col-12 text-center">
                                                                                      <button disabled={ia.analizando} className="btn btn-primary btn-lg" onClick={() => this.analizarIA()}>{ia.analizando ? 'Analizando con IA' : 'Analizar con IA'}</button>
                                                                                  </div>
                                                                                }
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                :
                                                                  <div className="row">
                                                                      <div className="col-12 text-center">
                                                                          { ia.cargandoData ?
                                                                            <h3>Cargando información</h3>
                                                                            :
                                                                            <h3>Esta solicitud no cuenta con reporte de buró para hacer el análisis con IA</h3>
                                                                          }
                                                                      </div>
                                                                  </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        {/* Informacion de OCR */}
                                        <div role="tabpanel" className={`tab-pane ${pestanaSeleccionada === 11 && 'active'}`}>
                                            <div className="panel-body">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                         <OcrData idSolicitud={objeto.id} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>      

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <style jsx="true">{`
                    .right{
                        text-align: right;
                    }
                    .sol{
                        display: flex;
                        justify-content: space-between;
                    }
                    .ibox-content{
                        border-style: none;
                    }
                    .fa-margin {
                        margin-right: 5px;
                    }
                    .right {
                        float: right;
                    }
                    .celda-delgada {
                        padding: 0.3rem !important;
                    }
                    .custom-control-input:checked ~ .custom-control-label::before {
                        color: #fff;
                        border-color: #25d07b;
                        background-color: #25d07b;
                    }
                    .invalid-feedback {
                        display: block;
                    }
                    .warning-rfc-curp {
                        color: #e2943a !important;
                    }
                    .success-rfc-curp {
                        color: green !important;
                    }
                    .alertBC {
                        color: red !important;
                    }
                `}</style>
            </Autenticado>
        )
    }
}

export default Form
