import React, { Component } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Inicio from './pages/Inicio';
import IniciarSesion from './pages/IniciarSesion';
import ResetarContrasena from './pages/ResetarContrasena';
import Error404 from './pages/Error404';
import OlvideMiContrasenia from './pages/OlvideMiContrasenia';
import ListaGrupoEmpresas from './pages/grupos_empresas/Lista';
import FormGrupoEmpresas from './pages/grupos_empresas/Form';
import VerGrupoEmpresas from './pages/grupos_empresas/Ver';
import ListaProductos from './pages/productos/Lista';
import FormProducto from './pages/productos/Form';
import VerProducto from './pages/productos/Ver';
import CotizarProducto from './pages/productos/Cotizar';
import ListaUsuarios from './pages/usuarios/Lista';
import FormUsuario from './pages/usuarios/Form';
import VerUsuario from './pages/usuarios/Ver';
import ListaGrupos from './pages/grupos/Lista';
import FormGrupo from './pages/grupos/Form';
import VerGrupo from './pages/grupos/Ver';
import ListaEmpleados from './pages/empleados/Lista';
import FormEmpleados from './pages/empleados/Form';
import VerEmpleado from './pages/empleados/Ver';
import ImportarEmpleado from './pages/empleados/Importar';
import ListaSolicitudes from './pages/solicitudes/Lista';
import VerSolicitudes from './pages/solicitudes/Ver';
import AgregarSolicitudes from './pages/solicitudes/Crear';
import EditarSolicitudes from './pages/solicitudes/Editar';
import Renovacion from './pages/prestamos/Renovacion';
import Referencias from './pages/solicitudes/Referencias';
import InformacionCrediticia from './pages/solicitudes/InformacionCrediticia';
import Comprobantes from './pages/solicitudes/Comprobantes';
import ListaDisposiciones from './pages/disposiciones/Lista';
import VerDisposicion from './pages/disposiciones/Ver';
import ListaPrestamos from './pages/prestamos/Lista';
import VerPrestamos from './pages/prestamos/Ver';
import LiquidarPrestamo from './pages/prestamos/Liquidar';
import ListaPagos from './pages/pagos/Lista';
import CrearPago from './pages/pagos/Crear';
import VerPagos from './pages/pagos/Ver';
import ListaTransacciones from './pages/emida/Transacciones';
import ListaRecargas from './pages/emida/Recargas';
import DetalleRecarga from './pages/emida/DetalleRecarga';
import ListaPagoServicios from './pages/emida/PagoServicios';
import DetallePagoServicio from './pages/emida/DetallePagoServicio';
import ListaCuentasBancarias from './pages/cuentas_bancarias/Lista';
import FormCuentasBancarias from './pages/cuentas_bancarias/Form';
import VerCuentasBancarias from './pages/cuentas_bancarias/Ver';
import ListaPagoMasivo from './pages/pagos/MasivosLista';
import CrearPagoMasivo from './pages/pagos/MasivosCrear';
import DetallePagoMasivo from './pages/pagos/MasivosDetalle';
import ReporteCobranza from './pages/pagos/ReporteCobranza';
import ReportePagosEmergentes from './pages/pagos/ReportePagosEmergentes';
import EstadoCuenta from './components/EstadoCuenta';
import ListaCuentasMaestra from './pages/jerarquia_empresas/ListaCuentasMaestas';
import VerCuentasMaestra from './pages/jerarquia_empresas/VerCuentasMaestras';
import ListaEmpresas from './pages/jerarquia_empresas/ListaEmpresas';
import FormEmpresas from './pages/jerarquia_empresas/FormEmpresas';
import VerEmpresa from './pages/jerarquia_empresas/VerEmpresas';
import ListaUnidadesOperativas from './pages/jerarquia_empresas/ListaUnidadesOperativas';
import VerUnidadesOperativas from './pages/jerarquia_empresas/VerUnidadesOperativas';
import DomiciliacionLeer from './pages/domiciliacion/leer';
import DomiciliacionGenerar from './pages/domiciliacion/generar';
import ImportarSepomex from './pages/sepomex/Importar';
import HistorialDomiciliacion from './pages/domiciliacion/historial_respuestas';
import AltasDomiciliacion from './pages/domiciliacion/altas';
import { isLogin } from './utils/auth';
import GenerarPromesas from './pages/domiciliacion/generar_promesas';
import ReporteNip from './pages/reportes/ReporteNip';
import UpdateTipoDescuentoMasivo from './pages/prestamos/update_tipo_descuento_masivo';
import HabilitacionCreditos from './pages/domiciliacion/habilitacion_creditos';
import DasboardTracking from './pages/dashboard_tracking/Dashboard';
import Integraciones from './pages/integraciones/FechaExpiracionBuroCredito'
import ReportesBuroCreditoList from './pages/buro_credito/ReportesBuroCreditoList';
import ArchivosBancarios from './pages/transacciones_bancarias/ArchivosBancarios';
import ArchivosBancariosDetalle from './pages/transacciones_bancarias/ArchivosBancariosDetalle';
import TransaccionBancaria from './pages/transacciones_bancarias/TransaccionesBancariasDetalle';
import ProcesarArchivoBancario from './pages/transacciones_bancarias/ProcesarArchivoBancario.js';


const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isLogin() === true ? (
          <Component {...props} />
        ) : (
          <Redirect to="/iniciar-sesion" />
        )
      }
    />
  );
};

const PublicRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props =>
        isLogin() === false ? <Component {...props} /> : <Redirect to="/" />
      }
    />
  );
};

class App extends Component {
  state = {
    token: undefined
  };

  render() {
    return (
      <Switch>
        <PrivateRoute exact path="/" component={Inicio} />
        <PrivateRoute exact path="/dashboard-tracking" component={DasboardTracking} />
        <PublicRoute exact path="/iniciar-sesion" component={IniciarSesion} />
        <PublicRoute
          exact
          path="/reset/:id/:uuid/"
          component={ResetarContrasena}
        />
        <PublicRoute
          exact
          path="/olvide-mi-contrasena"
          component={OlvideMiContrasenia}
        />
        <PrivateRoute exact path="/empresas" component={ListaEmpresas} />
        <PrivateRoute exact path="/empresas/agregar" component={FormEmpresas} />
        <PrivateRoute
          exact
          path="/empresas/editar/:id"
          component={FormEmpresas}
        />
        <PrivateRoute exact path="/empresas/ver/:id" component={VerEmpresa} />
        <PrivateRoute
          exact
          path="/grupos-de-empresas"
          component={ListaGrupoEmpresas}
        />
        <PrivateRoute
          exact
          path="/grupos-de-empresas/agregar"
          component={FormGrupoEmpresas}
        />
        <PrivateRoute
          exact
          path="/grupos-de-empresas/editar/:id"
          component={FormGrupoEmpresas}
        />
        <PrivateRoute
          exact
          path="/grupos-de-empresas/ver/:id"
          component={VerGrupoEmpresas}
        />
        <PrivateRoute exact path="/productos" component={ListaProductos} />
        <PrivateRoute
          exact
          path="/productos/agregar"
          component={FormProducto}
        />
        <PrivateRoute
          exact
          path="/productos/editar/:id"
          component={FormProducto}
        />
        <PrivateRoute exact path="/productos/ver/:id" component={VerProducto} />
        <PrivateRoute
          exact
          path="/productos/cotizar/:id"
          component={CotizarProducto}
        />
        <PrivateRoute exact path="/usuarios" component={ListaUsuarios} />
        <PrivateRoute exact path="/usuarios/agregar" component={FormUsuario} />
        <PrivateRoute
          exact
          path="/usuarios/editar/:id"
          component={FormUsuario}
        />
        <PrivateRoute exact path="/usuarios/ver/:id" component={VerUsuario} />
        <PrivateRoute exact path="/grupos" component={ListaGrupos} />
        <PrivateRoute exact path="/grupos/agregar" component={FormGrupo} />
        <PrivateRoute exact path="/grupos/editar/:id" component={FormGrupo} />
        <PrivateRoute exact path="/grupos/ver/:id" component={VerGrupo} />
        <PrivateRoute exact path="/empleados" component={ListaEmpleados} />
        <PrivateRoute
          exact
          path="/empleados/agregar"
          component={FormEmpleados}
        />
        <PrivateRoute
          exact
          path="/empleados/editar/:id"
          component={FormEmpleados}
        />
        <PrivateRoute exact path="/empleados/ver/:id" component={VerEmpleado} />
        <PrivateRoute
          exact
          path="/empleados/importar"
          component={ImportarEmpleado}
        />
        <PrivateRoute exact path="/solicitudes" component={ListaSolicitudes} />
        <PrivateRoute
          exact
          path="/solicitudes/agregar"
          component={AgregarSolicitudes}
        />
        <PrivateRoute
          exact
          path="/solicitudes/ver/:id"
          component={VerSolicitudes}
        />
        <PrivateRoute
          exact
          path="/solicitudes/editar/:id"
          component={EditarSolicitudes}
        />
        <PrivateRoute
          exact
          path="/solicitudes/:id/referencias"
          component={Referencias}
        />
        <PrivateRoute
          exact
          path="/solicitudes/:id/otros-creditos"
          component={InformacionCrediticia}
        />
        <PrivateRoute
          exact
          path="/solicitudes/:id/comprobantes"
          component={Comprobantes}
        />
        <PrivateRoute
          exact
          path="/disposiciones"
          component={ListaDisposiciones}
        />
        <PrivateRoute
          exact
          path="/disposiciones/ver/:id"
          component={VerDisposicion}
        />
        <PrivateRoute exact path="/prestamos" component={ListaPrestamos} />
        <PrivateRoute
          exact
          path="/prestamos/ver/:id"
          component={VerPrestamos}
        />
        <PrivateRoute
          exact
          path="/prestamos/estado-cuenta/:id"
          component={EstadoCuenta}
        />
        <PrivateRoute
          exact
          path="/prestamos/liquidar/:id"
          component={LiquidarPrestamo}
        />
        <PrivateRoute
          exact
          path="/prestamos/:id/renovacion/"
          component={Renovacion}
        />
        <PrivateRoute exact path="/pagos" component={ListaPagos} />
        <PrivateRoute exact path="/pagos/crear" component={CrearPago} />
        <PrivateRoute exact path="/pagos/ver/:id" component={VerPagos} />
        <PrivateRoute exact path="/pagos/masivos" component={ListaPagoMasivo} />
        <PrivateRoute
          exact
          path="/pagos/masivos/crear"
          component={CrearPagoMasivo}
        />
        <PrivateRoute
          exact
          path="/pagos/masivos/:id"
          component={DetallePagoMasivo}
        />
        <PrivateRoute
          exact
          path="/transacciones"
          component={ListaTransacciones}
        />
        <PrivateRoute exact path="/recargas" component={ListaRecargas} />
        <PrivateRoute
          exact
          path="/recargas/ver/:id"
          component={DetalleRecarga}
        />
        <PrivateRoute
          exact
          path="/pago-de-servicios"
          component={ListaPagoServicios}
        />
        <PrivateRoute
          exact
          path="/pago-de-servicios/ver/:id"
          component={DetallePagoServicio}
        />
        <PrivateRoute
          exact
          path="/cuentas-bancarias"
          component={ListaCuentasBancarias}
        />
        <PrivateRoute
          exact
          path="/cuentas-bancarias/agregar"
          component={FormCuentasBancarias}
        />
        <PrivateRoute
          exact
          path="/cuentas-bancarias/editar/:id"
          component={FormCuentasBancarias}
        />
        <PrivateRoute
          exact
          path="/cuentas-bancarias/ver/:id"
          component={VerCuentasBancarias}
        />
        <PrivateRoute
          exact
          path="/sepomex/importar"
          component={ImportarSepomex}
        />
        <PrivateRoute
          exact
          path="/pagos/reporte-de-cobranza"
          component={ReporteCobranza}
        />
          <PrivateRoute
            exact
            path="/pagos/reporte-pagos-emergentes"
            component={ReportePagosEmergentes}
          />
        <PrivateRoute exact path="/cuentas-maestra" component={ListaCuentasMaestra} />
        <PrivateRoute exact path="/cuentas-maestra/ver/:id" component={VerCuentasMaestra} />
        <PrivateRoute exact path="/unidades-operativas" component={ListaUnidadesOperativas} />
        <PrivateRoute exact path="/unidades-operativas/ver/:id" component={VerUnidadesOperativas} />
        <PrivateRoute
          exact
          path="/domiciliacion/generar"
          component={DomiciliacionGenerar}
        />
        <PrivateRoute
          exact
          path="/domiciliacion/generar_promesas"
          component={GenerarPromesas}
        />
        <PrivateRoute
          exact
          path="/domiciliacion/leer"
          component={DomiciliacionLeer}
        />
        <PrivateRoute
          exact
          path="/domiciliacion/altas"
          component={AltasDomiciliacion}
        />
        <PrivateRoute
          exact
          path="/domiciliacion/habilitar_creditos"
          component={HabilitacionCreditos}
        />
        <PrivateRoute
          exact
          path="/domiciliacion/historial"
          component={HistorialDomiciliacion}
        />
        <PrivateRoute
          exact
          path="/reportes/reporte-nip"
          component={ReporteNip}
        />
        <PrivateRoute
          exact
          path="/prestamos_tipo_descuento/update"
          component={UpdateTipoDescuentoMasivo}
        />        
        <PrivateRoute
          exact
          path="/integraciones/fecha-expiracion-buro-credito"
          component={Integraciones}
        />
          <PrivateRoute
            exact
            path="/buro-credito/reportes"
            component={ReportesBuroCreditoList}
          />

        <PrivateRoute
          exact
          path="/conciliaciones/archivos-bancarios"
          component={ArchivosBancarios}
        />

        <PrivateRoute
          exact
          path="/conciliaciones/archivos-bancarios/ver/:id"
          component={ArchivosBancariosDetalle}
        />

        <PrivateRoute
          exact
          path="/conciliaciones/transacciones/ver/:id"
          component={TransaccionBancaria}
        />

        <PrivateRoute
          exact
          path="/conciliaciones/agregar"
          component={ProcesarArchivoBancario}
        />

        <Route component={Error404} />
      </Switch>
    );
  }
}

export default App;
