import React, { useContext } from 'react';
import Swal from 'sweetalert2';
import { CDDContext } from '../../../context/solicitudes/CDDContext';
import { currencyFormat, dateFormat } from '../../../utils/filters';
import { verificarPermiso } from '../../../utils/auth';


const CDDCuenta = ({cuenta}) => {
    const { mostrarModalActualizar, mostrarModalSeleccionar, removerCuenta} = useContext(CDDContext);

    const seleccionarCuenta = (cuenta) => {
        mostrarModalSeleccionar(true, cuenta);
    }

    const removerCuentaSeleccionada = (cuenta) => {
        removerCuenta(cuenta);
        Swal.fire({
            type: 'success',
            text: 'La cuenta fue removida exitosamente',
            toast: true,
            position: 'top-end',
            timer: 5000,
            showConfirmButton: false
        })
    }

    return ( 
        <tr>
            <td>{cuenta.nombre_otorgante}</td>
            <td>{cuenta.tipo_cuenta_display}</td>
            <td>{cuenta.tipo_contrato_display}</td>
            <td>{cuenta.numero_pagos_real}</td>
            <td>{cuenta.frecuencia_pagos_display}</td>
            {/* <td>{currencyFormat(cuenta.monto_pagar)}</td> */}
            <td>{dateFormat(cuenta.fecha_apertura)}</td>
            <td>{dateFormat(cuenta.fecha_ultimo_pago)}</td>
            <td>{currencyFormat(cuenta.credito_maximo)}</td>
            <td>{currencyFormat(cuenta.saldo_actual_real)}</td>
            <td>{currencyFormat(cuenta.monto_pagar_real)}</td>
            <td>{currencyFormat(cuenta.saldo_vencido)}</td>
            <td>{cuenta.forma_pago_actual}</td>
            <td>{cuenta.historico_pago}</td> 
            {/* <td>{cuenta.apta_cdd ? 'Si' : 'No'}</td> */}

            <td>
            
                {cuenta.cuenta_cerrada ? <span className="text-info">Cuenta Cerrada</span> :
                  <>
                    {cuenta.apta_cdd ? 
                      <>
                        { verificarPermiso('solicitudes.modificar_cuenta_cdd,') &&
                          <>
                          {
                            cuenta.estatus_yuhu === 'aceptada' ?
                              <button
                                className="btn btn-danger btn-xs btns-space"
                                onClick={() => removerCuentaSeleccionada(cuenta)}
                              >
                                <i className="fa fa-remove"></i>
                              </button>
                              :
                              <>
                                <button
                                  className="btn btn-primary btn-xs btns-space"
                                  onClick={() => mostrarModalActualizar(true, cuenta)}
                                >
                                  <i className="fa fa-edit"></i>
                                </button>
                                <button
                                  className="btn btn-primary btn-xs btns-space"
                                  onClick={() => seleccionarCuenta(cuenta)}
                                >
                                  <i className="fa fa-check"></i>
                                </button>
                              </>
                          }
                          </>             
                        }
                    
                      </>
                      :
                      <span className="text-info">No apta</span>
                    }
                  </>
                }
            </td>
        </tr>
    );
}
 
export default CDDCuenta;