import { ConstructorAPIAsync } from './API';
import { callError } from './Error';

async function obtenerPrestamos(parametros = '') {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get('/admin/prestamos/', { params: parametros });
  } catch (err) {
    callError(err);
  }
}

async function exportarPrestamos(params={}) {
  params.export = true;
  const API = await ConstructorAPIAsync(120000, 'blob');
  try {
    const resp = await API.get('/admin/prestamos/', { params });
    delete params.export;
    return resp;
  } catch (err) {
    delete params.export;
    callError(err);
  }
}

async function obtenerPrestamo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/prestamos/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerAmortizacionesPrestamo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/prestamos/${id}/amortizaciones/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerGastosCobranzaPrestamo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/prestamos/${id}/gastos-cobranza/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerPagosPrestamo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/prestamos/${id}/pagos/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerComentariosPrestamo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/prestamos/${id}/comentarios/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarComentariosPrestamo(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/prestamos/${id}/comentarios/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarComentariosPrestamo(datos, idPrestamo, idComentario) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(
      `/admin/prestamos/${idPrestamo}/comentarios/${idComentario}`,
      datos
    );
  } catch (err) {
    callError(err);
  }
}

async function eliminarComentariosPrestamo(idPrestamo, idComentario) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(
      `/admin/prestamos/${idPrestamo}/comentarios/${idComentario}`
    );
  } catch (err) {
    callError(err);
  }
}

async function obtenerMovimientosPrestamo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/prestamos/${id}/movimientos/`);
  } catch (err) {
    callError(err);
  }
}

async function obtenerDocumentosPrestamo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/prestamos/${id}/documentos/`);
  } catch (err) {
    callError(err);
  }
}

async function agregarDocumentosPrestamo(datos, id) {
  const API = await ConstructorAPIAsync(60000);
  try {
    return await API.post(`/admin/prestamos/${id}/documentos/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function eliminarDocumentosPrestamo(idPrestamo, idDocumento) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.delete(
      `/admin/prestamos/${idPrestamo}/documentos/${idDocumento}`
    );
  } catch (err) {
    callError(err);
  }
}

async function cambiarFechaPrestamo(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/prestamos/${id}/cambiar-fecha/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function liquidarPrestamo(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/prestamos/${id}/liquidar/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function crearRenovacion(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/prestamos/${id}/renovacion/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function obtenerRenovacion(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/renovacion/${id}/`);
  } catch (err) {
    callError(err);
  }
}

async function actualizarFechasPrestamo(datos, id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/prestamos/${id}/actualizar-fechas/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function actualizarTipoDescuentoMasivoPrestamo(datos) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`admin/prestamos/update-tipo-descuento/`, datos);
  } catch (err) {
    callError(err);
  }
}

async function obtenerHistorialPagosDomiciliadosPrestamo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/prestamos/${id}/historial-pagos-domiciliados/`);
  } catch (err) {
    callError(err);
  }
}
async function reporteCastigos() {
  const API = await ConstructorAPIAsync(120000,'blob');
  try {
    return await API.get(`/admin/prestamos/reporte-castigos/`);
  } catch (err) {
    callError(err);
  }
}

async function generarDatosCobranza(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/prestamos/${id}/generar-datos-cobranza/`);
  } catch (err) {
    callError(err);
  }
}
async function obtenerExcedentesPrestamo(id) {
  const API = await ConstructorAPIAsync();
  try {
    return await API.get(`/admin/prestamos/${id}/excedentes/`);
  } catch (err) {
    callError(err);
  }
}
async function crearReembolso(datos,id){
  const API = await ConstructorAPIAsync();
  try {
    return await API.post(`/admin/prestamos/${id}/excedentes/`, datos);
  } catch (err) {
    callError(err);
  }
}

export {
  obtenerPrestamos,
  exportarPrestamos,
  obtenerPrestamo,
  obtenerAmortizacionesPrestamo,
  obtenerGastosCobranzaPrestamo,
  obtenerPagosPrestamo,
  obtenerComentariosPrestamo,
  agregarComentariosPrestamo,
  actualizarComentariosPrestamo,
  eliminarComentariosPrestamo,
  obtenerMovimientosPrestamo,
  obtenerDocumentosPrestamo,
  agregarDocumentosPrestamo,
  eliminarDocumentosPrestamo,
  cambiarFechaPrestamo,
  liquidarPrestamo,
  crearRenovacion,
  obtenerRenovacion,
  actualizarFechasPrestamo,
  actualizarTipoDescuentoMasivoPrestamo,
  obtenerHistorialPagosDomiciliadosPrestamo,
  reporteCastigos,
  generarDatosCobranza,
  obtenerExcedentesPrestamo,
  crearReembolso
}
