import React, { useContext } from 'react';
import Modal from 'react-awesome-modal';
import Swal from 'sweetalert2';
import { CDDContext } from '../../../context/solicitudes/CDDContext';
import { currencyFormat } from '../../../utils/filters';
import CotizacionComparativa from './CotizacionComparativa';

const ModalSeleccionarCuenta = () => {
    const { modalSeleccionar, mostrarModalSeleccionar, cuenta, aprobarCuenta } = useContext(CDDContext);

    const seleccionarCuenta = () => {
        aprobarCuenta();
        Swal.fire({
            type: 'success',
            text: 'La cuenta fue agregada satisfactoriamente',
            toast: true,
            position: 'top-end',
            timer: 3000,
            showConfirmButton: false
        })
    }
    
    return (
        <Modal visible={ modalSeleccionar } width="30%" height="450px" effect="fadeInUp">
            <div className="modal-header">
                <h4 className="modal-title">Agregar cuenta CDD</h4>
            </div>
            <div className="modal-body">
                <p>¿Desea agregar la cuenta para compra de deuda por <strong>{ currencyFormat(cuenta.saldo_actual_real) }</strong>?</p>
                { cuenta.cotizacion_yuhu && cuenta.cotizacion_actual ? 
                    <CotizacionComparativa cuenta={cuenta} />  
                :
                    null
                }        
            </div> 
            <div className="modal-footer">
                <button type="button" className="btn btn-white" onClick={() => mostrarModalSeleccionar(false, cuenta) }>Cancelar</button>
                { cuenta.estatus_yuhu === 'aceptada' ? 
                    'cuenta seleccionada' : 
                    <button type="button" className="btn btn-primary" onClick={() => seleccionarCuenta() }>Aceptar</button>
                }
            </div>
        </Modal>
    );
}
 
export default ModalSeleccionarCuenta;