import React from 'react';


const NavModos = ({modo, modos, accion}) => {
    
    return ( 
        <div className="btn-group">
            {
                Object.entries(modos).map( ([key, value], index) => (
                    <button
                        key={index}
                        type="button"
                        className={`btn btn-xs ${modo === key ? 'btn-primary' :  'btn-white'}`}
                        onClick={() => accion(key)}
                    >
                        {value}
                    </button>
                    ) )
            } 
        </div>
    );
}
 
export default NavModos;