import React, { useContext } from 'react';
import { CDDContext } from '../../../context/solicitudes/CDDContext';
import { currencyFormat } from '../../../utils/filters';

const CDDResumen = () => {

    const { resumenCuenta } = useContext(CDDContext)
    

    console.log(resumenCuenta);
    return (
        <div>

            {resumenCuenta.cuentas.map((cuenta, index) => (
                <table className="table table-hover no-margins" key={index}>
                    <thead>
                        <tr>
                            <th width="25%">{cuenta.nombre_otorgante}</th>
                            <th width="25%">{cuenta.num_cuentas}</th>
                            <th width="25%">{ currencyFormat(cuenta.credito_maximo_total)}</th>
                            <th width="25%">{ currencyFormat(cuenta.saldo_actual_total) }</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cuenta.subcuentas.map((subcuenta, index) => (
                            <tr key={index}>
                                <td>&nbsp;&nbsp;&nbsp;&nbsp;{subcuenta.tipo_cuenta_nombre}</td>
                                <td>{subcuenta.num_cuentas}</td>
                                <td>{ currencyFormat(subcuenta.credito_maximo_total_tipo_cuenta) }</td>
                                <td>{ currencyFormat(subcuenta.saldo_actual_total) }</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ))}
            <table className="table table-hover no-margins">
                <thead>
                    <tr>
                        <th width="25%">Total general</th>
                        <th width="25%">{ resumenCuenta.num_cuentas }</th>
                        <th width="25%">{ currencyFormat(resumenCuenta.credito_maximo_total)}</th>
                        <th width="25%">{ currencyFormat(resumenCuenta.saldo_actual_total) }</th>
                    </tr>
                </thead>
            </table>
        </div>
 
    );
}
 
export default CDDResumen;