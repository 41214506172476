import React, { createContext, useEffect, useState } from 'react';
import {
  aprobarCuentaCDD,
  getCuentasCDD,
  getResumenCuentasCDD,
  obtenerSolicitud,
  removerCuentaCDD,
  updateCuentasCDD
} from '../../api/Solicitudes';

const CDDContext = createContext();

const CDDProvider = ({children}) => {
    const [cuentas, setCuentas] = useState([]);
    const [solicitud, setSolicitud] = useState({});
    const [resumenCuenta, setResumenCuenta] = useState({});
    const [cuenta, setCuenta] = useState({});
    const [modalActualizar, setModalActualizar] = useState(false);
    const [modalSeleccionar, setModalSeleccionar] = useState(false);
    const [filtros, setFiltros] = useState({
        otorgantes: ['BANCO', 'HIPOTECARIO', 'REVOLVENTE', 'HIPOTECAGOBIERNO'],
        tipo_cuenta: {
            I: 'Pagos Fijos',
            M: 'Hipoteca',
            O: 'Sin Límite Pre-establecido',
            R: 'Revolvente'
        },
        forma_pago_actual: ['01'],
    })

    useEffect(() => {
        if (solicitud.hasOwnProperty('id')) {
            getCuentas();
            getResumenCuentas();
        }
    }, [solicitud])

    const getCuentas = async () => {
        try {
            const resp = await getCuentasCDD(solicitud.id);
            setCuentas(resp.data);
            setFiltros({
                ...filtros,
                otorgantes: Array.from(new Set(resp.data.map((cuenta) => cuenta.nombre_otorgante))),
                forma_pago_actual: Array.from(new Set(resp.data.map((cuenta) => cuenta.forma_pago_actual)))
            });
        } catch (err) {
            console.log(err);
        }
    }

    const refrescarSolicitud = async () => {
        const res = await obtenerSolicitud(solicitud.id);
        setSolicitud(res.data);
    }

    const mostrarModalActualizar = (mostrarModal, cuentaAModificar) => {
        setModalActualizar(mostrarModal);
        setCuenta(cuentaAModificar);
        getCuentas()
    }

    const mostrarModalSeleccionar = (mostrarModal, cuentaAModificar) => {
        setCuenta(cuentaAModificar);
        setModalSeleccionar(mostrarModal);
    }
        
    const valoresNuevosCuenta = (valoresNuevos) => {
        setCuenta({...cuenta, ...valoresNuevos})
    };

    const modificarCuenta = async () => {
        try {
            await updateCuentasCDD(solicitud.id, cuenta.uuid, cuenta);
            getCuentas();
            setModalActualizar(false);
        } catch (err) {
            console.log(err)
        }
    }

    const getResumenCuentas = async () => {
        try {
            const resp = await getResumenCuentasCDD(solicitud.id);
            setResumenCuenta(resp.data);
        } catch(err) {
            console.log(err)
        }
    }

    const aprobarCuenta = async () => {
        try {
            await aprobarCuentaCDD(solicitud.id, cuenta.uuid);
            setModalSeleccionar(false);
            getCuentas();
            await refrescarSolicitud();
        } catch (err) {
            console.log(err)
        }
    }

    const removerCuenta = async (cuenta) => {
        try {
            await removerCuentaCDD(solicitud.id, cuenta.uuid);
            await refrescarSolicitud();
            getCuentas();
        } catch (err) {
            console.log(err)
        }
    }

    const filtrar = async (otorgante, tipo_cuenta, forma_pago) => {
        let cuentasFiltradas = [];
        try {
            const resp = await getCuentasCDD(solicitud.id);
            cuentasFiltradas = resp.data;
        } catch(err) {
            console.log(err)
        }

        if (otorgante !== '') {
            cuentasFiltradas = cuentasFiltradas.filter(cuenta => cuenta.nombre_otorgante === otorgante);
        }
        if (tipo_cuenta !== '') {
            cuentasFiltradas = cuentasFiltradas.filter(cuenta => cuenta.tipo_cuenta === tipo_cuenta);
        }
        if (forma_pago !== '') {
            cuentasFiltradas = cuentasFiltradas.filter(cuenta => cuenta.forma_pago_actual === forma_pago);
        }
        setCuentas(cuentasFiltradas);
    }

    const limpiarFiltros = () => {
        getCuentas();
    }

    const context = {
        cuentas,
        cuenta,
        getCuentas,
        mostrarModalActualizar,
        mostrarModalSeleccionar,
        modalActualizar,
        modalSeleccionar,
        modificarCuenta,
        valoresNuevosCuenta,
        aprobarCuenta,
        removerCuenta,
        solicitud,
        setSolicitud,
        filtros,
        filtrar,
        getResumenCuentas,
        resumenCuenta,
        limpiarFiltros,
    };

    return (
        <CDDContext.Provider value={context}>
            {children}
        </CDDContext.Provider>
    );
}
 
export {CDDContext, CDDProvider};