import React, { useContext, useEffect, useState } from 'react';
import NavModos from './NavModos';
import CDDLista from './CDDLista';
import Buscador from './Buscador';
import CDDResumen from './CDDResumen';
import CDDMonto from './CDDMonto';
import ModalActualizarCuenta from './ModalActualizarCuenta';
import ModalSeleccionarCuenta from './ModalSeleccionarCuenta';
import { CDDContext } from '../../../context/solicitudes/CDDContext';


const CDD = ({solicitud}) => {
    const [ modo, setModo ] = useState('listado');
    const { setSolicitud} = useContext(CDDContext);

    useEffect(() => {
        setSolicitud(solicitud);
    }, [solicitud]);


    const MODOS = {
        listado: 'Listado',
        resumen: 'Resumen'
    }

    const modoOnClick = (k) => {
        setModo(k);
    }

    return (
        <>
            <div className="row m-b-md">
                <div className="col">
                    <NavModos
                        modo={modo}
                        modos={MODOS}
                        accion={modoOnClick}
                    />
                </div>
                <div className="col">
                    <CDDMonto />
                </div>
            </div>

            <div className="row m-b-md">
                <Buscador />
            </div>

            <div className="row">
                <div className="col">
                    {
                        modo === 'listado' ? 
                            <CDDLista />
                        : 
                            modo === 'resumen' ?
                                <CDDResumen />
                            : 
                                null
                    }
                </div>
            </div>

            <ModalActualizarCuenta /> 

            <ModalSeleccionarCuenta />
        </>
    );
}
 
export default CDD;