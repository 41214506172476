import React, { useContext } from 'react';
import Modal from 'react-awesome-modal';
import Swal from 'sweetalert2';
import { CDDContext } from '../../../context/solicitudes/CDDContext';


const ModalActualizarCuenta = () => {
    const { modalActualizar, mostrarModalActualizar, cuenta, valoresNuevosCuenta, modificarCuenta } = useContext(CDDContext);

    const onChangeValoresReales = (e) => {
        valoresNuevosCuenta({
            [e.target.name]: e.target.value
        });
    }

    const actualizarCuenta = () => {
        modificarCuenta();
        Swal.fire({
            type: 'success',
            text: 'La cuenta fue actualizada exitosamente',
            toast: true,
            position: 'top-end',
            timer: 5000,
            showConfirmButton: false
        })
    }

    return (
        <Modal visible={ modalActualizar } width="50%" height="550px" effect="fadeInUp">
            <div className="modal-header">
                <h4 className="modal-title">Modificar cuenta</h4>
            </div>
            <div className="modal-body">
                <form>
                    
                    <div className="form-group  row">
                        <label className="col-sm-2 col-form-label">Monto a pagar</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                className="form-control"
                                name="monto_pagar_real"
                                value={cuenta.monto_pagar_real}
                                onChange={onChangeValoresReales} 
                            />
                        </div>
                    </div>

                    <div className="form-group  row">
                        <label className="col-sm-2 col-form-label">Número de pagos</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                className="form-control"
                                name="numero_pagos_real"
                                value={cuenta.numero_pagos_real}
                                onChange={onChangeValoresReales}
                            / >
                        </div>
                    </div>

                    <div className="form-group  row">
                        <label className="col-sm-2 col-form-label">Saldo Actual</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                className="form-control"
                                name="saldo_actual_real"
                                value={cuenta.saldo_actual_real}
                                onChange={onChangeValoresReales} 
                            />
                        </div>
                    </div>

                    <div className="form-group  row">
                        <label className="col-sm-2 col-form-label">Nombre de la Institución</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                className="form-control"
                                name="nombre_institucion"
                                value={cuenta.nombre_institucion}
                                onChange={onChangeValoresReales} 
                            />
                        </div>
                    </div>

                    <div className="form-group  row">
                        <label className="col-sm-2 col-form-label">Pagos restantes</label>
                        <div className="col-sm-8">
                            <input
                                type="text"
                                className="form-control"
                                name="numero_pagos_restantes_real"
                                value={cuenta.numero_pagos_restantes_real}
                                onChange={onChangeValoresReales} 
                            />
                        </div>
                    </div>

                </form>
            </div>
            
            <div className="modal-footer">
                <button type="button" className="btn btn-white" onClick={() => mostrarModalActualizar(false, cuenta)}>Cancelar</button>
                <button type="button" className="btn btn-primary" onClick={() => actualizarCuenta()} >Aceptar</button>
            </div>
        </Modal>
    );
}
 
export default ModalActualizarCuenta;