import React, { useEffect, useState } from 'react';
import { getOcrIneData, invalidarINE } from '../../../api/Solicitudes';
import { mostrarToast} from '../../../utils/alerts';


const OcrINE = ({idSolicitud}) => {
    const [data, setData] = useState({});
    const [solicitarIne, setSolicitarIne] = useState(false);

    const getData = async () => {
        try {
            const response = await getOcrIneData(idSolicitud);
            setData(response.data);
            setSolicitarIne(!response.data.valido)
        } catch (error) {
            console.log(error);
        }
    }

    const solicitarINE = async () => {
        try {
            const resp = await invalidarINE(idSolicitud)
            mostrarToast('Se cambio estatus de INE correctamente');
            setSolicitarIne(false)
        } 
        catch (error) {
            console.log('Error:', error);
            mostrarToast('Error');
        }
    }
    
    useEffect(() => {
        if (idSolicitud !== undefined) {
            getData();
        }        
    }, [idSolicitud]);

    return (
        <div className="panel panel-default">
            <div className="panel-heading m-b">
                <div className="row">                
                    <div className="col-6">
                        Validación de identificación
                    </div>
                    <div className="col-6 text-right">
                        {solicitarIne
                        ?  
                            <button
                                type="button"
                                className="btn btn-sm btn-primary  "
                                onClick={ () => solicitarINE() }
                            >
                                Solicitar INE
                            </button>  
                        : 
                            <button
                                type="button"
                                className="btn btn-sm btn-primary  "
                                disabled
                                onClick={ () => solicitarINE() }
                            >
                                Solicitar INE
                            </button> 
                        }    
                    </div>
                </div>
            </div>
            <div className="panel-body">
                { !data.informacion_completa
                    ? 'No hay datos'
                    : <div className="row">

                        <div className="col-md-6 m-t">
                        
                            <div className="col-sm-12 col-md-12 sol">
                                <span>Apellido Paterno </span>
                                <span>{data.apellido_paterno}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Apellido Materno</span>
                                <span>{data.apellido_materno}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Nombre</span>
                                <span>{data.nombre}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Género</span>
                                <span>{data.genero}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>CURP</span>
                                <span>{data.curp}</span>
                            </div>
                            
                            <div className="col-sm-12 col-md-12 sol">
                                <span>RFC</span>
                                <span>{data.rfc}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Fecha de Nacimiento</span>
                                <span>{data.fecha_nacimiento}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Nacionalidad</span>
                                <span>{data.nacionalidad}</span>
                            </div>
            
                        </div>

                        <div className="col-md-6 m-t">
                        
                            <div className="col-sm-12 col-md-12 sol">
                                <span>Número de Electoral</span>
                                <span>{data.no_electoral}</span>
                            </div>
                            
                            <div className="col-sm-12 col-md-12 sol">
                                <span>Proveedor</span>
                                <span>{data.proveedor}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Tipo de Documento</span>
                                <span>{data.tipo_documento}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Fecha de Registro</span>
                                <span>{data.fecha_registro}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Fecha de vigencia</span>
                                <span>{data.fecha_vigencia}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Estatus</span>
                                <span>{data.estatus}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Fecha Emisión</span>
                                <span>{data.fecha_emision}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Válido</span>
                                <span>
                                    { data.valido
                                    ? <><i className="fa fa-check text-navy"></i> <span>Si</span></>
                                    : <><i className="fa fa-times text-danger"></i> <span>No</span></>
                                    }
                                </span>
                            </div>
                            <div className="col-sm-12 col-md-12 sol">
                                <span>RENAPO</span>
                                <span>
                                    {data.verificado_renapo
                                    ? <><i className="fa fa-check text-navy"></i> <span>Válido</span></>
                                    : <><i className="fa fa-times text-danger"></i> <span>Inválido</span></>
                                    }
                                </span>
                            </div>
                            <div className="col-sm-12 col-md-12 sol">
                                <span>Videoselfie</span>
                                { data.videoselfie &&
                                    <span>
                                        <a href={ data.videoselfie } target="_blank" rel="noopener noreferrer">Link</a>
                                    </span>
                                }
                            </div>

                        </div>

                        <div className="col-md-6 m-t">
                            <div className="col-sm-12 col-md-12 sol">
                                <span>Calle</span>
                                <span>{data.calle}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Número Exterior</span>
                                <span>{data.numero_exterior}</span>
                            </div> 

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Número Interior</span>
                                <span>{data.numero_interior}</span>
                            </div> 

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Colonia</span>
                                <span>{data.colonia}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Código Postal</span>
                                <span>{data.codigo_postal}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Municipio</span>
                                <span>{data.municipio}</span>
                            </div>
                            
                            <div className="col-sm-12 col-md-12 sol">
                                <span>Estado</span>
                                <span>{data.estado}</span>
                            </div>

                            <div className="col-sm-12 col-md-12 sol">
                                <span>Domicilio Completo</span>
                                <span>{data.domicilio_completo}</span>
                            </div>
                        </div>

                        <style jsx="true">{`
                            .img-responsive {
                                width: auto;
                                height: 100px;
                            }
                        `}
                        </style>

                        <div className="col-md-6 m-t">
                            <div className="row">
                                <div className="col-4 text-center">
                                    <a  href={data.documento_front} title="INE frontal" target="_blank">
                                        <img src={data.documento_front} alt="foto" className="img-responsive"  />
                                    </a>
                                </div>

                                <div className="col-4 text-center">
                                    <a href={data.documento_back} title="INE reverso" target="_blank">
                                        <img src={data.documento_back} alt="foto" className="img-responsive" />
                                    </a>
                                </div>
                                    
                                <div className="col-4 text-center">
                                    <a href={data.selfie} title="selfie" target="_blank">
                                        <img src={data.selfie} alt="foto" className="img-responsive" />
                                    </a>         
                                </div>              

                            </div>
                        </div>

                    </div>
                }
                
            </div>
        </div>
    );
}
 
export default OcrINE;