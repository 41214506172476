import React, { useContext, useState } from 'react';
import { CDDContext } from '../../../context/solicitudes/CDDContext';

const Buscador = () => {
    const [filters, setFilters] = useState({
        otorgante: '',
        tipo_cuenta: '',
        forma_pago_actual: ''
    })
    const { filtros: {otorgantes, tipo_cuenta, forma_pago_actual}, filtrar } = useContext(CDDContext);
    
    const buscarOnchange = (e) => {
        setFilters({
            ...filters,
            [e.target.name]: e.target.value
        });
        if(e.target.name === 'otorgante') {
             filtrar(e.target.value, filters.tipo_cuenta, filters.forma_pago_actual);
        }
        if(e.target.name === 'tipo_cuenta') {
             filtrar(filters.otorgante, e.target.value, filters.forma_pago_actual);
        }
        if(e.target.name === 'forma_pago_actual') {
             filtrar(filters.otorgante, filters.tipo_cuenta, e.target.value);
        }
    }

    // useEffect(() => {

    // }, [solicitud])

    // const limpiarBusqueda = () => {
    //     setFilters({
    //         otorgante: '',
    //         tipo_cuenta: '',
    //         forma_pago_actual: ''
    //     });
    //     limpiarFiltros();
    // }

    
    return (
        <>
            <div className="col-sm-4">
                <div className="input-group">
                    <select className="form-control" name="otorgante" onChange={(e) => buscarOnchange(e) }>
                        <option value="">-- Otorgante --</option>
                        {otorgantes.map((otorgante, index) => <option key={index} value={otorgante}>{otorgante}</option>)}
                    </select>
                </div>
            </div>

            <div className="col-sm-4">
                <div className="input-group">
                    <select className="form-control" name="tipo_cuenta" onChange={(e) => buscarOnchange(e)}>
                        <option value="">-- Tipos de crédito --</option>
                        {
                            Object.entries(tipo_cuenta).map(([key, value], index) => {
                                return <option value={key} key={index}>{value}</option>
                            })
                        }
                    </select>
                </div>
            </div>

            <div className="col-sm-4">
                <div className="input-group">
                    <select className="form-control" name="forma_pago_actual" onChange={(e) => buscarOnchange(e)}>
                        <option value="">-- Forma de pago --</option>
                        {forma_pago_actual.map((forma, index) => {
                            return <option key={index} value={forma}>{forma}</option>
                        })}
                    </select>
                </div>
            </div>

            <div className="col-sm-4">
                <div className="input-group">
                    {/* <input type="text" className="form-control" /> */}
                    <span className="input-group-append">
                        {/* <button className="btn btn-sm btn-danger" type="button" onClick={() => limpiarBusqueda()}>
                            <i className="fa fa-close fa-margin"></i>
                        </button> */}
                        {/* <button
                            className="btn btn-sm btn-primary"
                            type="button"
                            onClick={() => filtrar(filters.otorgante, filters.tipo_cuenta, filters.forma_pago_actual, solicitud)}
                        >
                            <i className="fa fa-search fa-margin"></i>
                            Buscar
                        </button> */}
                    </span>
                </div>
            </div>
        </>
    );
}
 
export default Buscador;